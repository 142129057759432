import styled from 'styled-components';



export const Content = styled.div`
    max-width: 650px;
    background-color: #ffffff;
    margin: 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const Title = styled.div`
    font-size: 42px;
    color: #060368;
    font-weight: 400;
    text-align: center;
    margin: 20px;
    font-family: var(--font-mrEaves);
    font-weight: normal;

    @media (max-width: 375px) {
        font-size: 28px;
    }

`;

export const Subtitle = styled.div`
    font-size: 18px;
    margin: 10px 0px;
    color: #111111;
    font-weight: 400;
    line-height: 26px;

    @media (max-width: 375px) {
        font-size: 15px;
    }
`;

export const ButtonsBar = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    align-items: center;
    margin-top: 10px;
    justify-content: space-evenly;
    width: 100%;
`;

const Button = styled.button`
    margin: 10px;
    height: 50px;
    min-width: 202px;
    padding: 5px 10px;
    border-radius: 5px;
    background-size: cover;
    font-size: 21px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-mrEaves);
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`;

export const RemoveButton = styled(Button)`
    background-color: #69469d;
    color: #ffffff;
`;

export const CancelButton = styled(Button)`
    border: 2px solid #69469d;
    margin-left: 10px;
    color: #69469d;
`;
