import React, {useState, useEffect} from 'react';
import { Route, useRouteMatch ,Switch, Redirect, useLocation } from 'react-router-dom';
import TaskPage from './components/TaskPage';
import Calendar from './components/Calendar/Calendar';
import Invitations from './components/Invitations/Invitations';
import Settings from './components/Settings/Settings';
import { useAuth0 } from './shared-lib/auth-provider';
import Footer from './shared-lib/components/general/Footer'
import BackgroundPattern from './shared-lib/components/BackgroundPattern'
import Menubar from './shared-lib/components/Menubar';
import DashboardTabbar from './components/Dashboard/DashboardTabbar';
import UpcomingTaskPage from './components/Dashboard/UpcomingTaskPage';
import ManageVillagePage from './components/Dashboard/ManageVillagePage';
import CalendarTabbar from './components/CalendarTabbar';
import TitleText from './components/TaskPage/TitleText';
import AccountPage from './components/Account';
import { useUser } from './auth/UserContext';
import {getInvitesAndMembers} from './data/actions';
import { connect } from 'react-redux';


function App(props) {

  const { isAuthenticated, logout } = useAuth0()
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { membership } = useUser()
  const isDashboard = pathname.includes("dashboard") ? true : false;
  const isTaskPage = pathname.includes('task-page') ? true : false;
  const {getInvitesAndMembers} = props;

  useEffect(() => {
    if (membership && membership.mission_uuid) {
      console.log('getting invites and members');
      getInvitesAndMembers(membership.mission_uuid);
    }

  }, [membership])

  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const handleRootClick = () => {
    if (showAccountMenu) {
      setShowAccountMenu(false);
    }
  }

  if (!isAuthenticated) {
    return <Redirect to="/"/>
  }

  return (
      <div className="app-root" onClick={handleRootClick}>
        <div className={isAuthenticated ? "app-header" : "auth-protected-app-header"}>
          <BackgroundPattern />
          <Menubar history={props.history} url={url} handleLogout={logout} showAccountMenu={showAccountMenu} setShowAccountMenu={setShowAccountMenu}/>
          {
            isTaskPage ?
            <TitleText person_passed={membership && membership.person_passed}/>
            :
            isDashboard ? 
            <DashboardTabbar />
            :
            <CalendarTabbar isAuthenticated={isAuthenticated} />
          }
        </div> 
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${url}/calendar`}/>}/>
          <Route path={`${path}/task-page`} render={() => <TaskPage/>}/>
          <Route path={`${path}/calendar`} render={() => <Calendar/>}/>
          <Route path={`${path}/invitations`} render={() => <Invitations/>}/>
          <Route path={`${path}/settings`} render={() => <Settings/>}/>
          <Route path={`${path}/dashboard/tasks`} render={() => <UpcomingTaskPage/>}/>
          <Route path={`${path}/dashboard/villages`} render={() => <ManageVillagePage/>}/>
          <Route path={`${path}/dashboard/account`} render={() => <AccountPage />} />
        </Switch>
        <Footer/>
      </div>
  );
}

const mapDispatchToProps = { 
  getInvitesAndMembers
};

export default connect(null, mapDispatchToProps)(App);
