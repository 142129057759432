'use strict'
import React, { useEffect } from 'react';

import Bathrooms from '../../../assets/bathrooms.svg'
import Dishes from '../../../assets/dishes.svg'
import LawnCare from '../../../assets/lawncare.svg'
import Tidying from '../../../assets/tidying.svg'
import Laundry from '../../../assets/laundry.svg'
import Groceries from '../../../assets/groceries.svg'


import { 
    Title,
    Subtitle, 
    ButtonCard, 
    ButtonCardIcon, 
    ButtonCardTitle, 
    ButtonCardSubtitle, 
    RootWrapper,
    Checkbox,
    Grid,
    SubmitButton, 
    FormContent} from './style'
import { connect } from 'react-redux'
import { wizardStep4, skipWizardStep4 } from '../../../data/actions';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SkipThisStepButton from '../../Share/SkipThisStepButton'
import { useAnalytics } from '../../../analytics/AnalyticsContext';

const validationSchema = Yup.object().shape({
    taskCategories: Yup.array(Yup.string())
})

function HowToHelp(props) {

    const { track, pageview, mpTrackWizard } = useAnalytics()

    useEffect(() => {
      track("Page View", {"Page Name": "Wizard - How to help"})
      pageview("/wizard/how-to-help", [], "Wizard - How to help")
      // eslint-disable-next-line
    }, [])

    const initialValues = {
        taskCategories: [],
        checkbox0: props.step4[0] === "" ? false : true,
        checkbox1: props.step4[1] === "" ? false : true,
        checkbox2: props.step4[2] === "" ? false : true,
        checkbox3: props.step4[3] === "" ? false : true,
        checkbox4: props.step4[4] === "" ? false : true,
        checkbox5: props.step4[5] === "" ? false : true
    }

    const taskCategories = [
        { icon: Bathrooms, title: "Cleaning bathrooms", subtitle: "Wiping counters, washing floors, cleaning mirrors", selected: false },
        { icon: Dishes, title: "Washing dishes", subtitle: "Loading dishwasher, handwashing and drying", selected: false },
        { icon: LawnCare, title: "Lawn care", subtitle: "Mowing, raking leaves, gardening ", selected: false },
        { icon: Tidying, title: "Tidying up", subtitle: "Sweeping, vacuuming, taking out garbage", selected: false },
        { icon: Laundry, title: "Laundry", subtitle: "Washing, folding, dry cleaning", selected: false },
        { icon: Groceries, title: "Shopping", subtitle: "Groceries, household items", selected: false },
    ];

    const handleSubmit = (values) => {
        var _taskCategories = []
        _taskCategories.push(values.checkbox0 ? "Cleaning bathrooms" : "")
        _taskCategories.push(values.checkbox1 ? "Washing dishes" : "")
        _taskCategories.push(values.checkbox2 ? "Lawn care" : "")
        _taskCategories.push(values.checkbox3 ? "Tidying up" : "")
        _taskCategories.push(values.checkbox4 ? "Laundry" : "")
        _taskCategories.push(values.checkbox5 ? "Shopping" : "")
        
        props.wizardStep4(_taskCategories)
        mpTrackWizard("How to help", true, _taskCategories.filter(i => i !== ""))
        props.goto('/wizard/schedule')
    }
    const skipWizardStep4 = () => {
        props.skipWizardStep4()
        mpTrackWizard("How to help", false)
        props.goto('/wizard/schedule')
    }
  
    return (<RootWrapper>
            <Title>Most people experiencing a difficult time need support.</Title>
            { props.step1 === 'myself' ? 
            (<Subtitle>Did you know that helping others activates regions of the brain 
                associated with pleasure, social connection and trust, creating a “warm glow” effect? 
                Select all chores that you and your family could use a little help with.</Subtitle>) : 
            (<Subtitle>Did you know that helping others activates regions of the brain 
                associated with pleasure, social connection and trust, creating a “warm glow” effect? 
                Select all chores that the family could use a little help with.</Subtitle>)
            }
            <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                render={({handleBlur, handleChange, values, errors}) => (
                    <Form>
                        <FormContent>
                        <Grid>
                    {taskCategories.map((item, idx) => {
                        return (
                        <Checkbox key={idx}>
                            <input type="checkbox" id={'checkbox' + idx} name={'checkbox' + idx} onChange={handleChange} onBlur={handleBlur} />
                            <ButtonCard key={'label' + idx} htmlFor={'checkbox' + idx} id={'label' + idx} isChecked={values['checkbox' + idx]}>
                                <ButtonCardIcon isChecked={values['checkbox' + idx]} src={item.icon} alt="Calendar"></ButtonCardIcon>
                                <ButtonCardTitle isChecked={values['checkbox' + idx]}>{item.title}</ButtonCardTitle>
                                <ButtonCardSubtitle isChecked={values['checkbox' + idx]}>{item.subtitle}</ButtonCardSubtitle>
                            </ButtonCard>
                        </Checkbox>
                    )})}
                    </Grid>
                    <div/><SubmitButton type="submit">Next</SubmitButton>
                    </FormContent>
                    </Form>
                )}
            />
            <SkipThisStepButton onClick={skipWizardStep4}>Skip this step</SkipThisStepButton>
        </RootWrapper>)
}

const mapStateToProps = (state) => {
    return {         
        step1: state.WizardReducer.step1,
        step4: state.WizardReducer.step4
    }
}

export default connect( mapStateToProps, { wizardStep4, skipWizardStep4 })(HowToHelp);