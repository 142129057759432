import * as actions from './actionTypes';

export const wizardStep1 = (person) => ({
    type: actions.WIZARD_STEP_1,
    payload: { 
        step1: person
    }
})

export const wizardStep2 = (isContinue) => ({
    type: actions.WIZARD_STEP_2,
    payload: { 
        step2: isContinue
    }
})

export const wizardStep3 = (name, familyMembers) => ({
    type: actions.WIZARD_STEP_3,
    payload: { 
        name: name,
        familyMembers: familyMembers
    }
})

export const wizardStep4 = (taskCategories) => ({
    type: actions.WIZARD_STEP_4,
    payload: { 
        step4: taskCategories
    }
})

export const skipWizardStep4 = () => ({
    type: actions.SKIP_WIZARD_STEP_4,
    payload: { 
        step4: ["", "", "", "", "", ""]
    }
})

export const getBookings = (user_uuid) => ({
    type: actions.GET_BOOKINGS,
    payload: {user_uuid}
})

export const resetGetBookings = () => ({
    type: actions.GET_BOOKINGS_RESET
})

export const createBooking = (booking) => ({
    type: actions.CREATE_BOOKING,
    payload: booking
})

export const deleteBooking = (booking) => ({
    type: actions.DELETE_BOOKING,
    payload: booking
})

export const deleteBookingReset = () => ({
    type: actions.DELETE_BOOKING_RESET
})

export const createBookingReset = () => ({
    type: actions.CREATE_BOOKING_RESET
})

export const fetchTaskSeries = (mission_uuid, weekStart, weekEnd) => ({
    type: actions.FETCH_TASK_SERIES,
    payload: {
        mission_uuid,
        weekStart,
        weekEnd,
    }
})

export const getTaskPageTaskSeries = (mission_uuid) => ({
    type: actions.GET_TP_TASKS,
    payload: mission_uuid
})

export const updateTaskSeries = (values) => ({
    type: actions.UPDATE_TASK_SERIES,
    payload: values
})

export const updateTaskSeriesReset = () => ({
    type: actions.UPDATE_TASK_SERIES_RESET
})

export const modifyTasksSeriesCategories = (missionUuid, taskSeriesCategoriesData) => ({
    type: actions.MODIFY_TASK_SERIES_CATEGORIES,
    payload: { 
        missionUuid: missionUuid,
        taskSeriesCategoriesData: taskSeriesCategoriesData 
    }
})

export const modifyTasksSeriesCategoriesSuccess = () => ({
    type: actions.MODIFY_TASK_SERIES_CATEGORIES_SUCCESS,
    payload: { }
})

export const modifyTasksSeriesCategoriesError = (error) => ({
    type: actions.MODIFY_TASK_SERIES_CATEGORIES_ERROR,
    payload: error
})


export const updateException = (booking) => ({
    type: actions.UPDATE_EXCEPTION,
    payload: booking
})

export const addException = (booking) => ({
    type: actions.ADD_EXCEPTION,
    payload: {
        booking: booking
    }
})

export const updateSettings = (settings) => ({
    type: actions.UPDATE_SETTINGS,
    payload: {
        settings: settings
    }
})

export const getSettings = (mission_uuid) => {
    return {
    type: actions.GET_SETTINGS,
    payload: { mission_uuid: mission_uuid }
}}


export const addTask = (newTask) => ({
    type: actions.ADD_TASK,
    payload: newTask
})

export const setWeek = (weekStart, weekEnd) => ({
    type: actions.SET_WEEK,
    payload: { weekStart, weekEnd }
})

export const getMission = (mission_uuid) => ({
    type: actions.GET_MISSION,
    payload: mission_uuid
})

export const deleteTaskSeries = (id) => ({
    type: actions.DELETE_TASK_SERIES,
    payload: id
})

export const deleteTaskSeriesReset = () => ({
    type: actions.DELETE_TASK_SERIES_RESET
})

export const fetchRecentNotes = (date, seriesId) => ({
    type: actions.FETCH_RECENT_NOTES,
    payload: { date, seriesId }
})

export const sendEmailInvitations = (data) => {

    const postBody = {
        emails: data.emails,
        missionUuid: data.mission_uuid,
        senderUuid: data.user_uuid,
        personPassed: data.person_passed,
        role: data.role,
        emailSubject: data.subject,
        emailBody: data.body
    }

    return {
        type: data.role === "rec" ? actions.INVITE_REC : actions.INVITE_CONT,
        payload: postBody
    }
}

export const invteContReset = () => ({
    type: actions.INVITE_CONT_RESET
})

export const invteRecReset = () => ({
    type: actions.INVITE_REC_RESET
})

export const getInvitesAndMembers = (mission_uuid) => {
    return {
        type: actions.GET_INVITES_AND_MEMBERS,
        payload:{mission_uuid: mission_uuid}
    }
}

export const updateInvitesAndMembers = (data) => {
    return {
        type: actions.UPDATE_INVITES_AND_MEMBERS,
        payload:data
    }
}

