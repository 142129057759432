import React, { useState, useEffect } from 'react';
import { ColContainer, RowContainer, Title, ButtonCard, Icon, Option } from './style';
import { connect } from 'react-redux';
import { wizardStep1 } from '../../../data/actions/index';
import eldercareSvg from '../../../assets/eldercare.svg';
import careSvg from '../../../assets/care.svg';
import { useAnalytics } from '../../../analytics/AnalyticsContext';

function WhoseLoss(props) {

    const [selected, setSelected] = useState(props.step1)
    const { track, pageview, mpTrackWizard } = useAnalytics()

    useEffect(() => {
      track("Page View", {"Page Name": "Wizard - Whose loss"})
      pageview("/wizard/whose-loss", [], "Wizard - Whose loss")
      // eslint-disable-next-line
    }, [])

    const handleChange = (e) => {
        const option = e.target.value
        setSelected(option)
        mpTrackWizard("Whose loss", true, option)
        props.wizardStep1(option)
        if (option === 'myself') {
            props.goto('/wizard/sorry-loss')
        } else  {
            props.goto('/wizard/about-family')
        }
    }

    return (
    <ColContainer>
        <Title>I am requesting support for</Title>
        <RowContainer>
        <ButtonCard htmlFor="myself" isChecked={selected === "myself"} >
            <input type="radio" name="whose-loss" id="myself" value="myself" onChange={handleChange}/>
            <Icon src={eldercareSvg}/>
            <Option>Myself</Option>
        </ButtonCard>
        <ButtonCard htmlFor="lovedOne" isChecked={selected === "lovedOne"} >
            <input type="radio" name="whose-loss" id="lovedOne" value="lovedOne" onChange={handleChange}/>
            <Icon src={careSvg}/>
            <Option>Loved one</Option>
        </ButtonCard>
        </RowContainer>
    </ColContainer>)
}

const mapStateToProps = (state) => {
    return { step1: state.WizardReducer.step1 }
}

const mapDispatchToProps = {
    wizardStep1: wizardStep1
}

export default connect(mapStateToProps, mapDispatchToProps)(WhoseLoss);