import styled from 'styled-components';
import checkIcon from '../../../assets/check-white.svg'
import Button from '../../Share/Button';


export const SubmitButton = styled(Button)`
    ;// this doesn't do anything but addresses an apparent bug where styled(Component) inserts a ',' before first property
    @media(max-width: 675px){
        margin-top: 40px;
    }
`
export const RootWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
`

export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Title = styled.h1`
    font-family: ${props => props.theme.mrEaves};
    font-size: 36px;
    color: #060368;
    font-weight: 400;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0px;

    @media(max-width: 675px) {
        font-size: 29px;
        margin-left: 20px;
        margin-right: 23px;
        height: 66px;
        margin-top:30px;
    }
    
`

export const Subtitle = styled.h5`
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 24px;
    text-align: center;
    max-width: 660px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: ${props => props.theme.mrsEaves};

    @media(max-width: 675px) {
        margin-top:16px;
        margin-left: 20px;
        margin-right: 23px;
    }
`

export const ButtonCard = styled.label`
    width: 225px;
    height: 165px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    background-color: ${(props) =>  props.isChecked ?  '#69489d' : '#ffffff' };
    background-size: cover;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    transition: 0.7s;
    :hover {
        cursor: pointer;
    }

    @media(max-width: 675px) {
        width: 156px;
        height: 206px;
    }
`

export const ButtonCardIcon = styled.img`
    border: none;
    background-size: cover;
    content:url(${(props) => props.isChecked ? checkIcon : props.src });
    border: ${(props) => props.isChecked ? 'solid white 2px' : null};
    border-radius: ${(props) => props.isChecked ? '40px' : null};
    padding-right: ${(props) => props.isChecked ? '8px' : null};
    padding-left: ${(props) => props.isChecked ? '10px' : null};
    padding-top: ${(props) => props.isChecked ? '12px' : null};
    padding-bottom: ${(props) => props.isChecked ? '12px' : null};
`

export const ButtonCardTitle = styled.label`
    font-family: ${props => props.theme.mrEaves};
    font-size: 26px;
    color: ${(props) => props.isChecked ?  'white' : '#69489d'};
    font-weight: bold;
    text-decoration: none solid rgb(105, 72, 157);
    text-align: center;
    pointer-events: none 
    margin: 0px;
`

export const ButtonCardSubtitle = styled.label`
    font-size: 18px;
    color: ${(props) => props.isChecked ?  'white' : '#555555'};
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 21px;
    text-align: center;
    pointer-events: none 
    font-style: italic;
    margin-left: 10px;
    margin-right: 10px;
`

export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 20px;
    margin: 50px;
    justify-items: center;
    align-items: center;

    @media(max-width: 675px) {
        max-width: 100vw;
        margin: 10px;
        margin-right: 13px;
        grid-gap: 18px;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
    }
`

export const Checkbox = styled.div`
    width: 225px;
    height: 165px;


    input[type="checkbox"] {
        display: none;
        width:1px;
        height:1px;
    }

    input[type="checkbox"]:checked + label {
        background-color: #69469d;
    }

    input[type="checkbox"]:checked + label > label {
        color: white;
    }

    @media(max-width: 675px) {
        width: 156px;
        height: 206px;
    }
`