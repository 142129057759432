import React from 'react';
import styled from 'styled-components'
import { Field } from 'formik';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0px 50px 50px 50px;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
`

export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items:baseline;
    margin-left: 80px;

    .input {
        max-width: 310px;
    }

    @media(max-width: 675px) {
        margin-left:0px;
        align-items:center;
    }
`

export const InputField = styled((props) => {
    return (<Field {...props} />)
})`
    min-width: 480px;
    height: 45px;
    padding: 5px 10px;
    border: 1px solid #555555;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
`

export const InputHeader = styled.div`
    text-align: left;
    margin-bottom: 5px;
    font-size: 18px;
    color: #060368;
    font-weight: bold;
    font-style: italic;
    text-decoration: none solid rgb(6, 3, 104);
`

export const InputFooter = styled.div`
    text-align: left;
    color: red;
    margin-top: 5px;
    margin-bottom: 20px;
    min-height: 20px;
`

export const Title = styled.h6`
    font-family: ${props => props.theme.mrEaves};
    font-size: 36px;
    color: #060368;
    font-weight: 400;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin: 0px 10px 10px 10px;

    @media(max-width: 675px) {
        font-size: 29px;
    }
`

export const SubTitle = styled.h3`
    width: 650px;
    max-width: 80vw;
    height: 48px;
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 24px;
    text-align: center;
    margin: 10px 20px 40px 20px;
    font-family: ${props => props.theme.mrsEaves};

    @media(max-width: 675px) {
        max-width: 80vw;
    }

`

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 10px 5px 0px;
`

export const NameLabel = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 18px;
    color: #555555;
    color: #555555;
    width: 280px;
    text-align: left;
`

export const RelationLabel = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 18px;
    color: #555555;
    color: #555555;
    width: 233px;
    text-align: left;
`

export const AgeLabel = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 18px;
    color: #555555;
    color: #555555;
    width: 75px;
    text-align: left;
`

export const MemberWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 650px;
    height: 45px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    margin: 0 10px 10px 0px;
`

export const MemberName = styled.input`
    height: 43px;
    padding: 0px;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    border-style: none;
    border-right: 1px solid #cccccc;
    padding-left: 20px;
    flex: 1;
`

export const MemberRelation = styled.select`
    width: 233px;
    height: 43px;
    border-radius: 8px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    border: none;
    background-color: white;
    margin-left: 2px;
`

export const RelationOption = styled.option`
    width: 75px;
    height: 43px;
    padding: 0px;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    border: none;
    background-color: white;
`

export const MemberAge = styled.input`
    width: 75px;
    height: 43px;
    padding: 0;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    border-style: none;
    padding-left: 10px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
`

export const DeleteButton = styled.button`
    width: 30px;
    height: 30px;
    border: 1.5px solid #69469d;
    border-radius: 15px;
    margin: 12px;
    align-self: center;
    color: #69469d
    transition: all 0.2s;
    font-weight:300;
    text-decoration:none;
    display: flex;
    align-items: center;
    justify-content: center;

    :active{
         color:#BBBBBB;
         border-color:#BBBBBB;
    }
    :focus {
        outline: none;
        background-color: #69469d;
        color:#000000;
    }

    :hover {
        background-color: #69469d;
        color:#000000;
    }
`

export const FamilyMemberFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const ButtonCenterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    @media(max-width: 675px) {
        margin-top: 10px;
        margin-bottom: 0px;
    }
`

export const PersonOrPet = styled.div`
    margin: 10px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #69469d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 70, 157);
`