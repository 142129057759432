import React, { useEffect } from 'react';
import loading from './loading.svg';
import { useAnalytics } from '../analytics/AnalyticsContext';

const style = {
    display: "flex",
    height: '100vh',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
}


function Callback() {
 
    const { track, pageview } = useAnalytics()

    useEffect(() => {
        track("Page View", {"Page Name": "Login"})
        pageview("/login", [], "Login")
        // eslint-disable-next-line
      }, [])

    return (
    <div style={style}>
        <img src={loading} alt="loading"/>
    </div>)
}

export default Callback;