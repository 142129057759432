import React, { useEffect } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from 'styled-components';
import SimpleCard from '../../Share/SimpleCard';
import FacebookIcon from '../../../assets/facebook.svg';
import TwitterIcon from '../../../assets/twitter.svg';
import EmailIcon from '../../../assets/email.svg';
import { useAnalytics } from '../../../analytics/AnalyticsContext';
import { useUser } from '../../../auth/UserContext';



const Container = styled.div`
	display: flex;
	flex-direction: ${props => props.isSmall ? 'column':'row'};
	justify-content: space-between;
	width: 100%;
	flex-wrap: no-wrap;
	margin-top: 20px;
	
`

const CardWrapper = styled.div`
	flex: 1 1 0px;
`

function SocialMediaPanel ({openEmail, isSmall, personPassed}){

	const { track, pageview } = useAnalytics();
	const {getContributorInviteLink} = useUser(); 
	const inviteText = `Volunteer for tasks to help support the ${personPassed} family during this difficult time. No matter how big or small the task, every bit helps. `
    
    useEffect(() => {
        track("Page View", {"Page Name": "Invitations - Social media"})
        pageview("invitations/socialmedia",[], "Invitations - Social media")
        // eslint-disable-next-line
	}, [])

	const trackSocial = (ref) => {
		track("Invite People", {
			"Method": ref
		})
	}

	const handleEmailClick = () => {
		openEmail();
		trackSocial('Email'); // assuming we'll want to track it this way...
	}

	return (
		<Container isSmall={isSmall}>
			<CardWrapper>
			<SimpleCard
					title="Email"
					svgUrl={EmailIcon}
					onClick={handleEmailClick}
				/>
			</CardWrapper>
			<CardWrapper style={isSmall ? {margin:'20px 0px'} : {margin:'0px 20px'}}>
			<FacebookShareButton
				url={getContributorInviteLink({medium:'social', source:'facebook'})}
				quote={inviteText}
				style={{width: '100%'}}
			>
				<SimpleCard 
					title="Facebook" 
					svgUrl={FacebookIcon}
					onClick={() => trackSocial("Facebook")}
				/>
			</FacebookShareButton>
			</CardWrapper>
			<CardWrapper>
			<TwitterShareButton
				url={getContributorInviteLink({medium:'social', source:'twitter'})}
				title={inviteText}
				style={{width: '100%'}}
			>
				<SimpleCard 
					title= "Twitter" 
					svgUrl={TwitterIcon} 
					onClick={() => trackSocial("Twitter")}
				/>
			</TwitterShareButton>
			</CardWrapper>
		</Container> 
	);
}

export default SocialMediaPanel;