import React, { useEffect } from 'react';
import { Container, SubTitle, Title, ButtonCenterWrapper, FormContent} from './style';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { wizardStep3 } from '../../../data/actions';
import Button from '../../Share/Button';
import { mapHasRelativeBoolsToGenericRelativesObject, mapGenericRelativesObjectToRelativeBools } from '../relativesUtils';
import FamilyMembersRadioButtons from '../../../shared-lib/components/user_entry/FamilyMembersRadioButtons';
import {FormField, getFamilyNameConfig} from '../../../shared-lib/components/user_entry/FormField';
import { useAnalytics } from '../../../analytics/AnalyticsContext';

const validationSchema = Yup.object().shape({
    name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

function AboutFamily(props) {

    const { track, pageview, mpTrackWizard } = useAnalytics()

    useEffect(() => {
      track("Page View", {"Page Name": "Wizard - About family"})
      pageview("/wizard/about-family", [], "Wizard - About family")
      // eslint-disable-next-line
    }, [])

    const submitHandler = (values) => {
        const {familyMembers} = values
        var genericRelatives = mapHasRelativeBoolsToGenericRelativesObject(familyMembers)
        props.wizardStep3(values.name, genericRelatives)
        mpTrackWizard("About family", true, {name: values.name, genericRelatives})
        props.goto('/wizard/how-to-help')
    }

    const {name, familyMembers} = props.step3;

    const initialValues = {
        name: name,
        familyMembers: mapGenericRelativesObjectToRelativeBools(familyMembers)
    }

    const familyMemberValuesProvided = (members) => {
        return (members.eldercare != null && members.childcare != null && members.petcare != null);
    }

    const isSmall = window.matchMedia( "(max-width: 675px)").matches;

    return (<Container>
        <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            render={({values, errors, touched, handleBlur, handleChange,  isSubmitting, setFieldValue }) => (
                <Form>
                    {props.step1 === 'myself' ? (
                        <div>
                            <Title>Tell us about your family.</Title>
                            <SubTitle>
                            Knowing a little bit about your family helps us to create a customized list of tasks tailored to your family’s needs.
                            </SubTitle>
                        </div>
                    ) : (
                        <div>
                            <Title>Tell us about the family.</Title>
                            <SubTitle>
                            Knowing a little bit about the family helps us to create a customized list of tasks tailored to the family’s needs.
                            </SubTitle>
                        </div>
                    )
                }
                  <FormContent>
                    <FormField config={getFamilyNameConfig({errors, values, handleBlur, handleChange, touched, decorator:"*"})} width={isSmall ? '100%' : '310px'}></FormField>
                    <FamilyMembersRadioButtons values={values.familyMembers} handleBlur={handleBlur} setFieldValue={setFieldValue} decorator={"*"}/>
                </FormContent>
                <ButtonCenterWrapper>
                    <Button variant='outlined' type="submit" disabled={isSubmitting || errors.name || !familyMemberValuesProvided(values.familyMembers)}>Next</Button>
                    </ButtonCenterWrapper>
                </Form>
            )}
        />
    </Container>)
}



const mapStateToProps = (state) => {
    return { 
        step3: state.WizardReducer.step3,
        step1: state.WizardReducer.step1
    }
}

export default connect(mapStateToProps, { wizardStep3 })(AboutFamily);