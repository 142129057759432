import React from 'react';
import styled from 'styled-components'

const FamilyMembersStyle = styled.div`
    margin-top: 40px;

    @media(max-width: 675px) {
        margin-top:20px;
    }
`

const InputHeader = styled.div`
    text-align: left;
    margin-bottom: 5px;
    font-size: 18px;
    color: #060368;
    font-weight: bold;
    font-style: italic;
    text-decoration: none solid rgb(6, 3, 104);
`

const FamilyRadioButton = styled.input`
`
const NoRadioButton = styled.input`
    margin-left: 35px;
`
const RadioButtonContainer = styled.div`
    color: #060368;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    font-size: 18px;
`

function FamilyMembersRadioButtons(props){
    const { childcare, petcare, eldercare} = props.values
    const { handleBlur, setFieldValue, decorator  } = props

    const changePetcareTo = (val) => {
        const newVals = {...props.values, petcare: val}
        updateFamilyMembers(newVals)
    }

    const changeElderCareTo = (val) => {
        const newVals = {...props.values, eldercare: val}
        updateFamilyMembers(newVals)
    }

    const changeChildcareTo = (val) => {
        const newVals = {...props.values, childcare: val}
        updateFamilyMembers(newVals)
    }

    const updateFamilyMembers = (val) => {
        setFieldValue('familyMembers', val, false)
    }

    const titleDecor = decorator ? decorator + " " : ""

    return(
        <FamilyMembersStyle>
        <InputHeader>{titleDecor}Are there any children who need child care?</InputHeader> 
        <RadioButtonContainer>
        <FamilyRadioButton type="radio" name="childcare" checked={childcare === true} onBlur={handleBlur} onChange={() => {
            changeChildcareTo(true)
        }}/> Yes
        <NoRadioButton type="radio" name="childcare" checked={childcare === false} onBlur={handleBlur} onChange={() => {
            changeChildcareTo(false)
        }}/>No 
        </RadioButtonContainer>
        <br/>

        <InputHeader>{titleDecor}Are there any seniors with caregiving needs?</InputHeader>
        <RadioButtonContainer>
        <FamilyRadioButton type="radio" name="eldercare" checked={eldercare === true} onBlur={handleBlur} onChange={() => {
            changeElderCareTo(true)
        }}/> Yes
        <NoRadioButton type="radio" name="eldercare" checked={eldercare === false} onBlur={handleBlur} onChange={() => {
            changeElderCareTo(false)
        }}/>No 
        </RadioButtonContainer>
        <br/>

        <InputHeader>{titleDecor}Are there any pets in the household?</InputHeader>
        <RadioButtonContainer>
        <FamilyRadioButton type="radio" name="petcare" checked={petcare === true} onBlur={handleBlur} onChange={() => {
            changePetcareTo(true)
        }}/> Yes
        <NoRadioButton type="radio" name="petcare" checked={petcare === false} onBlur={handleBlur} onChange={()=> {
            changePetcareTo(false)
        }}/>No 
        </RadioButtonContainer>
        <br/>
        </FamilyMembersStyle>
    )
}

export default FamilyMembersRadioButtons;