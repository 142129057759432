import React, { useState, useMemo } from 'react';
import moment from 'moment';
import AddTaskModalDialog from './TaskSeries/AddModal'
import styled from 'styled-components';
import AddIcon from '../../assets/add.svg';
import NextIcon from '../../assets/next.svg';
import PreviousIcon from '../../assets/previous.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setWeek, fetchTaskSeries } from '../../data/actions';
import { useUser } from '../../auth/UserContext';

const WeekDay = styled.div`
    max-width: 120px;
    flex: 1 1 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: ${props => props.theme.mrEaves};
    font-size: 18px;
    color: #69469d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 70, 157);
    text-align: center;

    & p {
        font-size: 26px;
        color: #060368;
        font-weight: 400;
        text-decoration: none solid rgb(6, 3, 104);
        text-align: center;
        margin: 0px;
    }


    @media (max-width: 375px) {
        & div {
            font-size: 14px;
        }
        & p {
            font-size: 18px;
        }
    }

`

const Root = styled.div`
    max-width: 1080px;
    justify-content: flex-start;
    height: 110px;
    display: flex;
    flex-direction: column;
    background: white;

    & .current-month {
        height: 31px;
        font-family: ${props => props.theme.mrEaves};
        font-size: 26px;
        color: #060368;
        font-weight: 400;
        text-decoration: none solid rgb(6, 3, 104);
        text-align: center;
        margin: 0px;
    }
`

const AddButton = styled.button`
    max-width: 240px;
    flex: 2 2 20%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: none;
    border: none;
    padding-left: 12px;

    :hover {
        cursor: pointer;
    }

    & > img {
        object-fit: contain;
        flex: 1 1 30%;
    }

    & > div {
        flex: 2 2 60%;
        font-weight: bold;
    }

    @media (max-width: 675px) {
        & > img {
            width: 25px;
            height: 25px;
        }
    }

    @media (max-width: 375px) {
        & > img {
            width: 20px;
            height: 20px;
        }
    }
`

const Title = styled.div`
    max-width: 125px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 24px;
    color: #69469d;
    font-weight: 400;
    text-align: left;
    margin: 0px 5px;
    margin-left: 9px;

    @media (max-width: 675px) and (min-width: 375px) {
        font-size: 18px;
    }

    @media (max-width: 375px) {
        font-size: 14px;
    }
`

const HeaderMonth = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 5px 0px 5px 0px;
    & > h2 {
        font-family: ${props => props.theme.mrEaves};
        font-size: 26px;
        color: #060368;
        font-weight: 400;
        text-decoration: none solid rgb(6, 3, 104);
        text-align: center;
        margin: 0px;
        width: 100%;
        position: absolute;
    }
`

const HeaderWeek = styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: flex-end;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    margin: 0px 20px 0px 20px;
    z-index: 1;

    @media (max-width: 675px) {
        width: 100%;
    }
`

const ArrowButton = styled.button`
    background: none;
    border: none;
    height: 44px;
    width: 44px;
    margin: 0px;
    padding: 0px;
    border-radius: 22px;
    pointer-events: ${p => p.clickable ? 'auto':'none'}
    & img {
        height: 44px;
        width: 44px;
        border-radius: 22px;
        border: none;
        :hover {
            background: white; 
        }
    }
    
    :focus {
        outline: none;
    }

    :hover {
        cursor: pointer;
    }

    @media (max-width: 375px) {
        & img {
            height: 37px;
            width: 37px;
        }
    }
`

const LeftIcon = styled.img`
    max-width: 30px;
    height: 30px;
`

const getMonth = (sunday) => {
    let sundayMonth = moment(sunday).format('MMMM')
    let saturdayMonth = moment(sunday).add(6, 'day').format('MMMM')
    if (sundayMonth === saturdayMonth) {
        return `${sundayMonth}`
    } else {
        return `${sundayMonth} / ${saturdayMonth}`
    }
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri" , "Sat"]

const checkMissionStart = (mission, newWeekStart) => {
    const { creation_date } = mission
    const missionStart = moment(creation_date)
    const missionEnd = missionStart.clone().add(1, 'year')
    const newWeekEnd = moment(newWeekStart).endOf('week')
    return moment(newWeekEnd).isBetween(missionStart, missionEnd)
}

const checkMissionEnd = (mission, newWeekStart) => {
    const { creation_date } = mission
    const missionStart = moment(creation_date)
    const missionEnd = missionStart.clone().add(1, 'year')
    return moment(newWeekStart).isBetween(missionStart, missionEnd)
}

function CalendarHeader(props) {

    const { weekStart, weekEnd, setWeek, fetchTaskSeries, mission } = props
    const { membership } = useUser()
    const [showModal, setShowModal] = useState(false)

    const nextClickable = useMemo(() => {
        if (mission && mission.creation_date) {
            const nextSunday = moment(weekStart).add(1, 'week');
            return checkMissionEnd(mission, nextSunday)
        } else {
            return false
        }
    }, [mission, weekStart])

    const prevClickable = useMemo(() => {
        if (mission && mission.creation_date) {
            const previousSunday = moment(weekStart).add(-1, 'week');
            return checkMissionStart(mission, previousSunday)
        } else {
            return false
        }
    }, [mission, weekStart])

    const updateWeekStartDate = (newDateStart) => {
        const newDateEnd = newDateStart.clone().endOf('week');
        setWeek(newDateStart, newDateEnd)
        fetchTaskSeries(membership.mission_uuid, newDateStart, newDateEnd)
    }

    const nextWeekPressed = () => {
        const nextSunday = moment(weekStart).add(1, 'week');
        updateWeekStartDate(nextSunday);   
    }

    const previousWeekPressed = () => {
        const previousSunday = moment(weekStart).add(-1, 'week');
        updateWeekStartDate(previousSunday);
    }

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    return (
            <Root>
            <HeaderMonth>
                <h2 style={{flexBasis: 600}}>{getMonth(weekStart)}</h2>
                <ButtonWrapper>
                    <ArrowButton 
                        onClick={previousWeekPressed} 
                        clickable={prevClickable}>
                        <img src={PreviousIcon} alt="prev"/>
                    </ArrowButton>
                    <ArrowButton 
                        onClick={nextWeekPressed}
                        clickable={nextClickable}>
                        <img src={NextIcon}  alt="next"/>
                    </ArrowButton>
                </ButtonWrapper>
            </HeaderMonth>
            <HeaderWeek>
                <AddButton onClick={handleShowModal}>
                    <LeftIcon src={AddIcon} alt="plus"/>
                    <Title>Add Tasks</Title>
                </AddButton> 
                {
                    weekDays.map((item, idex) => {
                        const calendarDay = moment(weekStart).day(idex).format('DD').toString()
                        return (
                            <WeekDay key={idex}>
                                <div>{item}</div>
                                <p style={{fontWeight: 'bold', fontFamily: "mrs-eaves-xl-serif, serif"}}>
                                    {calendarDay}
                                </p>
                            </WeekDay>
                        )
                    })
                }
            </HeaderWeek>
            {showModal ? <AddTaskModalDialog isOpen={showModal} 
                closeHandler={handleShowModal} 
                membership={membership}
            /> : <div></div>}
        </Root>
    );
}

CalendarHeader.propTypes = {
    updateWeekStartDate: PropTypes.func
}

const mapStateToProps = (state) => ({
    mission: state.VillageReducer.mission,
    weekStart: state.VillageReducer.weekStart,
    weekEnd: state.VillageReducer.weekEnd,
})

export default connect(mapStateToProps, { setWeek, fetchTaskSeries })(CalendarHeader);
