import React from 'react';
import Timeslot from '../Timeslot';
import styled from 'styled-components';
import moment from 'moment';
import UpdateModal from './UpdateModal';
import { connect } from 'react-redux';

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
`

const TaskSeriesTitle = styled.div`
    font-weight: bold;
    width: 20%;
    flex: 2 2 20%;
    height: 87px;
    background-color: #ebe9f4;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const TaskSeriesCard = styled.div`
    max-width: 220px;
    margin: 0px 10px;
    height: 75px;
    padding: 10px;
    flex-grow: 1;
    border: 2px solid #a694c7;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.05);

    font-weight: bold;
    font-family: ${props => props.theme.mrEaves};
    font-size: 26px;
    color: #69489d;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;

    & img {
        height: 35px;
    }

    & p {
        flex: 3 3 75%;
        margin: 0 5px 0 10px;
        text-align: left;
        font-size: 22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }  
    :hover {
        cursor: pointer;
        background-color: #ebe9f4;
    }

    @media(min-width: 375px) and (max-width: 875px) {
        flex-direction: column;
        padding: 5px;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        & > img {
            height: 25px;
        }

        & > p {
            font-size: 18px;
            text-align: center;
            margin: 0px;
            max-height: 20px
            width: 100%;
        }
    } 

    @media(max-width: 375px) {
        flex-direction: column;
        padding: 5px;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        & > img {
            height: 25px;
            align-self: center;
        }

        & > p {
            font-size: 16px;
            margin: 0px;
            max-height: 20px
            text-align: center;
            width: 100%;
        }
    }
`

function TaskSeries(props) {
    
    const [weekTimeslots, setWeekTimeslots] = React.useState([])
    const [isModalOpen, setModalOpen] = React.useState(false);
    const { taskSeries, weekStart, weekEnd } = props;
    
    const { 
        series_id, 
        timeslots, 
        start_date, 
        end_date, 
        preferences, 
        recurring_days, 
        recurring_cadence,
        iconUrl,
        title,
        third_party_allow,
    } = taskSeries

    React.useEffect(() => {
        const _timeslots = selectTimeslotsForWeek(timeslots, weekStart, weekEnd)
        setWeekTimeslots(_timeslots)
    }, [weekStart, weekEnd, timeslots])

    const handleModal = () => {
        setModalOpen(!isModalOpen)
    }

    const selectTimeslotsForWeek = (timeslots, weekStart, weekEnd) => {

        const filterd = timeslots.filter(item => {
            return (
                moment(item.date) >= weekStart && 
                moment(item.date) < weekEnd
            )
        })

        const timeslotsForThisWeek = [0, 1, 2, 3, 4, 5, 6].map(i => {
            const _weekdate = moment(weekStart).add(i, 'day')
            const _timeslot = filterd.find(el => {
                if (
                    moment(el.date) >= _weekdate.startOf('day') && 
                    moment(el.date) <= _weekdate.endOf('day')
                ) {
                    return true
                }
            })
            return (
                _timeslot ? 
                _timeslot : 
                {
                    date: _weekdate.toISOString(), 
                    assignee: null, 
                    isEnabled: false
                }
            )
        })
        return timeslotsForThisWeek
    }

    return (
    <RowContainer>
        <TaskSeriesTitle>
            <TaskSeriesCard title={title} onClick={handleModal}>
                <img src={iconUrl} alt="ts-icon"/>
                <p>{title}</p>
            </TaskSeriesCard>
            {isModalOpen ? <UpdateModal 
                isOpen={true} 
                setOpen={handleModal}
                title={title}
                series_id={series_id}
                start_date={start_date}
                end_date={end_date}
                preferences={preferences}
                recurring_days={recurring_days}
                recurring_cadence={recurring_cadence}
                thirdPartyAllow={third_party_allow}
            /> : null}
        </TaskSeriesTitle>
        {
            weekTimeslots.map((item, index) => {
                return (
                <Timeslot 
                    key={index}
                    showDayModal={props.showDayModal} 
                    backgroundColor={props.isOddRow ? "rgba(235, 233, 244, 0.3)" : "white"}
                    timeslot={item}
                    series_id={series_id}
                />)
            })           
        }
    </RowContainer>);
}

const mapStateToProps = (state) => ({
    weekStart: state.VillageReducer.weekStart,
    weekEnd: state.VillageReducer.weekEnd
})

export default connect(mapStateToProps, {})(TaskSeries);