import React from 'react';
import styled from 'styled-components';

const DropdownRoot = styled.div`
    position: absolute;
    right: 15px;
    z-index: 1000;
    top: 10px;
    transition-duration: 2s;

`

const DropdownButton = styled.button`
    border: none;
    cursor: pointer;
    font-family: var(--font-mrsEaves);
    font-size: 14px;
    color: #060368;
    text-align: right;
    background: none;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
`

const DropdownContent = styled.div`
    display: block;
    visibility: ${props => props.isShown ? "visible" : "hidden"};
    opacity: ${props => props.isShown ? "1" : "0"};
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    border: 1px solid #ddd;
    z-index: 2000;
    right: 0;
    top: 20px;

    transition: visibility 0s, opacity 0.6s linear;

    & span {
        color: #060368;
        font-size: 14px;
        padding: 10px 10px;
        text-decoration: none;
        display: block;
        text-align: left;
        border-top: 1px solid #ddd;
      }
    
    & span:hover {
        background-color: #ddd
        cursor: pointer;
    }
`

const Dropdown = ({children, isShown, setIsShown}) => {

    const toggleMenu = () => {
        setIsShown(!isShown)
    }
    
    return (
    <DropdownRoot>
        <DropdownButton onClick={toggleMenu}>My Account</DropdownButton>
            <DropdownContent isShown={isShown} onClick={toggleMenu}>
                {children}
            </DropdownContent>
    </DropdownRoot>)
}

export default Dropdown;