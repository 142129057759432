import React from 'react'
import styled from 'styled-components';
import {CheckableListItem} from '../general/CheckableListItem';

const GuideRoot = styled.div`
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:#ebe9f4;
    fill: #ebe9f4;
    padding-bottom: 10px;
    padding-top: 8px; 
    margin-top: 0.5px;
`
const Label = styled.div`
    text-align: left;
    margin-left: 14px;
    font-family:mrs-eaves-xl-serif, serif;
    font-size: 14px;
    color: #111111;
    text-decoration: none solid rgb(6, 3, 104);
`

function RequirementsGuide(props) {
    const items = props.requirements
    if (items != null && !props.hidden) {
        return(
            <GuideRoot>
            <Label>{props.title}</Label>
            {items.map((requirement, index) => {
                return <CheckableListItem key={index} requirement={requirement} value={props.value}></CheckableListItem>
            })}
        </GuideRoot>
        )
    } else {
        return null;
    }
}

export default RequirementsGuide;