import taskApi from '../api/task.api';
import { categories } from '../../shared-lib/utils/taskSeries';

export const prepareTaskSeriesCrudData = (reduxState, missionUuid, taskSeriesCategoriesData) => {

  const { other, selectedTaskFields } = taskSeriesCategoriesData;

  const existingTaskSeries = reduxState.VillageReducer.taskSeries || [];

  const resultItemsToCreate = [];
  const resultItemsToUpdate = [];
  const resultItemsToDelete = [];
  
  if (other && other.items) {

      // List of [ {title, seriesId}, ... ]
      const payloadOtherItems = other && other.items;
      const payloadSeriesIdToItemMap = payloadOtherItems
          .filter(({ seriesId }) => !!seriesId)
          .reduce((map, item) => map.set(item.seriesId, item), new Map());

      // this Items already exists in the current Redux state and on BE side
      const existingOtherItems = existingTaskSeries.filter(item => {
          const category = item.category || '';
          const otherCategory = categories.other;
          return category.toUpperCase() === otherCategory.toUpperCase();
      });
      const existingSeriesIdToItemMap = existingOtherItems.reduce(
          (map, item) => map.set(item.series_id, item), 
          new Map());


      const otherItemsToCreate = payloadOtherItems
          .filter(({ seriesId }) => existingSeriesIdToItemMap.has(seriesId) === false)
          .map(({ title }) => {
            const recurringDays = taskApi.defaultRecurringDays(title);
            const recurringPeriod = taskApi.defaultRecurringPeriod(title);
            return taskApi.makeTask(null, title, categories.other, recurringDays, recurringPeriod);
          });

      const otherItemsToUpdate = payloadOtherItems
          .filter(({ seriesId, title }) => {
              const existingItem = existingSeriesIdToItemMap.get(seriesId);
              if (!existingItem) {
                  return false;
              }
              if (title === existingItem.title) {
                  return false;
              }
              return true;                
          })
          .map(({ seriesId, title }) => {
            const recurringDays = existingTaskSeries.filter(i => i.series_id === seriesId)[0].recurring_days;
            const recurringPeriod = existingTaskSeries.filter(i => i.series_id === seriesId)[0].recurring_cadence;
            return taskApi.makeTask(seriesId, title, categories.other, recurringDays, recurringPeriod);
          });

      const otherItemsToDelete = existingOtherItems
          .filter(({ series_id }) => payloadSeriesIdToItemMap.has(series_id) === false)
          .map(({ series_id, title }) => ({ seriesId: series_id, title: title}));
      
      resultItemsToCreate.push(...otherItemsToCreate);
      resultItemsToUpdate.push(...otherItemsToUpdate);        
      resultItemsToDelete.push(...otherItemsToDelete);
  }

  if (selectedTaskFields.length > 0) {
    const itemsToCreate = selectedTaskFields
        .filter((item) => {
            return item.method === "create"
        })
        .map((item) => {      
            const recurringDays = taskApi.defaultRecurringDays(item.name)
            const recurringPeriod = taskApi.defaultRecurringPeriod(item.name)
            return taskApi.makeTask(null, item.name, item.category, recurringDays, recurringPeriod);
        });
    const itemsToDelete = selectedTaskFields
        .filter((item) => {
            return item.method === "delete";
        })
        .map((item) => {
            const { taskSeries } = item
            return { seriesId: taskSeries.series_id, title: taskSeries.title}
        })

    resultItemsToCreate.push(...itemsToCreate)
    resultItemsToDelete.push(...itemsToDelete)
  }

  return {
      metadata: {
          missionUuid: missionUuid,
      },
      create: resultItemsToCreate,
      update: resultItemsToUpdate,
      delete: resultItemsToDelete
  };
};


export default {
  prepareTaskSeriesCrudData
};