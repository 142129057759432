import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SignupPage from '../../Signup';
import { connect } from 'react-redux';
import {
    RootContainer,
    Title,
    Subtitle,
    PurpleText,
    SmallText,
 } from './style';
import { useAuth0 } from '../../../shared-lib/auth-provider';
import { useAnalytics } from '../../../analytics/AnalyticsContext';
import { useUser } from '../../../auth/UserContext';
import { ActivityIndicator } from '../../../shared-lib/components/general/ActivityIndicator';


 export const CreateAccountButton = styled.button`
    width: 310px;
    height: 76px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;

    &:hover {
        cursor: pointer;
        background-color: #060368;
    },
    &:active: {
        border: 2px solid #ffffff
    },
    &:focus : {
        outline:none
    },
    &:disabled: {
        border: 1px solid #cccccc,
        background-color: #ffffff
    },
`


function Schedule(props) {

    const [openModal, setOpenModal] = useState(false)
    const { isAuthenticated } = useAuth0()
    const [createMissionPending, setCreateMissionPending] = useState(false);
    const { user, signupNewUser, createNewMissionForExistingUser } = useUser()

    var personPassed = props.nameOfPassedAway
    if (!personPassed) {
        personPassed =  ""
    }

    const { track, pageview } = useAnalytics()

    useEffect(() => {
      track("Page View", {"Page Name": "Wizard - Recommendations"})
      pageview("/wizard/schedule", [], "Wizard - Recommendations")
      // eslint-disable-next-line
    }, [])

    const handleCreateVillage = async () => {

        const args = {
            email: user.email, 
            relatives: props.relatives, 
            tasksSelected: props.tasksSelected,
            person_passed: personPassed,
            important_date_list: props.important_date_list,
            name: user && user.name,
        }
        if (isAuthenticated) {
            if (createMissionPending) {return};
            setCreateMissionPending(true);
            try {
                await createNewMissionForExistingUser(args);
            } catch(e) {}
            setCreateMissionPending(false)
        } else {
            await signupNewUser(args, "org")
        }
    }

    return (<RootContainer>
        { props.wizardState.step1 === 'myself' ? 
            (<div>
                <Title>Your village is eager to help</Title>
                <Subtitle>
                    Based on a combination of your answers and our research, we have created a personalized plan of support for your family.
                </Subtitle>            
                <SmallText>
                    At one point or another, we all experience difficult times. During this time it’s okay (and healthy!) 
                    to lean on those around you. Below, you will find a list of ways your loved ones can support you during this time. 
                    Because there’s nothing worse than trying to think of a response to: “How can I help?”
                </SmallText>
            </div>) 
        : (<div>
            <Title>Ways to help the {personPassed} family</Title>
                <Subtitle>
                    Based on a combination of your answers and our research, we have created a personalized plan to help the family during this time.
                </Subtitle>            
                <SmallText>
                Oftentimes, people experiencing difficult events don’t even know what type of support they need. 
                Taking away the burden to ask for help is one of the greatest gifts you can give to someone who is grieving. 
                Below, you will find a list of ways you can support your loved one during this time. 
                Because there’s nothing worse than feeling helpless.
                </SmallText>
            </div>) 
    }
        <PurpleText>
            {
                isAuthenticated ?
                "Add more"
                :
                "Create an account to invite people and customize"
            }
        </PurpleText>
        <SmallText>
            {
                isAuthenticated ? 
                "Save your progress and get all the details on how you can help."
                :
                "Save your progress and get all the details on how you can help by creating a free account."
            }
            
        </SmallText>
        {
            isAuthenticated ? 
            <ActivityIndicator active={createMissionPending}>
                <CreateAccountButton onClick={handleCreateVillage}>
                    Save and create village
                </CreateAccountButton>
            </ActivityIndicator>
            :
            <CreateAccountButton
                onClick={() => setOpenModal(true)}>
                Create free account
            </CreateAccountButton>
        }
        <SignupPage 
            isModalOpen={openModal} 
            handleModalClose={() => setOpenModal(false)}
        />
        </RootContainer>)
}

const mapStateToProps = (state) => {
    return {
        wizardState: state.WizardReducer,
        nameOfPassedAway: state.WizardReducer.step3.name,
        villageCreated: state.VillageReducer.villageCreated,
        villageCreateError: state.VillageReducer.villageCreateError,
        relatives: state.WizardReducer.step3.familyMembers,
        tasksSelected: state.WizardReducer.step4,
    }
}

export default connect(mapStateToProps, {})(Schedule);