import React from 'react';
import styled from 'styled-components';


const DropDownSelect = styled.select`
    width: 100%;
    height: 41px;
    font-size: 21px;
    box-shadow: inset 0px 1px 2px rgba(0,0,0,0.2);
    margin: 10px 30px;
`

function DropDown({calendarSelected, handleChangeToSelectedCalendar}) {

   const handleChange = (event) => {
    handleChangeToSelectedCalendar(event.target.value)
   }
 
    return(
        <DropDownSelect value={calendarSelected} onChange={handleChange}>
            <option selected value="">Select calendar</option>
            <option value="Google">Google calendar</option>
            {/* <option value="Apple">Apple calendar</option> TODO: add backin */}
        </DropDownSelect>
    )   
}

export default DropDown;