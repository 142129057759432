import React, { useState, useEffect, Fragment } from "react";
import { normalizedMessage } from "../../shared-lib/utils/error_normalizer";
import { useAnalytics } from "../../analytics/AnalyticsContext";
import { useUser } from "../../auth/UserContext";
import Modal from '../../shared-lib/components/general/Modal';
import SigninForm from '../../shared-lib/components/user_entry/SigninForm';
import SignupForm from '../../shared-lib/components/user_entry/SignupForm';
import SignupToggle from '../../shared-lib/components/user_entry/SignupToggle';
import PasswordResetForm from '../../shared-lib/components/user_entry/PasswordResetForm';
import { FormContainer, Title, Subtitle, Instruction, InstructionDetail } from './styles';
import BackgroundPattern from '../../shared-lib/components/BackgroundPattern';
import Menubar from '../../shared-lib/components/Menubar';
import TitleText from '../TaskPage/TitleText';
import TaskPage from '../TaskPage';
import { useInvite } from "./inviteHook";
import InviteInvalidModal from '../Invitations/InviteInvalidModal';
import Spinner from "../../shared-lib/components/Spinner";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../shared-lib/auth-provider";
import { Helmet } from "react-helmet";

const RecipientTitle = () => (
  <Fragment>
    <Title>We are sorry for your loss.</Title>
    <Subtitle>Savo is a place where your friends and family can come together and help out during this difficult time.</Subtitle>
    <Instruction>Create an account</Instruction>
    <InstructionDetail>You'll be able to view and manage tasks whenever you want, as well as pause your village if needed.</InstructionDetail>
  </Fragment>
)

const ContributorTitle = ({personPassed=null}) => {
  const family = personPassed ? `for ${personPassed}'s Family` : ""
  return (<Fragment>
    <Title>{`Welcome to the village ${family}`}</Title>
  </Fragment>)
}

const ContributorBackground = ({personPassed=""}) => (
  <Fragment>
    <div className="app-header">
        <BackgroundPattern />
        <Menubar hideAccount={true}/>
        <TitleText person_passed={personPassed}/>
    </div>
    <TaskPage useDummy={true}/>
  </Fragment>
)

function SignupPage({
  isModalOpen = true,
  handleModalClose,
}) {

  // MARK: - React Hooks
  const { track, pageview } = useAnalytics();
  const history = useHistory()
  const { isAuthenticated } = useAuth0()
  const {
    checkUserExist,
    signupNewUser,
    signinExistUser,
    resetPassword,
  } = useUser();

  // MARK: - Getting information from the url
  const { 
    role, 
    missionUuid, 
    personPassed, 
    inviteStatus,
    inviteUuid,
    tasksSelected,
    relatives,
    fetchingInvite, 
    inviteEmail,
    oldInviteRole
  } = useInvite()

  // MARK: - local state
  const [signupError, setSignupError] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const [isSignin, setIsSignin] = useState(false)
  const [isPasswordResetHidden, setPasswordResetHidden] = useState(true)
  const [emailAddress, setEmailAddress] = useState()

  // MARK: - local methods
  const handleLogin = async ({ email, password }) => {
    try {
      setRequestPending(true);
      await signinExistUser({
        email,
        password,
        mission_uuid: missionUuid,
        role: role,
        relatives: relatives,
        tasksSelected: tasksSelected,
        person_passed: personPassed,
        inviteUuid,
        inviteStatus,
      });
    } catch (error) {
      setSignupError(normalizedMessage(error));
      console.trace(error);
    } finally {
      window.scrollTo(0, 0);
      setRequestPending(false);
    }
  };

  const handleSignup = async ({ email, password, name }) => {
    try {
      setRequestPending(true);
      await signupNewUser({
        name,
        password,
        email,
        role: role,
        mission_uuid: missionUuid,
        person_passed: personPassed,
        relatives: relatives,
        tasksSelected: tasksSelected,
        inviteUuid: inviteUuid,
        isEmailEnabled: true // set email default
      });

    } catch (error) {
      setSignupError(normalizedMessage(error));
      console.trace(error);
    } finally {
      window.scrollTo(0, 0);
      setRequestPending(false);
    }
  };

  const handleCheckUserExist = async (email) => {
    try {
      const res = await checkUserExist(email);
      if (res.user_exist) {
        const existError = { code: "user_exists" };
        setSignupError(normalizedMessage(existError));
      } else {
        setSignupError(null);
      }
    } catch (error) {
      setSignupError(normalizedMessage(error));
    }
  };

  const handlePasswordReset = async ({ email }) => {
    try {
      await resetPassword(email);
    } catch (error) {
      console.error("handlePasswordReset.error", error);
    }
  };

  const togglePasswordReset = (isHidden, emailAddress) => {
    setPasswordResetHidden(isHidden)
    setEmailAddress(emailAddress)
  }

  const handleRadio = (e) => {
    if (e.target.value === "signup_form") {
        setIsSignin(false)
    } else {
        setIsSignin(true)
    }
  }

  const handleRedirect = () => {
    if (isAuthenticated) {
      history.replace('/mission/dashboard/villages')
    } else {
      history.replace('/')
    }
  }

  // MARK: - side effects
  useEffect(() => {
    track("Signup");
    pageview("Signup");
    // eslint-disable-next-line
  }, []);
  
  if (fetchingInvite) {
    return (
    <div style={{height: '100vh'}}>
      <Spinner/>
    </div>)
  } else {
    if (
      inviteUuid && 
      role === "rec" && 
      inviteStatus === "accepted"
    ) {
      return <InviteInvalidModal handleClose={handleRedirect}/>
    }
    // if old invite is cont, it will allow to signup
    if (oldInviteRole === "rec") {
      return <InviteInvalidModal reason='expired' handleClose={handleRedirect}/>
    }
  }

  return (
    <Fragment>
    <Helmet>
    <meta property="og:site_name" content="Savo" />
    <meta property="og:title" content="Sign up to help" />
    <meta property="og:description" content="Savo brings friends and family together to support a loved one during a difficult time." />
    <meta property="og:image" content="https://joinsavo.com/wp-content/uploads/2020/05/Savo-Facebook-image-1.jpg" />
    <meta property="og:url" content={process.env.PUBLIC_URL} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content="https://joinsavo.com/wp-content/uploads/2020/05/Savo-Twitter-image-1.jpg" />
    <meta name="twitter:image:alt" content="joinsavo.com" />

    </Helmet>
    <Modal
      open={isModalOpen}
      handleClose={handleModalClose}
      closable={false}
      title={role === "cont" ? <ContributorTitle personPassed={personPassed}/> : role === "rec" ? <RecipientTitle/> : <Title>Create a free account</Title>}
      background={role === "cont" ? <ContributorBackground/> : null}
      error={signupError}
      fullscreen={role === "rec"}
    >
      <FormContainer>
        {role === "cont" && (
          <Subtitle show={true}>
            Savo provides an easy way for friends and family to help out in
            times of need. You can volunteer for tasks like cleaning, delivering
            meals, and emotional support.
          </Subtitle>
        )}
        <SignupToggle handleRadio={handleRadio} isSignin={isSignin} />
        {!isSignin ? (
          <SignupForm
            submitHandler={handleSignup}
            checkUserExist={handleCheckUserExist}
            requestPending={requestPending}
            isHidden={isSignin}
            prefill={{email: inviteEmail}}
          />
        ) : isPasswordResetHidden ? (
          <SigninForm
            submitHandler={handleLogin}
            requestPending={requestPending}
            isHidden={!isSignin || !isPasswordResetHidden}
            togglePasswordReset={togglePasswordReset}
            emailAddress={emailAddress || inviteEmail}
          />
        ) : (
          <PasswordResetForm
            submitHandler={handlePasswordReset}
            isHidden={!isSignin || isPasswordResetHidden}
            togglePasswordReset={togglePasswordReset}
            emailAddress={emailAddress}
          />
        )}
      </FormContainer>
    </Modal>
    </Fragment>
  );
}

export default SignupPage;
