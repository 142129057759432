import React, { useState, useEffect, useMemo } from 'react';
import { useUser } from '../../../auth/UserContext';
import { useAnalytics } from '../../../analytics/AnalyticsContext';
import EmailAddressInput from './EmailAddressInput';
import EmailInputModel from '../../../shared-lib/utils/email_input_model';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import FormField, { getEmailConfig } from '../../../shared-lib/components/user_entry/FormField';
import Button from '../../../shared-lib/components/Button';
import LightButton from '../../../shared-lib/components/LightButton';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { sendEmailInvitations } from '../../../data/actions';
import Spinner from '../../../shared-lib/components/Spinner';
import { Status } from '../../../data/api';

const Footer = styled.p`
	color: #111111;
	font-size: 15px;
	font-family: var(--font-mrsEaves);
	margin-top: -10px;
`

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction:row;
	align-items: center;
	justify-content: space-between;

	& > .spacer  {
		width: 24px;;
	  }
`

function EmailPanel({
	personPassed, 
	inviteAs, 
	handleClose, 
	bodyHeight='500px',
	maxWidth='100%',
	sendEmailInvitations,
	sendEmailInvitationsState,
}) {
	
	const { user, membership } = useUser()
	const userName = user.name

	const [emailModel, setEmailModel] = useState(EmailInputModel({}));

	const { 
		status:sendStatus, 
		error:sendError, 
		response:sendResponse 
	} = sendEmailInvitationsState

	const validationSchema = Yup.object().shape({
		subject: Yup.string().required(),
		body: Yup.string().required(),
	})

	const showCancelButton = handleClose && true

	const { track, pageview } = useAnalytics();
    
  useEffect(() => {
		track("Page View", {"Page Name": "Invitations - Email"})
		pageview("/invitations/email",[], "Invitations - Email")
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (sendStatus === 'success') {
			setEmailModel(EmailInputModel({}))
		}
	}, [sendStatus])
	

	const submitInvite = (values) => {
			
		const {
			user_uuid,
			mission_uuid,
			person_passed,
		} = membership

		const data = {
			emails: emailModel.getValid(),
			body: values.body,
			subject: values.subject,
			mission_uuid,
			user_uuid,
			role: inviteAs,
			person_passed
		}

		sendEmailInvitations(data)
	};

	const defaultText = () => {
		const line1 = 'Hello,';
		let line2 = '';
		let line3 = '';
		let line4 = '';
		let line5 = '';
		if (inviteAs === 'rec') {
			line2 = "\n\nDuring this difficult time, I wanted to make sure you are taken care of."
			line3 = "\n\nI'm just letting you know I have created a Village for you on Savo so that friends and family can come together and help out. It's a way for us to coordinate and provide help where needed on day-to-day tasks."
			line4 = "\n\nThere is nothing you need to do in the Village right now. At any point in time you can log in and update your information, manage the tasks, and see what is coming up."
			line5 = "\n\nWith love,\n" + userName;
		} else {
			line2 = "\n\nAs you know, the " + personPassed + " family is going through a difficult time. We want to help out the family so we have created a place for everyone to sign up for different tasks."
			line3 = "\n\nIt doesn't matter how big or small a task you volunteer for, it will help the family greatly and help provide them time and space."
			line4 = "\n\nTogether, we will make this tough time a little easier."
			line5 = "\n\n- " + userName
		}
		return line1 + line2 + line3 + line4 + line5;
	}

	const subj = inviteAs === 'rec' ? 'A village to help during this difficult time' : `Help the ${personPassed} family through Savo`;

	const formik = useFormik({
        initialValues: {body: defaultText(), subject: subj},
        onSubmit: submitInvite,
        validationSchema: validationSchema
	})
	
	const {subjectConfig, bodyConfig} = useMemo(() => {
		return {
			subjectConfig: getEmailConfig('subject', formik),
			bodyConfig: getEmailConfig('body', {...formik, height: bodyHeight})
		}
		
	}, [formik, bodyHeight])

	const submitEnabled = emailModel.nonEmptyAndAllValid() && !(formik.errors.body || formik.errors.subject)

	const buttonStyle = {width: '140px'};

	const Loader = () => (
		<div style={{height: 57}}><Spinner /></div>
	)
	return (
		<div className="email-panel">
			<EmailAddressInput emailModel={emailModel} setEmailModel={setEmailModel} maxWidth={maxWidth}/>
			<Formik>
			<Form style={{width: '100%', display: 'flex', flexDirection:'column', alignItems: 'center'}} onSubmit={formik.handleSubmit}>
			<FormField config={subjectConfig}/>
			<FormField config={bodyConfig}/>
			<Footer>A button with a link to the village will be added automatically to the end of the message.</Footer>
			<ButtonWrapper>
				{
					showCancelButton && sendStatus !== Status.loading ? 
					<LightButton enabled={true} onClick={handleClose} style={buttonStyle}>
						Cancel
					</LightButton> : null
				}
				<div className='spacer' hidden={!showCancelButton}></div>
				{sendStatus === Status.loading ? <Loader /> :
					<Button type="submit" enabled={submitEnabled} style={buttonStyle}>
					Invite
					</Button>
				}
			</ButtonWrapper>
			</Form>
			</Formik>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
	}
}
export default connect(mapStateToProps, {sendEmailInvitations})(EmailPanel);
