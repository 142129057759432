import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { 
    updateTaskSeries, 
    updateTaskSeriesReset,
    fetchTaskSeries, 
    deleteTaskSeries,
    deleteTaskSeriesReset,
 } from '../../../../data/actions';
import moment from 'moment';
import Modal from '../../../../shared-lib/components/general/Modal';
import { useUser } from '../../../../auth/UserContext';
import { ActivityIndicator } from '../../../../shared-lib/components/general/ActivityIndicator';
import { normalizedMessage } from '../../../../shared-lib/utils/error_normalizer';
import DatePicker from '../../../../shared-lib/components/DatePicker';
import { useAnalytics } from '../../../../analytics/AnalyticsContext';

const Title = styled.div`
    font-weight: bold;
    font-family: ${props => props.theme.mrEaves};
    font-size: 42px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
`

const Subtitle = styled.div`
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 26px;
    text-align: center;
`

const WeekRepeatContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 50px 0px 20px 0px;
    label {
        height: 18px;
        font-size: 18px;
        color: #060368;
        font-weight: bold;
        font-style: italic;
        text-decoration: none solid rgb(6, 3, 104);
    }
`

const WeekdayContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%
    margin: 20px 0px 20px 0px;
`
const WeekdayCheckbox = styled.label`
    width: 54px;
    height: 54px;
    border-radius: 29px;
    position: relative;
    border: ${({isChecked}) => isChecked ? "2px solid #060368" : "2px solid #a694c7"} ;
    background-color: ${({isChecked}) => isChecked ? "#060368" : "#ebe9f4"};
    font-family: var(--font-mrEaves);
    font-size: 21px;
    font-weight: bold;
    color: ${({isChecked}) => isChecked ? "white" : "#060368"};;
    transition: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        opacity: 0;
        cursor: pointer;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 0;
    }

    @media (hover: hover) {
        &:hover {
            border: 2px solid #a694c7;
            background-color: #69469d;
            color: white;
        }
    }

    @media(max-width: 450px) {
        width: 40px;
        height: 40px;
    }

    @media(max-width: 450px) {
        width: 40px;
        height: 40px;
    }
`

const DateTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    color: #060368;
    font-weight: 400;
    text-decoration: none solid rgb(6, 3, 104);
    border-bottom: 1px solid lightgray;
    padding-bottom: 40px;
    padding-top: 30px;

    label {
        font-style: italic;
        font-weight: bold;
    }

    @media(max-width: 500px) {
        flex-direction: column;
    }
`

const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 20px 0 0;
    @media(max-width: 500px) {
        width: 100%;
        margin: 10px 0;
    }
`


const WeekRepeat = styled.select`
    max-width: 125px;
    height: 45px;
    padding: 0px 8px;
    border: 1px solid #555555;
    border-radius: 5px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    background: white;
    margin: 0px 20px 0px 20px;
`

const PreferenceContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin: 20px 0px 20px 0px;
    textarea {
        resize: none;
        width: 100%;
        height: 166px;
        padding: 5px 10px;
        border: 1px solid #555555;
        border-radius: 5px;
        background-color: #ffffff;
        background-size: cover;
        font-family: ${props => props.theme.mrEaves};
        font-size: 21px;
        color: #111111;
        font-weight: 400;
        text-decoration: none solid rgb(17, 17, 17);
        box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
        box-sizing: border-box;
    }

    label {
        height: 18px;
        font-size: 18px;
        color: #060368;
        font-weight: 400;
        text-decoration: none solid rgb(6, 3, 104); 
        font-weight: bold;
        font-style: italic;
        display: flex;
        align-items: center;
        font-size: 21px
        margin: 0 0;
    }

    & #optionalLabel {
        font-style: italic;
        color: grey;
        margin-left: 5px;
        font-size: 14px;
        font-weight: normal;
        padding-top: 4px;
    }

    & .preferenceText {
        font-weight: normal;
        padding-bottom: 10px;
        line-height: 25px;
        font-size: 18px;
        margin-bottom: 0px;
    }
    
    input[type="checkbox"] {
        width: 21px;
        height: 21px;
        border: 2px solid #69469d;
        border-radius: 2px;
        background-color: #ffffff;
        background-size: cover;
    }

    a {
        font-family: ${props => props.theme.mrEaves};
        font-size: 21px;
        color: #69469d;
        font-weight: 400;
        text-decoration: none solid rgb(105, 70, 157);
    }
`
const ChildcarePreferenceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin: 0px 0px 10px 0px;
    
    & .preferenceText {
        font-weight: normal;
        padding-top: 10px;
        padding-bottom: 0px;
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`

const RemoveTaskButton = styled.label`
    color: #69469d;
    font-size: 21px;
    font-weight: 400;
    font-family: ${props => props.theme.mrEaves};
    border-bottom: dotted 1px #69469d;

    :hover {
        cursor: pointer;
        color: #060368;
        border-bottom: 1px solid #060368;
    }
`

const SaveButton = styled.button`
    width: 140px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin-left: 15px;
    font-weight: bold;

    :hover {
        background-color: #060368;
        cursor: pointer;
    }
    @media(max-width: 500px) {
        width: 120px;
    }
`

const CancelButton = styled.button`
    width: 140px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #69469d;
    background-color: white;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #69469d;
    text-align: center;
    margin-right: 15px;
    font-weight: bold;

    :hover {
        background-color: #060368;
        cursor: pointer;
        color: #ffffff;
        border-color: #060368;
    }

    @media(max-width: 500px) {
        width: 120px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
`

const parseWeekdays = (recurring_days) => {
    const init = [false, false, false, false, false, false, false]
    const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    return recurring_days
        .slice(1, -1)
        .split(",")
        .reduce((accu, val) => {
            let dayIndex = weekdays.findIndex(el => el === val)
            accu[dayIndex] = true
            return accu
        }, init)
}

const convertWeekdays = (recurring_days) => {
    let daysString = ""
    daysString += "{"
    recurring_days.map(i => {
        daysString = daysString + i + ","
    })
    daysString = daysString.slice(0, -1);
    daysString += "}"
    return daysString
}

const getWeekdays = (values) => {
    const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu' ,'fri', 'sat']
    return values.weekdays
        .reduce((accu, val, indx) => {
            return (val ? 
                accu.push(weekdays[indx]) && accu 
                :
                accu)
        }, new Array(0))
}

const validationSchema = Yup.object().shape({
    weekRepeat: Yup.string(),
})

function UpdateModal(props) {

    const { 
        setOpen, isOpen, 
        weekStart, weekEnd,
        updateTaskSeries,
        updateTaskSeriesReset,
        updateTaskSeriesSuccess,
        updateTaskSeriesError,
        deleteTaskSeries,
        deleteTaskSeriesReset,
        deleteTaskSeriesSuccess,
        deleteTaskSeriesError,
        fetchTaskSeries,
     } = props;
    const { 
        series_id, 
        title,
        start_date,
        end_date,
        recurring_cadence,
        recurring_days,
        preferences,
        thirdPartyAllow,
    } = props

    const [loading, setLoading] = useState(false)
    const { membership } = useUser();
    const mission_uuid = membership && membership.mission_uuid
    const { mpTrackTaskSeries } = useAnalytics();

    const initialValues = {
        weekdays: parseWeekdays(recurring_days),
        weekRepeat: recurring_cadence,
        startDate: moment(start_date).utc().format("YYYY-MM-DD"),
        endDate: moment(end_date).utc().format("YYYY-MM-DD"),
        time: "08:00",
        preferences: preferences === "preferences n/a" ? "" : preferences,
        thirdPartyAllow: thirdPartyAllow,
        exceptions: [{ type: "include", date: ""} ]
    }

    const hasFrequenceChange = (values) => {
        const weekRepeatPrev = recurring_cadence
        const weekRepeatNext = values.weekRepeat
        let hasWeekChanged = weekRepeatPrev !== weekRepeatNext

        const dowPrev = recurring_days
        const dowNext = convertWeekdays(values.recurring_days)
        let hasDayChanged = dowPrev !== dowNext
        
        return hasWeekChanged || hasDayChanged
    }

    const handleUpdate = (values) => {
        values.startDate = moment(values.startDate).format("YYYY-MM-DD")
        values.endDate = moment(values.endDate).format("YYYY-MM-DD")
        values.series_id = series_id
        values.recurring_days = getWeekdays(values)
        values.title = title
        values.frequenceChange = hasFrequenceChange(values)
        mpTrackTaskSeries(values, "update")
        updateTaskSeries(values)
        setLoading(true)
    }

    const handleDelete = () => {
        mpTrackTaskSeries({series_id}, "delete")
        deleteTaskSeries(series_id)
        setLoading(true)
    }

    const handleCloseModal = useCallback(() => {
        setOpen(false)
        updateTaskSeriesReset()
        deleteTaskSeriesReset()
    }, [setOpen, updateTaskSeriesReset, deleteTaskSeriesReset]);

    useEffect(() => {
        if ((updateTaskSeriesSuccess || deleteTaskSeriesSuccess) && loading) {
            setLoading(false)
            handleCloseModal()
            fetchTaskSeries(mission_uuid, weekStart, weekEnd)
        }
    }, [
        loading,
        handleCloseModal,
        mission_uuid, 
        weekStart, 
        weekEnd,
        fetchTaskSeries,
        updateTaskSeriesSuccess, 
        updateTaskSeriesReset,
        deleteTaskSeriesSuccess
    ])

    const normalizedError = normalizedMessage(deleteTaskSeriesError || updateTaskSeriesError)
    
    return(<Modal open={isOpen} title={<Title>{title}</Title>} width='600px' handleClose={handleCloseModal} error={normalizedError} closable={false}>
        <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdate}
        >
        {({ handleChange, handleBlur, values, setFieldValue }) => (
            <Form style={{height: '100%', maxWidth: 650}}>
                <Subtitle>Adjust the frequency and preferences for this task</Subtitle>
                <WeekRepeatContainer>
                    <label>Repeat every</label>
                    <WeekRepeat
                        name="weekRepeat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.weekRepeat}
                    >
                        {
                            [{name: '1 Week', value: '1week'}, 
                                {name: '2 Weeks', value: '2week'},
                                {name: '3 Weeks', value: '3week'},
                                {name: '4 Weeks', value: '4week'}].map((item, index)=> (
                                <option value={item.value} key={index}>
                                    {item.name}
                                </option>
                            ))
                        }
                    </WeekRepeat>
                    <label>on</label>
                </WeekRepeatContainer>
                <WeekdayContainer>
                    {
                        ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((item, index) => (
                            <WeekdayCheckbox
                                htmlFor={"weekdays["+index+"]"}
                                key={index}
                                isChecked={values.weekdays[index]}
                            >
                            <span>{item}</span>
                            <input type="checkbox" 
                                id={"weekdays["+index+"]"}
                                key={index}
                                name={"weekdays["+index+"]"}
                                readOnly
                                checked={values.weekdays[index]}
                                onChange={handleChange}
                                onBlur={handleBlur}/>
                            
                            </WeekdayCheckbox>
                        ))
                    }
                </WeekdayContainer>
                <DateTimeContainer>
                    <DateWrapper>
                        <label htmlFor="startDate">Start date</label>
                        <DatePicker required type="date" name="startDate" value={values.startDate} setFieldValue={setFieldValue} onBlur={handleBlur}/>
                    </DateWrapper>
                    <DateWrapper>
                        <label htmlFor="endDate">End date</label>
                        <DatePicker required type="date" name="endDate" value={values.endDate} setFieldValue={setFieldValue} onBlur={handleBlur}/>
                    </DateWrapper>
                </DateTimeContainer>

                <PreferenceContainer>
                <label style={{marginBottom: '10px'}}>Preferences</label>
                {title === "Childcare" ? null : 
                 <p className="preferenceText" style={{marginTop: '0px'}}> Do not include addresses or other personally identifiable information.</p>
                }
                {
                    title === "Childcare" ?
                    <ChildcarePreferenceContainer style={{marginLeft: '0px', paddingTop: '0px'}}>
                        <p className="preferenceText" style={{paddingTop: '0px'}}>
                        Volunteers will see:
                        </p>
                        <p className="preferenceText" style={{fontStyle:'italic', paddingTop: '1px'}}>
                        Please contact the family for information about childcare needs.
                        </p>
                    </ChildcarePreferenceContainer>

                    :
                    <textarea
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                    name="preferences" 
                    value={values.preferences}
                    />
                }       

                </PreferenceContainer>
                
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator active={loading}>
                    <ButtonContainer>
                            <CancelButton type="button" onClick={() => setOpen(false)}>
                                Cancel
                            </CancelButton>
                            <SaveButton type="submit">
                                Save
                            </SaveButton>
                    </ButtonContainer>
                    <ButtonContainer>
                        <RemoveTaskButton onClick={handleDelete}>
                            Remove this task
                        </RemoveTaskButton>
                    </ButtonContainer>
                </ActivityIndicator>
                </div>
            </Form>
        )}
        </Formik>
        </Modal>)
}

const mapStateToProps = (state) => {
    const village = state.VillageReducer
    return {
        weekStart: village.weekStart,
        weekEnd: village.weekEnd,
        updateTaskSeriesError: village.updateTaskSeriesError,
        updateTaskSeriesSuccess: village.updateTaskSeriesSuccess,
        deleteTaskSeriesError: village.deleteTaskSeriesError,
        deleteTaskSeriesSuccess: village.deleteTaskSeriesSuccess
    }
}

const mapDispatchToProps = {
    fetchTaskSeries,
    updateTaskSeries, 
    updateTaskSeriesReset,
    deleteTaskSeries,
    deleteTaskSeriesReset,
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal)