import React from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
    box-sizing: content-box;
    width: 100%;
    height: 90px;
    border-top: 0px solid white;;
    border-bottom: 1px solid #a694c7;
    border-right: ${props => props.isChecked? "3px solid white" : "0px solid white"}
    background-size: cover;
    box-shadow: inset 0px 0px 0px rgba(0,0,0,0);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    background-color: ${props => props.isChecked ? "white" : "#EBE9F4"};
    position: relative;
    box-shadow: ${props => props.isChecked? "none" : "inset -3px 0px 6px -6px #000000bd"};

    :focus {
        background-color: #ffffff;
        border-right: none;
    }

    :hover {
        cursor: pointer;
    }


    & h5 {
        flex: 2 2 30px;
        text-align: left;
        margin-left: 24px;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 66px;
        padding-left: 10px;
        padding-right:10px;

        border-bottom:  ${props => props.isChecked ? "1px solid #ffffff" : "1px solid #a694c7"};
        border-right:  ${props => props.isChecked && props.rightEdge ? 'none' : '1px solid #a694c7'};
        border-left: ${props => !props.isChecked && props.leftEdge ? '1px solid #a694c7' : 'none'};

        & > h5 {
            flex: none;
            display: flex;
            margin: 5px 0;
            text-align: center;
            align-self: center;
            font-size: 16px;
            font-weight: normal;
        }

    }
`

function InvitationTabComponent(props){

    return(
        <TabContainer isChecked={props.isChecked} htmlFor={props.title} onClick={props.onClick} leftEdge={props.edge === 'left'} rightEdge={props.edge === 'right'}>
            <h5>{props.title}</h5>
        </TabContainer>
    )
}



export default InvitationTabComponent;