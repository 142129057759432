import React, { useState, useEffect } from 'react';
import './DatePicker.css';
import {
  getDaysInMonth,
  setDate,
  getDate,
  getDay,
  isEqual,
  startOfMonth,
  endOfMonth,
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  subDays,
  addDays,
  subWeeks,
  addWeeks,
  parseISO
} from 'date-fns';
import { chunk } from 'lodash';
import prevIcon from './previous.svg';
import nextIcon from './next.svg';
import InlineTooltip from '../general/InlineTooltip';

const Calendar = ({
  date,
  handleSelectDate,
  closeCalendar,
}) => {
  const dateString = date || format(new Date(), "yyyy-MM-dd")
  const dateValue = parseISO(dateString)
  const [selectedDate, setSelectedDate] = useState(dateValue)
  
  const setPreviousYear = () => {
    const previousYear = subYears(selectedDate, 1);
    setSelectedDate(startOfMonth(previousYear));
  }

  const setNextYear = () => {
    const nextYear = addYears(selectedDate, 1);
    setSelectedDate(startOfMonth(nextYear));
  }

  const setPreviousMonth = () => {
    const previousMonth = subMonths(selectedDate, 1);
    setSelectedDate(startOfMonth(previousMonth));
  }

  const setNextMonth = () => {
    const nextMonth = addMonths(selectedDate, 1);
    setSelectedDate(startOfMonth(nextMonth));
  }

  const setPreviousWeek = () => {
    const previousWeek = subWeeks(selectedDate, 1);
    setSelectedDate(previousWeek);
  }

  const setNextWeek = () => {
    const nextWeek = addWeeks(selectedDate, 1);
    setSelectedDate(nextWeek);
  }

  const setPreviousDay = () => {
    const previousDay = subDays(selectedDate, 1);
    setSelectedDate(previousDay);
  }

  const setNextDay = () => {
    const nextDay = addDays(selectedDate, 1);
    setSelectedDate(nextDay);
  }


  const setDatePreviousMonth = () => {
    setSelectedDate(subMonths(selectedDate, 1));
  }

  const setDateNextMonth = () => {
    setSelectedDate(addMonths(selectedDate, 1));
  }

  const setDatePreviousYear = () => {
    setSelectedDate(subYears(selectedDate, 1));
  }

  const setDateNextYear = () => {
    setSelectedDate(addYears(selectedDate, 1));
  }

  const setMonthStart = () => {
    setSelectedDate(startOfMonth(selectedDate));
  }

  const setMonthEnd = () => {
    setSelectedDate(endOfMonth(selectedDate));
  }

  const generateMonth = () => {
    const daysInMonth = getDaysInMonth(selectedDate);
    const startWeekday = getDay(startOfMonth(selectedDate));
    const endWeekday = getDay(endOfMonth(selectedDate));
    const gridDays = chunk([
      ...Array.from({ length: startWeekday }).fill(null),
      ...Array.from({ length: daysInMonth }, (_,i) => setDate(selectedDate, i+1)),
      ...Array.from({ length: (6-endWeekday) }).fill(null)
    ], 7);
    return gridDays;
  }

  const handleKeyPress = (e,cb) => {
    const charCode = e.charCode
    if(charCode === 13 || charCode === 32) {
      cb();
    }
  }

  const handleDateSelection = (date) => {
    const dateString = format(date, "yyyy-MM-dd");
    handleSelectDate(dateString);
  }

  return (<div className="calendar">
    <div className="title">
    <div 
      onClick={setPreviousMonth}
      role="button"
      aria-label="Previous month"
      id="prev-icon"
    >
      <img src={prevIcon} alt="prev"/>
    </div>
    <div className="month" role="heading">
      <b>
        {format(selectedDate, "MMMM yyyy")}
      </b>
    </div>
    <div 
      onClick={setNextMonth}
      role="button"
      aria-label="Next month"
      id="next-icon"
    >
      <img src={nextIcon} alt="next"/>
    </div>
    </div>
    <table
      id="grid"
      role="grid"
      aria-label="Month"
    >
      <thead>
        <tr role="row">
          <th className="header" role="columnheader" aria-label="Sunday"><abbr title="Sunday">Sun</abbr></th>
          <th className="header" role="columnheader" aria-label="Monday"><abbr title="Monday">Mon</abbr></th>
          <th className="header" role="columnheader" aria-label="Tuesday"><abbr title="Tuesday">Tue</abbr></th>
          <th className="header" role="columnheader" aria-label="Wednesday"><abbr title="Wednesday">Wed</abbr></th>
          <th className="header" role="columnheader" aria-label="Thursday"><abbr title="Thursday">Thu</abbr></th>
          <th className="header" role="columnheader" aria-label="Friday"><abbr title="Friday">Fri</abbr></th>
          <th className="header" role="columnheader" aria-label="Saturday"><abbr title="Saturday">Sat</abbr></th>
        </tr>
      </thead>
      <tbody>
        {generateMonth().map((week,i) => (
          <tr className="week" key={`week-${i}`} role="row">
            { week.map((day,i) => (
              day
                ?
                <td
                  className={`cell${isEqual(selectedDate, day) ? ' active' : ''}`}
                  key={`day-cell-${i}`}
                  onClick={() => handleDateSelection(day)}
                  role="gridcell"
                  aria-selected={isEqual(selectedDate, day)}
                >
                  {getDate(day)}
                </td>
                :
                <td className="empty" key={`day-cell-${i}`}>&nbsp;</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>)}

const Index = ({
  name,
  onBlur,
  value,
  error,
  touched,
  setFieldValue = () => {}
}) => {
  const [date, setDate] = useState(value);
  const [showCalendar, setShowCalendar] = useState(false)

  const toggleCalendar = (e) => {
    setShowCalendar(!showCalendar);
  } 

  const handleSelectDate = (date) => {
    if (date && date.target) {
      setDate(date.target.value)
      setFieldValue(name, date.target.value)
    } else {
      setDate(date)
      setFieldValue(name, date)
    }
    setShowCalendar(false);
  }

  const closeCalendar = () => {
    setShowCalendar(false);
  }

  return (
    <div id="dp">
      <input 
        type="date" 
        value={date} 
        onChange={handleSelectDate}
        onBlur={onBlur}
        name={name}
      />
      <div id="calendar-icon" onClick={toggleCalendar}></div>
      <div className="date-picker-error">
        <InlineTooltip hidden={!(error && touched)}>{error}</InlineTooltip>
      </div>
      {showCalendar && (
        <Calendar
          date={date}
          handleSelectDate={handleSelectDate}
          closeCalendar={closeCalendar}
        />
      )}
    </div>
  )

}

export default Index;