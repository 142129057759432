export const categories = {
    care: "care",
    cleaning: "cleaning",
    emotion: "emotion",
    food: "food",
    other: "other",
}

export const titles = {
    childcare: "Childcare",
    petcare: "Petcare",
    eldercare: "Eldercare",
    meals: "Meals",
    groceries: "Groceries",
    activity: "Activity",
    checkin: "Check In",
    companionship: "Companionship",
    financeCoaching: "Finance Coaching",
    laundry: "Laundry",
    tidyingUp: "Tidying Up",
    dishes: "Dishes",
    bathrooms: "Bathrooms",
    lawncare: "Lawn Care",
    other: "Other",
}

export const icons = {
    eldercare: "https://village-co-callback.s3.amazonaws.com/images/eldercare.svg",
    petcare: "https://village-co-callback.s3.amazonaws.com/images/petcare.svg",
    bathrooms: "https://village-co-callback.s3.amazonaws.com/images/bathrooms.svg",
    dishes: "https://village-co-callback.s3.amazonaws.com/images/dishes.svg",
    laundry: "https://village-co-callback.s3.amazonaws.com/images/laundry.svg",
    tidyingUp: "https://village-co-callback.s3.amazonaws.com/images/tidying.svg",
    checkIn: "https://village-co-callback.s3.amazonaws.com/images/checkin.svg",
    financeCoaching: "https://village-co-callback.s3.amazonaws.com/images/financial.svg",
    meals: "https://village-co-callback.s3.amazonaws.com/images/meals.svg",
    lawnCare: "https://village-co-callback.s3.amazonaws.com/images/lawncare.svg",
    activity: "https://village-co-callback.s3.amazonaws.com/images/activity.svg",
    companionship: "https://village-co-callback.s3.amazonaws.com/images/companion.svg",
    groceries: "https://village-co-callback.s3.amazonaws.com/images/groceries.svg",
    childcare: "https://village-co-callback.s3.amazonaws.com/images/childcare.svg",
    other: "https://village-co-callback.s3.amazonaws.com/images/star.svg",
    otherWhite: "https://village-co-callback.s3.amazonaws.com/images/star-white.svg"
}
