import rp from 'request-promise';
import { getAuthAccessToken } from '../../shared-lib/auth-provider';

const getAuthHeader = () => {
    const accessToken = getAuthAccessToken();
    if (!accessToken) {
        return;
    }
    return `Bearer ${accessToken}`;
};

const getPermanentHeaders = () => {
    const result = {};
    const authHeader = getAuthHeader();
    if (authHeader) {
        result[`Authorization`] = authHeader;
    }
    return result;
};

export const makeRequest = async (request) => {
    if (!request) {
        throw new Error(`You have to pass valid request, but request = ${request} found`);
    }

    const initialHeaders = request.headers || {};
    const mergedHeaders = {
        ...initialHeaders,
        ...getPermanentHeaders()
    }

    const updatedRequest = {
        ...request,
        headers: mergedHeaders
    };

    return await rp(updatedRequest);
};

export default { makeRequest };