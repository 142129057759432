
import { makeRequest } from './base.api';
import env from '../../.env';
import moment from 'moment';
const urlPrefix = env.API_URL;




export const makeTask = (seriesId, title, category, recurringDays, recurringCadence) => {
  if (seriesId) {
    return {
      seriesId: seriesId,
      title: title,
      category: category,
      startDate: moment().toISOString(),
      endDate: moment().add(3, 'month').toISOString(),
      recurringDays: recurringDays,
      recurringCadence: recurringCadence
    }
  } else {
    return {
      title: title,
      category: category,
      startDate: moment().toISOString(),
      endDate: moment().add(3, 'month').toISOString(),
      recurringDays: recurringDays,
      recurringCadence: recurringCadence
    }
  }
}


export function defaultRecurringDays(title) {
  switch (title) {
      case 'Eldercare':
          return [1, 3, 5];
      case 'Childcare':
          return [1, 3, 5];
      case 'Activity':
          return [2];
      case 'Check In':
          return [1, 3, 5];
      case 'Companionship':
          return [1, 4];
      case 'Finance Coaching':
          return [1];
      case 'Meals':
          return [0, 1, 2, 3, 4, 5, 6];
      case 'Bathrooms':
          return [1];
      case 'Dishes':
          return [0, 2, 4, 6];
      case 'Lawn Care':
          return [1];
      case 'Tidying Up':
          return [0, 2, 4, 6];
      case 'Laundry':
          return [1];
      case 'Groceries':
          return [0];
      case 'Petcare':
          return [0, 1, 2, 3, 4, 5, 6];
      case 'PetcareCat':
          return [1, 3, 5];
      default:
          return [1, 3, 5];
  }
}

export function defaultRecurringPeriod(title) {
  switch (title) {
      case 'Finance Coaching':
          return 4;
      default:
          return 1;
  }
}
export default {
  defaultRecurringDays,
  defaultRecurringPeriod,
  makeTask,
};

