import React, {useMemo, useEffect} from 'react';
import styled from 'styled-components';
import openIcon from './assets/open-white.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import ChildcareIcon from './assets/childcare-white.svg';
import EldercareIcon from './assets/eldercare-white.svg'
import LaundryIcon from './assets/laundry-white.svg'
import DishesIcon from './assets/dishes-white.svg'
import PetIcon from './assets/petcare-white.svg'
import ActivityIcon from './assets/activity-white.svg'
import CheckinIcon from './assets/checking-white.svg'
import CompanionIcon from './assets/companionship-white.svg'
import FinancialIcon from './assets/finance-white.svg'
import BathroomsIcon from './assets/bathroom-white.svg'
import LawnIcon from './assets/lawncare-white.svg'
import TidyupIcon from './assets/tidy-white.svg'
import GroceriesIcon from './assets/groceries-white.svg'
import MealsIcon from './assets/meals-white.svg'
import { icons } from '../../shared-lib/utils/taskSeries';
import { useUser } from '../../auth/UserContext';
import useMediaQuery from '../../shared-lib/utils/useMediaQuery';
import PreferencesHolder from './TaskSeriesPreferencesHolder';

const cardHeight = 181;
const cardVertMargin = 15;

const PanelHeader = styled.div`
    max-width: 100%;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    background-color: #060368;
    background-size: cover;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;

    @media (max-width: 1024px) {
        width: 100%;
        border-radius: 0px;
        max-width: 100%;
    }
`

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: ${cardVertMargin}px;
`

const TaskCard = styled.div`
    max-width: 225px;
    flex: 1 1 33%;
    height: ${cardHeight}px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor:pointer;
    margin: 0px 10px;
`

const EmptyCard = styled.div`
    max-width: 225px;
    flex: 1 1 33%;
    height: ${cardHeight}px;
    margin: 0px 10px;
`

const TitleWrapper = styled.div`
    flex: 2 2 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const TaskCardTitle = styled.div`
    font-family: var(--font-mrEaves);
    font-size: 36px;
    color: #060368;
    font-weight: 400;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    
    @media (max-width: 1024px) {
        font-size: 26px;
    }
`

const TaskCardSubtitle = styled.div`
    font-style: italic;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #555555;
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    text-align: center;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
`

const TaskCardButton = styled.button`
    flex: 1 1 33%;
    width: 100%;
    max-height: 47px;
    min-height: 47px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #69469d;
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin: 0px;
    border: none;
    
    :hover {
        cursor: pointer;
    }

    :focus {
        outline: none;
    }
`

const PanelHeaderTitle = styled.div`
    height: 24px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none solid rgb(255, 255, 255);
    flex-grow: 1;
`

const PanelHeaderIcon = styled.img`
    margin-left: 20px;
    margin-right: 10px;
`

const SeeMoreButton = styled.label`
    color: #69469d;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    border-bottom-color: rgb(105, 70, 157);
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    text-align: center;
    display: ${props => props.hidden ? 'none' : 'default'};

    :hover {
        cursor: pointer;
    }
`

const CardTable = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        padding: 0 10px;
    }
`

const findIcon = (taskTitle) => {
    const iconList = [
        {titleName: "Childcare", icon: ChildcareIcon},
        {titleName: "Eldercare", icon: EldercareIcon},
        {titleName: "Petcare", icon: PetIcon},
        {titleName: "Bathrooms", icon: BathroomsIcon},
        {titleName: "Laundry", icon: LaundryIcon},
        {titleName: "Lawn care", icon: LawnIcon},
        {titleName: "Dishes", icon: DishesIcon},
        {titleName: "Tidying up", icon: TidyupIcon},
        {titleName: "Groceries", icon: GroceriesIcon},
        {titleName: "Meals", icon: MealsIcon},
        {titleName: "Activity", icon: ActivityIcon},
        {titleName: "Finance coaching", icon: FinancialIcon},
        {titleName: "Check in", icon: CheckinIcon},
        {titleName: "Companionship", icon: CompanionIcon}
    ]
    const iconItem = iconList.find(task => task.titleName.toLowerCase() === taskTitle.toLowerCase())
    if (iconItem) {
        return iconItem.icon
    } else {
        return icons.otherWhite;
    }  
}

function TaskPanel(props) {

    const [numRows, setNumRows] = React.useState(1);
    const { user } = useUser()
    const isSmall = useMediaQuery('(max-width: 420px)');
    const slotsPerRow = isSmall ? 2 : 3;

    const handleSeeMoreClick = () => {
        setNumRows(numRows + 1);
    }

    const availableSlots = useMemo(() => { 
        return props.taskSeries.timeslots.filter(ts => {
            const today = moment()
            if (moment(ts.date) > today && 
                ts.isEnabled && 
                ts.assignee === null) 
            {
                return true
            } else {
                return false
            }
        }).map((slot) => {
            const _date = moment(slot.date)
            return {
                title: _date.format('MMMM D').toString(),
                subtitle: _date.format('dddd').toString(),
                date: _date,
                seriesId: props.taskSeries.series_id,
                taskTitle: props.taskSeries.title
            }
        })

    }, [props.taskSeries]); 

    useEffect(() => {
        setNumRows(1);
    }, [props.taskSeries.title])


    const makeCardRow = (row) => {
        const start = row * slotsPerRow
        let targetSlots = availableSlots.slice(start, start + slotsPerRow);
        while (targetSlots.length < slotsPerRow) {
            targetSlots.push({empty:true})
        }
        return <CardWrapper key={row}>
            {
                targetSlots.map((item, index) => {

                    if (item.empty) {
                        return <EmptyCard key={index}/>
                    }

                    const booking = {
                        title: item.taskTitle,
                        date: item.date,
                        seriesId: item.seriesId,
                        userEmail: user && user.email,
                        name: user && user.name,
                        isFromTaskPage: true
                    }
                    return (
                        <TaskCard 
                            key={index}
                            id={`taskcard_${index}`}
                            style={{marginLeft: index === 0 ? '0px' : '10px', marginRight: index + 1 === slotsPerRow ? '0px' : '10px'}}
                            onClick={() => {
                                props.handleOpenCloseVolunteerModal(booking)
                            }
                        }>
                            <TitleWrapper>
                                <TaskCardSubtitle>{item.subtitle}</TaskCardSubtitle>
                                <TaskCardTitle>{item.title}</TaskCardTitle>
                            </TitleWrapper>
                            <TaskCardButton>Volunteer</TaskCardButton>
                        </TaskCard>
                    )
                })
            }
            </CardWrapper>
        
    }

    const cardRows = [];
     // in case the user expands to the end and then rotates device
    const maxRows = Math.floor(availableSlots.length / slotsPerRow + (availableSlots.length % slotsPerRow ? 1 : 0));
    let rows = Math.min(numRows, maxRows);

    const seeMoreHidden = numRows >= maxRows
    
    for(let i = 0; i < rows; i++) {
        cardRows.push(makeCardRow(i));
    }

    const isChildcare = props.taskSeries.title === 'Childcare'
    let preferencesText = isChildcare ? 'Please contact the family for information about childcare needs.': props.taskSeries.preferences


    if (availableSlots.length>0) { 
        return (
            <div className="task-panel-row">
                <PanelHeader>
                    <PanelHeaderIcon src={findIcon(props.taskSeries.title)} />
                    <PanelHeaderTitle>{props.taskSeries.title}</PanelHeaderTitle>
                    <PanelHeaderIcon src={openIcon} />
                </PanelHeader>
                <PreferencesHolder preferencesText={preferencesText}/>
                <CardTable>
                    {cardRows}
                    <SeeMoreButton onClick={handleSeeMoreClick} hidden={seeMoreHidden}>See more dates</SeeMoreButton>
                </CardTable>
            </div>
        ) 
    } else {
        return (
            <div></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, {  })(TaskPanel);