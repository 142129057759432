import styled from 'styled-components';

const Button = styled.button`
    width: auto;
    min-width: 150px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${props => props.disabled ? '#ffffff' :'#69469d'};
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: ${(props) => props.disabled ? '#767676' : '#ffffff'};
    font-weight: bold;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    border: ${props => props.disabled ? '1px solid #cccccc' :'none'};

    &:hover {
        cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
        background-color: ${(props) => props.disabled ? '#ffffff' : '#060368'};
    },

    &:active: {
        border: 2px solid #ffffff
    },
    &:focus : {
        outline:none
    },
`

export default Button;