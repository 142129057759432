import _ from 'lodash';
import * as validator from 'email-validator';

const EmailInputModel = ({state = {emails: [], pending: '', editingIndex: -1}}) => {
    let {emails, pending, editingIndex} = state;
    const valid = 'valid';
    const invalid = 'invalid';
    const editing = 'editing';

    const processEditingInput = (input) => {
        const newEmails = _.cloneDeep(emails).map((item) => {
            if (item.state === editing) {
                item.value = input;
            }
            return item;
        });
        const newState = {editingIndex, emails: newEmails, pending}
        return EmailInputModel({state: newState})
    }

    const validateEmail = (email) => {
        return validator.validate(email);
    }

    const _startEditing =  (index) => {
        let newEditingIndex = -1
        const newEmails = _.cloneDeep(emails).map((item, itemIndex) => {
            if (itemIndex === index) {
                item.state = editing;
                newEditingIndex = index;
            } else if (item.state === editing) {
                item.state = validateEmail(item.value) ? valid : invalid;
            }
            return item;
        }).filter(item => item.value);
        const newState = {editingIndex: newEditingIndex, emails: newEmails, pending}
        return EmailInputModel({state: newState})
    }

    const _getValid = () => {
        return emails.filter(email => email.state === valid).map(item => item.value);
    }

    return {
        getEmails: () => {
            return emails || [];
        },
        getValid: () => {
            return _getValid().map(e => e.toLowerCase());
        },
        getPending: () => {
            return pending || '';
        },

        nonEmptyAndAllValid: () => {
            const valid = _getValid();
            return valid.length > 0 && valid.length === emails.length;
        },

        processInput: (input) => {

            if (editingIndex >= 0) {
                return processEditingInput(input);
            }

            let newEmails = _.cloneDeep(emails)
            let newPending = input;
            let stringList = input.split(',');

            if (stringList.length > 1) {
                for (let i = 0; i < stringList.length; i++) {
                    let item = stringList[i].trim();
                    let isValid = validateEmail(item);
                    if (isValid) {
                        newEmails.push({value: item, state: valid});
                    } else if (i === stringList.length - 1) {
                        newPending = item;
                    } else {
                        newEmails.push({value: item, state: invalid})
                    }
                }
            }
            const newState = {emails: newEmails, pending: newPending, editingIndex}
            return EmailInputModel({state: newState})
        }, 

        handleDelete: (index) => {
            let newEmails = _.cloneDeep(emails)
            newEmails.splice(index, 1)
            const newState = {emails: newEmails, pending, editingIndex: editingIndex === index ? -1 : editingIndex}
            return EmailInputModel({state: newState})
        },

        handleSubmit: () => {
            if (editingIndex >= 0) {
                return _startEditing(-1);
            } else if(!pending){return EmailInputModel({state})}

            const newVal = pending.trim();
            emails.push({value:newVal, state: validateEmail(newVal) ? valid : invalid});
            state.pending = '';
            const newState = _.cloneDeep(state);
            return EmailInputModel({state: newState})
        }, 

        startEditing: (index) => {
            return _startEditing(index);
        },

        stopEditing: () => {
            return _startEditing(-1);
        }
    }
}

export default EmailInputModel;