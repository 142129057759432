import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TaskBar from '../../TaskBar';
import Button from '../../Share/Button';
import SkipThisStepButton from '../../Share/SkipThisStepButton'
import { fetchTaskSeries, getMission } from '../../../data/actions';
import { selTaskSeries } from '../../../data/selectors';
import UpdateModal from '../../Calendar/TaskSeries/UpdateModal';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 150px 150px;

  h1 {
    font-family: var(--font-mrEaves);
    font-size: 64px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin-bottom: 0px;
  }

  @media (max-width: 800px) {
    padding: 20px;
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 48px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 37px;
    }
  }

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
  }

  a {
    color: var(--color-savo-purple);
    font-size: 18px;
  }

`

export const Subtitle = styled.div`
    margin-bottom: 25px;
    font-size: 26px;
    font-style: italic;
    color: #555555;
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 32px;
    text-align: center;

    @media(max-width: 675px) {
        margin-top: 0px;
    }

    @media (max-width: 400px) {
      font-size: 20px;
    }

`

const Category = styled.div`
  font-family: var(--font-mrEaves);
  font-size: 36px;
  color: #060368;
  text-align: center;
  margin: 40px 10px 0;
  text-transform: capitalize;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`


const weekStart = moment().utc().startOf('week');
const weekEnd = moment().utc().endOf('week');
const parseCategories = (ts) => {
  const sorted = ts.sort((a, b) => (a.category > b.category ? 1 : -1)).sort((a, b) => (a.series_id > b.series_id ? 1 : -1));
  const parsed = sorted.reduce((myMap, task, index) => {
    task.index = index;
    if (myMap.has(task.category)) {
      const theList  = myMap.get(task.category).taskSeries;
      myMap.set(task.category, {
        name: task.category,
        taskSeries: [...theList, task]
      })
    } else {
      myMap.set(task.category, {
        name: task.category,
        taskSeries: [task]
      })
    }
    return myMap;
  }, new Map());
  return Array.from(parsed).map(i => i[1]);
}

const EditTaskStep = () => {

  const [edit, setEdit] = useState(null);
  const location = useLocation();
  const missionUuid = location.state && location.state.missionUuid;
  const taskSeries = useSelector(selTaskSeries);
  const categories = parseCategories(taskSeries);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (missionUuid) {
        dispatch(fetchTaskSeries(missionUuid, weekStart, weekEnd));
        dispatch(getMission(missionUuid));
    }
    // eslint-disable-next-line
  }, [fetchTaskSeries, getMission, missionUuid])

  return (
  <Container>
    <h1>We have a few recommendations</h1>
    <Subtitle>Follow these steps to include helpful details in your tasks.</Subtitle>
    <p>At any time, you can <a href="/mission/calendar">view the calendar</a></p>
    {edit !== null ?
      <UpdateModal 
        isOpen={edit !== null} 
        setOpen={() => setEdit(null)}
        title={edit.title}
        series_id={edit.series_id}
        start_date={edit.start_date}
        end_date={edit.end_date}
        preferences={edit.preferences}
        recurring_days={edit.recurring_days}
        recurring_cadence={edit.recurring_cadence}
        thirdPartyAllow={edit.third_party_allow}
      />
    : 
    null}
    {
      categories.map((cat, idx) => {
        return (
        <>
          <Category key={idx}>{cat.name}</Category>
          {cat.taskSeries.map((ts, i) => <TaskBar key={ts.series_id} ts={ts} idx={ts.index} setEdit={setEdit} />)}
        </>
        )
      })
    }
    <ButtonWrapper>
      <Button style={{padding: '0 18px'}} onClick={() => history.push('/wizard/invite-friends')}>Invite people to help</Button>
      <SkipThisStepButton onClick={() => history.push('/wizard/invite-friends')}>Skip this step</SkipThisStepButton>
    </ButtonWrapper>
  </Container>
  )
}

export default EditTaskStep;