import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { updateSettings, getSettings } from '../../data/actions'
import moment from 'moment';
import { useUser } from '../../auth/UserContext';
import { ActivityIndicator } from '../../shared-lib/components/general/ActivityIndicator';
import {StatusBanner} from '../../shared-lib/components/general/StatusBanner';
import {normalizedMessage} from '../../shared-lib/utils/error_normalizer';
import {FormField, getFamilyNameConfig} from '../../shared-lib/components/user_entry/FormField';
import _ from 'lodash'
import { useAnalytics } from '../../analytics/AnalyticsContext';
import { Status } from '../../data/api';
import ManageMembers from './ManageMembers';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
`

const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
}

const Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 52px;
    color: #69489d;
    margin: 3rem 0 1rem;
    text-align: center;
    @media(max-width: 400px) {
        font-size: 36px;
    }
`

const ButtonWrapper = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-grow: 1;
    justify-content: center;
`
const CancelButton= styled.button`
    border-width: 2px;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves}; 
    width: 140px;
    height: 47px;
    border-radius: 5px;
    background-size: cover;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    background-color: #ffffff;
    border: solid #69469d 2px;
    color: #69469d;
    margin: 0px 10px 30px 20px;
    cursor: pointer;
`
const SaveButton = styled.button`
    background-color: var(--color-savo-purple);
    color: white;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves};
    width: 140px;
    height: 47px;
    border-radius: 5px;
    background-size: cover;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    border: solid var(--color-savo-purple) 2px;
    margin: 0px 20px 30px 10px;
    cursor: pointer;
`

const NameWrapper = styled.div`
    min-width: 480px;

    @media (max-width: 500px) {
        min-width: 100%;
    }
`
const ManageMembersTitle = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 42px;
    color: #69489d;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
    @media(max-width: 400px) {
        font-size: 30px;
    }
`

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!')
    .max(50, 'TooLong!')
    .required('Required'),
    importantDateList: Yup.array(
        Yup.object().shape({
            date: Yup.string().required('Required'),
            significance: Yup.string().required('Required')
        })
    )
});

function normalizeSettings(settings) {
    if (settings !== null) {
        const { person_passed, important_date_list: importantDateList} = settings
        const nonNullDateList = importantDateList || [];
        const importantDateListNotNull = nonNullDateList.sort((item1, item2) => {return moment.utc(item1.date) - moment.utc(item2.date)}).map((item) => {
            return {
                ...item,
                date: moment.utc(item.date).format('YYYY-MM-DD')
            }
        });
    
        return {
            name: person_passed,
            importantDateList: importantDateListNotNull
        };
    }
    return {
        name: "",
        importantDateList: []
    };
}

function Settings(props) {

    const {settings, updateSettings, settingsError, settingsStatus, getSettings, updateMembersError} = props;
    const { membership} = useUser()

    const { track, pageview }  = useAnalytics()

    useEffect(() => {
        if (membership && membership.mission_uuid) {
            getSettings(membership.mission_uuid)
        }
    }, [])
    
    useEffect(() => {
        track("Page View", {"Page Name": "Settings"})
        pageview("/settings",[], "Settings")
        // eslint-disable-next-line
    }, [])
    
    const handleSubmit = (values) => {
        if (membership) {
            values["mission_uuid"] = membership.mission_uuid
            
            // track adding new dates
            const B = values.importantDateList
            const addedDates = B.filter(i => i.id === null)
            addedDates.map(i => {
                track("Add Date", {
                    "Date": moment(i.date).toISOString()
                })
            })

            updateSettings(values)
        }  
    }
    
  
    const formikConfig = {
        initialValues: normalizeSettings(settings),
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: validationSchema
    }

    const info = settingsStatus === Status.updated ? "Your settings have been updated." : null
    let errorMessage = settingsError  || updateMembersError;
    errorMessage =  errorMessage ? (normalizedMessage(errorMessage)) : null;

    return (
        <Container>
            <StatusBanner info={info} error={errorMessage}></StatusBanner>
            <Formik {...formikConfig}>
            {({values, errors, handleBlur, handleChange, touched, setFieldValue}) => (
            <Form style={formStyles}>
                <Title>Update the settings for the Village</Title>
                <NameWrapper>
                    <FormField config={getFamilyNameConfig({errors, values, handleBlur, handleChange, touched})}></FormField>
                </NameWrapper>
                <ButtonWrapper visible={touched && values.name && (values.name !== settings.person_passed)}>
                    <ActivityIndicator active={settingsStatus === Status.loading}>
                        <CancelButton type="reset">Cancel</CancelButton>
                        <SaveButton type="submit">Save</SaveButton>
                    </ActivityIndicator>
                </ButtonWrapper>
                <ManageMembersTitle>Manage members</ManageMembersTitle>
                <ManageMembers/>
            </Form>)}
            </Formik>
        </Container>
    )
} 

const mapStateToProps = (state) => {
    return {
        settings: state.VillageReducer.settings.data,
        settingsStatus: state.VillageReducer.settings.status,
        settingsError: state.VillageReducer.settings.error,
        updateMembersError: state.VillageReducer.invitesAndMembers.error
    }
} 
export default connect(mapStateToProps, { updateSettings, getSettings })(Settings);


