import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ExpandMoreIcon from '../../assets/open-icon-white.svg';
import TaskSeries from './TaskSeries/TaskSeries';
import DayModal from './TaskSeries/DayModal';
import PropTypes from 'prop-types';
import { 
    deleteBooking, 
    createBooking, 
    deleteBookingReset, 
    createBookingReset
} from '../../data/actions';
import { useUser } from '../../auth/UserContext';
import { Status } from '../../data/api';
import { useAnalytics } from '../../analytics/AnalyticsContext';
import { fetchRecentNotes } from '../../data/actions';
import moment from 'moment';


const predefinedGroups = [
    { category: 'care', iconUrl: "https://village-co-callback.s3.amazonaws.com/images/care-white.svg", taskSeriesList: [], title: 'Care'}, 
    { category: 'food', iconUrl: "https://village-co-callback.s3.amazonaws.com/images/food-white.svg", taskSeriesList: [], title: 'Food'}, 
    { category: 'emotion', iconUrl: "https://village-co-callback.s3.amazonaws.com/images/emotional-white.svg", taskSeriesList: [], title: 'Emotional support'}, 
    { category: 'cleaning', iconUrl: "https://village-co-callback.s3.amazonaws.com/images/cleaning-white.svg", taskSeriesList: [], title: 'Cleaning'},
    { category: 'other', iconUrl: "https://village-co-callback.s3.amazonaws.com/images/star-white.svg", taskSeriesList: [], title: 'Other'}
]

function CalendarWeek(props) {

    // Note: taskSeries are grouped together by categories
    const [taskSeriesGroups, setTaskSeriesGroups] = useState([])
    const [isDayModalOpen, setIsDayModalOpen] = useState(false)
    const [booking, setBooking] = useState({date: null, assignee: null, title: null})
    const {membership, user} = useUser()
    const mission_uuid = membership && membership.mission_uuid
    const user_uuid = user && user.user_uuid
    const { taskSeries } = props
    const {
        createBooking,
        deleteBooking,
        createBookingReset,
        deleteBookingReset,
        createBookingState,
        deleteBookingState, 
        fetchRecentNotes
    } = props

    const { mpTrackBooking} = useAnalytics()

    useEffect(() => {
        if (taskSeries.length > 0) {
            const groups = predefinedGroups.map(grp => {
                grp.taskSeriesList = taskSeries.filter(ts => ts.category === grp.category)
                grp.taskSeriesList.sort((a, b) => a.title.localeCompare(b.title))   
                return grp
            })            
            setTaskSeriesGroups(groups)
        }
    },[taskSeries])

    useEffect(() => {
        const createStatus = createBookingState.status
        const deleteStatus = deleteBookingState.status
        if (
            createStatus === Status.success ||
            deleteStatus === Status.success
        ) {
            createBookingReset()
            deleteBookingReset()
        }

        if (
            createStatus === Status.failure ||
            deleteStatus === Status.failure
        ) {
            const timer = setInterval(() => {
                createBookingReset()
                deleteBookingReset()
            }, 3000)
            return () => {
                return clearInterval(timer)
            }
        }
    },[createBookingState, deleteBookingReset])

    const showDayModal = (timeslot, seriesId) => {
        const ts = taskSeries.filter(i => i.series_id === seriesId)
        const title = ts[0] && ts[0].title
        setBooking({
            assignee: timeslot.assignee ,
            date: timeslot.date,
            title: title,
            exceptionId: timeslot.exception_id,
            series_id: seriesId,
            booking_id: timeslot.booking_id
        })
        if (timeslot.date && seriesId) {
            const dateString = moment(timeslot.date).utc().format('YYYY-M-D').toString()
            fetchRecentNotes(dateString, seriesId);
        }
        setIsDayModalOpen(true)
    }

    const handleCloseDayModal = () => {
        setIsDayModalOpen(false)
        createBookingReset()
        deleteBookingReset()
    }

    const handleDeleteBooking = () => {
        const data = {
            booking:booking,
            mission_uuid: mission_uuid,
            user_uuid: user_uuid,
            isFromTaskPage: false,
        }
        mpTrackBooking(data, "delete")
        deleteBooking(data)
    }

    const handleCreateBooking = (note) => {
        const data = {
            date: booking.date,
            user_uuid: user_uuid,
            series_id: booking.series_id,
            mission_uuid: mission_uuid,
            isFromTaskPage: false,
            note: note,
        }
        mpTrackBooking({title: booking.title}, "create")
        createBooking(data)
    }

    return (
        <div>
            {taskSeriesGroups.map((group, index) => {
                return (
                    <div key={index}>
                        { group.taskSeriesList.length > 0 ? (
                            <div key={index} className="hui-expansion-panel">
                                <details open={true}>
                                    <summary>
                                        <div className="summary-wrapper">
                                        <div className="title">
                                            <img src={group.iconUrl} alt="task-icon"/>
                                            <h6>{group.title}</h6>
                                        </div>
                                        <img src={ExpandMoreIcon} alt="expand-icon"/>
                                        </div>
                                    </summary>
                                    <div className="content">
                                        {
                                            group.taskSeriesList.map((ts, idx) => {
                                                return <TaskSeries 
                                                    key={idx}
                                                    taskSeries={ts}
                                                    showDayModal={showDayModal}
                                                    isDayModalOpen={isDayModalOpen}
                                                    isOddRow={idx % 2 === 0 ? true : false}
                                                />
                                            })   
                                        }
                                    </div>
                                </details>
                            </div>) : (<div/>)
                        }
                    </div>
                )
            })}
            {isDayModalOpen ?
                <DayModal 
                    isOpen={isDayModalOpen}
                    booking={booking}
                    handleClose={handleCloseDayModal}
                    handleCreateBooking={handleCreateBooking} 
                    handleDeleteBooking={handleDeleteBooking}
                    createBookingState={createBookingState}
                    deleteBookingState={deleteBookingState}
                /> 
                :
                null
            }
        </div>
    )
}

CalendarWeek.propTypes = {
    taskSeries: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = ({VillageReducer}) => ({
    createBookingState: VillageReducer.createBookingState,
    deleteBookingState: VillageReducer.deleteBookingState,
})

const mapDispatchToProps = {
    createBooking,
    deleteBooking,
    createBookingReset,
    deleteBookingReset,
    fetchRecentNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarWeek);