import styled from 'styled-components';

const SkipThisStepButton = styled.button`
    padding: 5px 0px 0px 0px;
    background-size: cover;
    background-color: #FFFFFF;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #69469d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 70, 157);
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-color: rgb(105, 70, 157);
    border-bottom-style: dotted;
    border-top: none;
    border-right: none;
    border-left: none;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover {
        cursor: pointer;
    }
`

export default SkipThisStepButton