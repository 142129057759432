import React, {useRef} from 'react';
import styled from 'styled-components';
import CustomIconButton from '../../../shared-lib/components/CustomIconButton';
import _ from 'lodash';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    border: ${props => "1px solid " + props.border};
    background-color: ${props => props.backgroundColor};
    height:30px;
    width: auto;
    margin-right:1px;
    margin-bottom: 2.5px;
    margin-top: 2.5px;
`
const Text = styled.label`
    font-family: var(--font-mrEaves);
    font-size: 16px;
    width: auto;
    height: auto;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: ${props => props.invalid ? 'var(--color-error-text)' : '#111111'};
    background: transparent;
    margin-top: 2px;
    margin-bottom: 2px;
    border: none;
    border-radius: 5px;
    align-self: center;

    :hover {
        cursor: pointer;
    }
`

const TextInput = styled.input`
    font-family: var(--font-mrEaves);
    font-size: 16px;
    width: auto;
    color:  #111111;
    align-self: center;
    margin-right: 5px;
    padding: 5px 10px;
    outline: none;
    background: transparent;
    margin-top: 2px;
    margin-bottom: 2px;
    max-height: 25px;
    border: none;
    border-radius: 5px;
`
const ButtonWrapper = styled.div`
    margin-right: 5px;
    align-self: center;
    z-index: 2000;
`

const EmailChip =  ({email, handleDelete, onChange, handleSubmit, handleClick}) => {

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
           handleSubmit();
        } else {
            console.debug('unhandled key up')
        }
    }

    const ref = useRef();

    const onClick = (e) => {
        e.stopPropagation();
        const refInChildren =_.find(e.target.children, (child)=>{return child === ref.current});
        if (e.target === ref.current || refInChildren) {
            console.debug('handle click in chip')
            handleClick();
        } 
    }

    const deleteClick = (e) => {
        e.stopPropagation();
        handleDelete();
    }

    const handleMouseDown = (e) => {
        const refInChildren =_.find(e.target.children, (child)=>{return child === ref.current});
        if (e.target === ref.current || refInChildren) {
            e.stopPropagation();
        } 
    }

    const buttonDimens = {
        iconHeight: '15px',
        iconWidth: '15px',
        height: '21px',
        width:'21px'

    }

    const invalid = email.state === 'invalid'
    const editing = email.state === 'editing'

    const colors = {
        iconFill: invalid ? 'var(--color-error-text)' : 'var(--color-savo-purple)',
        hoverFill: 'transparent',
        iconHoverFill: invalid ? 'var(--color-hover-error)' : 'var(--color-dark-purple)',
        borderColor: 'transparent',
        background: 'transparent',
        borderHover: 'transparent',
        disabledBorder: '#ffffff'
    }

    const containerBackgrounColor = invalid ? 'var(--color-error-background)' : '#ffffff';
    let containerBorder = invalid ? "var(--color-error)" : editing ? "#cccccc" : 'var(--color-savo-purple)';

    return editing ?
     (
        <Container backgroundColor={containerBackgrounColor} border={containerBorder}>
            <TextInput value={email.value} onChange={onChange} onKeyUp={handleKeyUp} onBlur={handleSubmit} autoFocus={true} />
            <ButtonWrapper><CustomIconButton icon={'close'} dimens={buttonDimens} disabled={true} colors={colors}/></ButtonWrapper>
        </Container>
    )
    :
    (
        <Container backgroundColor={containerBackgrounColor} border={containerBorder} onClick={onClick} onMouseDown={handleMouseDown}>
            <Text invalid={invalid} ref={ref}>{email.value}</Text>
            <ButtonWrapper><CustomIconButton onClick={deleteClick} icon={'close'} dimens={buttonDimens} colors={colors}/></ButtonWrapper>
        </Container>
    )

}

export default EmailChip;