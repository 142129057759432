import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';

const NotesContainer = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    padding: 5px 5px;
    border-top: none;
`

const NoteWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-top: none;
    flex-wrap: nowrap;
    align-items: ${props => props.expanded ? 'flex-end' : 'center'};
    padding 0px 5px 0px 0px;
`

const ShowMoreButton = styled.div`
    display: ${props => props.hidden ? 'none' : 'default'}
    color: blue;
    //padding: 1px 2px;
    //background-color: #eee;
    font-size: 12px;
    //border: 1px solid #111;
    //border-radius: 5px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
`

const NotesString = styled.p`
    white-space: ${props => props.expanded ? 'wrap' : 'nowrap'};
    overflow: hidden;
    margin: 5px;
    margin-right: 0px;
    display: block;
    text-overflow: ellipsis;
`

const NotesHolder = ({notes}) => {

    const ref1 = useRef()
    const ref2 = useRef()

    const [needsWrap, setNeedsWrap] = useState(false);
    const [expanded, setExpanded] = useState(false);
    

    useEffect(() => {
        if (ref1 && ref2 && notes) {
            const p = ref2.current
            const cp = ref1.current
            if(p && cp) {
                setNeedsWrap(p.scrollWidth > cp.scrollWidth);
            } 
           
        } 
    }, [ref1.current, ref2.current, notes])

    console.log('needsRap', needsWrap);

    return (
        <NotesContainer ref={ref1} className={'notesHolder'} hidden={!notes}>
            <NoteWrapper expanded={expanded}><NotesString ref={ref2} expanded={expanded}>{notes}</NotesString><ShowMoreButton hidden={!needsWrap} onClick={()=>{setExpanded(!expanded)}}>{expanded ? 'less' : 'more'}</ShowMoreButton> </NoteWrapper>
        </NotesContainer>
    )
}

export default NotesHolder;