import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 22px;
    flex-grow: 1;
`

const Title = styled.div`
    min-height: 21px;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #69469d;
    font-weight: 400;
    text-align: center;
`

const Subtitle = styled.div`
    min-height: 60px;
    font-family: var(--font-mrEaves);
    font-size: 52px;
    color: #060368;
    font-weight: 400;
    text-align: center;
`


const TitleText = ({person_passed = ""}) => {
    return (
        <TitleWrapper>
            <Title>Welcome to the village for the</Title>
            <Subtitle>{person_passed} Family</Subtitle>
        </TitleWrapper>
    )
}

export default TitleText;