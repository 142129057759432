import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {normalizedMessage} from '../../../shared-lib/utils/error_normalizer';
import Modal from '../../../shared-lib/components/general/Modal'
import { Status } from '../../../data/api';
import NoteList from '../../NoteList';
import { connect } from 'react-redux';
import { ActivityIndicator } from '../../../shared-lib/components/general/ActivityIndicator';

const Title = styled.div`
    font-weight: bold;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 42px;
    color: #060368;
    text-align: center;
`
const SubTitle = styled.div`
    font-family: ${props => props.theme.mrsEaves}; 
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    font-style: italic;
    line-height: 26px;
    text-align: center;
`

const MainText = styled.div`
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 25px;
    color: #060368;
    font-weight: 400;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
`
const Button = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    height: 47px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 21px;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 25px auto;
    margin-bottom: 25px
    font-weight: bold;
    width: fit-content;
    &:hover {
        cursor: pointer;
        background-color: #060368;
    }
    min-width: 140px;
`
const Button2 = styled.div`
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 21px;
    color: #69469d;
    font-weight: 400;
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-color: rgb(105, 70, 157);
    border-bottom-style: dotted;
    width: fit-content;
    margin: auto;
    &:hover {
        cursor: pointer;
        color:  #060368;
    },
`

const NoAssigneeWrapper = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    textarea {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        resize: none;
    }

    label {
        color: #060368
        font-size: 18px;
        font-style: italic;
        font-family: var(--font-mrsEaves);
        margin-bottom: 10px;
        width: 100%;
    }
`

const NoAssigneeContent = ({
    handleNoteChange, 
    handleCreateBooking, 
    handleDeleteBooking,
    createBookingState, 
    note,
    isChildcare,
    recentNotes,
}) => {

    const [decideVolunteer, setDecideVolunteer] = useState(isChildcare);  // volunteer immediately if this is childcare since no notes permitted

    return (
    <NoAssigneeWrapper>
        {
            decideVolunteer ?
            <Fragment>
                {isChildcare ? null : 
                <div style={{width: '100%'}}>
                <label>Notes for the family</label>
                <textarea style={{width: '100%', marginTop: '5px'}} name="note" onChange={handleNoteChange}/>
                <NoteList recentNotes={recentNotes}/>
                </div>
                }

                <ActivityIndicator active={createBookingState.status === Status.loading}>
                <Button onClick={() => handleCreateBooking(note)}>
                    Volunteer
                </Button>
                </ActivityIndicator>
            </Fragment>
            :
            <Button onClick={() => setDecideVolunteer(true)}>
                Volunteer for task
            </Button>
        }

        <Button2 onClick={handleDeleteBooking}>
        Remove task from calendar
        </Button2>
    </NoAssigneeWrapper>)
}

function DayModal ({
    isOpen,
    booking,
    handleClose,
    handleCreateBooking,
    handleDeleteBooking,
    deleteBookingState,
    createBookingState,
    fetchRecentNotesState
}) {

    const [note, setNote] = useState()
    const recentNotes = fetchRecentNotesState.response || []

    const handleNoteChange = (e) => {
        const val = e.target.value
        setNote(val);
    }

    useEffect(()=> {
        if(
            deleteBookingState.status === Status.success || 
            createBookingState.status === Status.success
        ) {
            handleClose();
        }
    }, [
        deleteBookingState, 
        createBookingState,
        handleClose
    ])


    const normalizedError = normalizedMessage(deleteBookingState.error || createBookingState.error);

    return(
    <Modal open={isOpen} handleClose={handleClose} title={<Title>{booking.title}</Title>} error={normalizedError} width="650px">
        <SubTitle>
            {moment(booking.date).utc().format('MMMM Do, YYYY').toString()}
        </SubTitle>
        { 
            booking.assignee ? 
            <MainText>
                {booking.assignee} has volunteered for this task
            </MainText> 
            :
            <MainText>
                No one has volunteered for this task yet
            </MainText>
        }
        { booking.assignee == null &&
            <NoAssigneeContent
                note={note}
                handleNoteChange={handleNoteChange}
                handleCreateBooking={handleCreateBooking}
                handleDeleteBooking={handleDeleteBooking}
                recentNotes={recentNotes}
                isChildcare={booking.title === 'Childcare'}
                createBookingState={createBookingState}
            />
        }

        { booking.assignee !== null &&
            <div>
                <Button onClick={handleClose}>
                    Okay
                </Button>
                <Button2 onClick={handleDeleteBooking}>
                Remove this task and send a note to {booking.assignee}
                </Button2>
            </div>
        }

     
      </Modal>)
}

const mapStateToProps = (state) => {
    const { fetchRecentNotesState } = state.VillageReducer
    return {
        fetchRecentNotesState
    }
}
export default connect(mapStateToProps)(DayModal);