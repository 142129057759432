import React from 'react';
import styled from 'styled-components';


const PreferenceText = styled.label`
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #111111;
`

const PreferencesWrapper = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: row;
    align-self: flex-start;
    padding: 16px 15px 10px 0px;
    
    @media(max-width: 1024px) {
        padding: 16px 15px 10px 15px;
    }
`

const PreferencesList = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
`


const PreferencesHolder = ({preferencesText}) => {

    const hidden = !preferencesText || preferencesText === 'preferences n/a';
    const lines = hidden ? [] : preferencesText.split('\n').filter(l => l);

    return (

        <PreferencesWrapper hidden={hidden}>
            <PreferenceText style={{fontWeight: 'bold'}}>Preferences:</PreferenceText>
            <PreferencesList>
                {lines.map((line,index) => {
                    return <PreferenceText style={{marginLeft: '28px', marginTop: index === 0 ? '0px':'5px'}} key={index}>{line}</PreferenceText>
                })}
            </PreferencesList>
        </PreferencesWrapper>
    )
}

export default PreferencesHolder;