import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  getLocalItem,
  AUTH_SESSION,
  IS_AUTHENTICATED
} from '../utils/localStorage'
import AuthHandler from './AuthHandler';

export const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

const localAuthSesson = getLocalItem(AUTH_SESSION)
const localIsAuthenticated = getLocalItem(IS_AUTHENTICATED)

export const Auth0Provider = ({ children, config, connection }) => {

  const handler = new AuthHandler(config, connection)
  const [authSession, setAuthSession] = useState(localAuthSesson);
  const [isAuthenticated, setIsAuthenticated] = useState(localIsAuthenticated || false);

  // prevent login redirect during restoring from local
  const [isRestoring, setIsRestoring] = useState(true); 

  const persistAuthData = (authData) => {
    handler.persistAuthData(authData);
    setAuthSession(authData.authSession);
    setIsAuthenticated(authData.isAuthenticated);
  };

  const clearAuthData = () => {
    handler.clearStoredAuthData();
    setAuthSession(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const restoreSession = async () => {
      const localSession = handler.getSessionFromLocalStorage();

      if (localSession && localSession.isAuthenticated) {
        try {
          const newTokens = await handler.refreshSession();
          const authData = await handler.setupAuthSession(newTokens);
          persistAuthData(authData);
        } catch (error) {
          clearAuthData();
        }
      }
      setIsRestoring(false)
    };
    restoreSession();
  }, []);

  useEffect(() => {
    const handleCallback = async () => {
      if (window.location.hash.includes('access_token=')) {
        try {
          const newTokens = await handler.handleParseHash();
          const authData = await handler.setupAuthSession(newTokens);
          persistAuthData(authData);
        } catch (error) {
          clearAuthData();
        }
      }
    };
    handleCallback();
  }, []);

  const handleNewUserSignup = async (email, password, shouldDelayStateUpdate = false) => {
    const authData  = await handler.auth0SignupAndSignin(email, password);
    const { authSession, isAuthenticated } = authData || {};

    handler.persistAuthData(authData);
    
    const delayedAuthStateUpdateAction = shouldDelayStateUpdate === true ? () => {
      setIsAuthenticated(isAuthenticated);
      setAuthSession(authSession);
    } : () => {};

    if (shouldDelayStateUpdate === false) {
      delayedAuthStateUpdateAction();
    }
  
    return {
      authData: authData,
      delayedAuthStateUpdateAction: delayedAuthStateUpdateAction
    }
  };

  const handleDeeplink = async (state) => {
    try {
      const newTokens = await handler.silentAuthenticate(state)
      const authData = await handler.setupAuthSession(newTokens);
      persistAuthData(authData);
    } catch (error) {
      clearAuthData();
    }
  }

  return (
    <Auth0Context.Provider value={{
      authSession,
      isAuthenticated,
      isRestoring,
      clearAuthData,
      login: (...p) => handler.login(...p),
      handleDeeplink: (...p) => handleDeeplink(...p),
      signup: (...p) => handler.signup(...p),
      loginWithCredential: (...p) => handler.loginWithCredential(...p),
      logout: (p) => handler.logout(p),
      auth0SignupAndSignin: (...p) => handleNewUserSignup(...p),
    }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
