import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    width: 100%;
    height: 75px;
    border: 2px solid #a694c7;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    
    :hover {
        cursor: pointer;
        background-color: var(--color-light-purple);
    }
`

const IconWrapper = styled.div `
    display: flex;
    flex-direction: cloumn;
    justify-content: center;
    align-items:center;
    width: 25%;
    max-width: 60px;
`

const LeftIcon = styled.img`
    margin-left: 10px;
`
const Title = styled.div`
    margin-left: 5px;
    width:75%;
    height: 29px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 26px;
    color: #69489d;
    font-weight: bold;
    text-align: left;
    text-decoration: none solid rgb(105, 72, 157);
`

function SimpleCard(props) {
    return(
        <Container onClick={props.onClick}>
            <IconWrapper>
            <LeftIcon src={props.svgUrl} alt="LeftIcon" height="31"/>
            </IconWrapper>
            <Title>{props.title}</Title>
        </Container>
    )
}

export default SimpleCard;