import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useAuth0 } from '../../shared-lib/auth-provider';

const TabBar = styled.div`

position: relative;
margin-bottom: -3px;
height: 60px;
width: 360px;
color: #69469d;
font-size: 21px;
visibility: ${props => props.visibility}; 

& > input {
  cursor: pointer;
  z-index: 99;
  opacity: 0;
}

& > input, & > span {
  width: 120px;
  height: 60px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  font-family: var(--font-mrEaves);
}

#tab-0, #tab-0 + span {
  left: 0px;
}

#tab-1, #tab-1 + span {
  left: 120px;
}

#tab-2, #tab-2 + span {
  left: 240px;
}

& + .line {
  width: 120px;
  height: 4px;
  top: 56px;
  position: absolute;
}

& > .line {
  width: 120px;
  height: 4px;
  top: 54px;
  position: absolute;
  background: #69469d
  transition: all .1s;
}

#tab-0:checked ~ .line {
  left: 0px;
}

#tab-1:checked ~ .line {
  left: 120px;
}

#tab-2:checked ~ .line {
  left: 240px;
}

#tab-0:checked + span {
  font-weight: bold;
}

#tab-1:checked + span {
  font-weight: bold;
}

#tab-2:checked + span {
  font-weight: bold;
}
`

const getInitialTab = (pathname) => {
if (pathname.includes("invitations")) {
  return 1;
} else if (pathname.includes("settings")) {
  return 2;
} else {
  return 0;
}
}


const CalendarTabbar = () => {

const { pathname } = useLocation();
const { url } = useRouteMatch();
const initialTab = getInitialTab(pathname)
const [activeTab, setActiveTab] = useState(initialTab);
const history = useHistory()
const { isAuthenticated } = useAuth0()

const handleChange = (event) => {
  const tabIndex = parseInt(event.target.value)

  switch (tabIndex) {
    case 0:
      history.push(`${url}/calendar`)
      break;
    case 1:
      history.push(`${url}/invitations`)
      break;
    case 2:
      history.push(`${url}/settings`)
      break;
    default:
      history.push(`${url}/calendar`)
  }
  setActiveTab(tabIndex)
};

return (
  <TabBar visibility={isAuthenticated ? "visible" : "hidden"}>
    <input type="radio" id="tab-0" checked={activeTab === 0} name="tab" value={0} onChange={handleChange}></input>
    <span>Calendar</span>
    <input type="radio" id="tab-1" checked={activeTab === 1} name="tab" value={1} onChange={handleChange}></input>
    <span>Invitations</span>
    <input type="radio" id="tab-2" checked={activeTab === 2} name="tab" value={2} onChange={handleChange}></input>
    <span>Village settings</span>
    <div className="line"/> 
</TabBar>
)
}

export default CalendarTabbar;