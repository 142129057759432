
//these functions serve to connect the new radio buttons for needing childcare,eldercare,petcare to the
//current middleware, lambdas, and database structure for real relatives with name, age, relationship, and id.

const hasChild = (relatives) => {
    //return true or false to settings if there is a child
    if (!relatives) { return false}
    if (relatives.filter(rel => rel && rel.age <18).length > 0) {
        return true
    }
    return false
}

const setChild = (setChildBool) => {
    //return a child object if true
    if (setChildBool) {
        return {name: "Generic Child", age: 1, relationship: "Child", id: null}
    } 
    return null
}

const hasElder = (relatives) => {
    if (!relatives) { return false }
    //return true or false to settings if there is an elder
    if (relatives.filter(rel => rel && rel.age >=65).length > 0) {
        return true
    }
    return false
}

const setElder = (setElderBool) => {
    //return an elder object if true
    if (setElderBool) {
        return {name: "Generic Elder", age: 99, relationship: "Parent", id: null}
    }
    return null
}


const hasPet = (relatives) => {
    if (!relatives) { return false }
    if (relatives.some(rel => rel && (rel.relationship === "Dog" || rel.relationship === "Cat"))){
        return true
    }
    return false
}

const setPet = (setPetBool) => {
    //return a pet object (just dog so we don't have to change the task generation logic!)
    if (setPetBool) {
        return {name: "Generic Dog", age: 20, relationship: "Dog", id: null}
    }
    return null
}


//takes object with bools for child, elder, pet and returns 3 genertic relatives
export const mapHasRelativeBoolsToGenericRelativesObject = (relativesBools) => {
    return [setChild(relativesBools.childcare), setElder(relativesBools.eldercare), setPet(relativesBools.petcare)].filter((member) => {
        return member != null;
    });

}

//takes array of relatives and nulls and returns object with bool for child, elder, pet
export const mapGenericRelativesObjectToRelativeBools = (relatives) => {
    if (relatives == null || relatives.length === 0) {
        return {childcare: null, eldercare: null, petcare: null}
    }
    return {childcare: hasChild(relatives), eldercare: hasElder(relatives), petcare: hasPet(relatives)}
}