import React, { useEffect } from 'react';
import { useAuth0 } from '../../shared-lib/auth-provider';
import loading from '../../auth/loading.svg';
import { useAnalytics } from '../../analytics/AnalyticsContext';
import { useHistory } from 'react-router-dom';

function LoginPage() {

    const { isAuthenticated, isRestoring, login } = useAuth0()
    const { mpTrackLogin } = useAnalytics()
    const history = useHistory()

    useEffect(() => {
        // role will reset after login
        mpTrackLogin("org")
    },[])
    
    useEffect(() => {
        if (!isRestoring) {
            if (isAuthenticated) {
                history.push('/mission/dashboard/tasks')
            } else {
                login()
            }
        }
    }, [isAuthenticated, isRestoring, login])


    return (<div style={{
        display: "flex",
        height: '100vh',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <img src={loading} alt="loading"/>
    </div>)
}

export default LoginPage;