import React from 'react';
import InvitationsHeader from './InvitationsHeader';
import InvitationsTabs from './InvitationsTabs';
import { useUser } from '../../auth/UserContext';

function Invitations() {
    
    const { membership } = useUser()
    const { person_passed } = membership



    return (
        <div className="inv-root">
            <InvitationsHeader/>
            <InvitationsTabs 
                personPassed={person_passed}
            />
        </div>
    )
}

export default Invitations;
