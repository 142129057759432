import styled from 'styled-components';
import bgUrl from '../../assets/backgroundPattern'

const BackgroundPattern = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center 75%;
    opacity: 0.15;
    z-index: 0;
    content:url(${bgUrl});
`


export default BackgroundPattern;