import React from 'react';
import styled from 'styled-components';
import RoundCheckBox from '../../../shared-lib/components/general/RoundCheckBox';
import LoadingIcon from '../../../shared-lib/assets/white-loading.svg';

const PickerContainer  = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    hover: pointer;
    font-family: ${props => props.theme.mrsEaves}; 
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    align-self: top;
    max-height: 35px;
    overflow-y: visible;
    width: 100%;

`
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-purple);
    font-family: var(--font-mrEaves);
    color: #fff;
    width: 100%;
    height: auto;
    padding: 5px 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-bottom: ${props => props.expanded ? 'none' :  '1px solid #ccc'};
    border-bottom-left-radius: ${props => props.expanded ? '0' : '5px'};
    border-bottom-right-radius: ${props => props.expanded ? '0' : '5px'};
`

const DownArrow = styled.p`
    transform: ${props => props.open ? 'rotate(360deg)' : 'rotate(180deg)'};
    margin: 0px;
    margin-left:5px;
    padding-top: 5px;
`

const OptionContainer = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    align-items:flx-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    width: 100%;
    height: ${props => props.visible ? 'auto' : '0px'};
    z-index: 10;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all .5s;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
`

const RoleOptionHolder = styled.div `
    height: auto; 
    z-index: 10;
    width: 100%;
    display: flex;
    background-color:${props => props.selected ? 'var(--color-light-purple)' : '#fff'};
    padding: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    & p {
        font-family: var(--font-mrEaves);
        font-size: 18px;
        color: var(--color-dark-purple);
        margin: 0;
    }
`
const Spinner = styled.img`
    display: flex;
    height: 25px;
    width: 25px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-family: var(--font-mrEaves);
    color: #fff;
`

const roleTitle = (role) => {
    if (role === 'cont') {
        return 'Volunteer'
    } else if (role === 'rec') {
        return 'Recipient'
    } 
}

const Picker = ({role, onClick, expanded, setRole, loading}) => {
    const roles = ['rec', 'cont']
    console.log('visible', expanded);
    const handleRoleClick = (e,r) => {
        console.log('e', e);
        e.preventDefault();
        e.stopPropagation();
        setRole(r)
    }
    return (
        <PickerContainer onClick={onClick} expanded={expanded}> 
            <TitleWrapper expanded={expanded}>
                {loading ? <Spinner src={LoadingIcon}/> : roleTitle(role)}
                <DownArrow open={expanded}>^</DownArrow>
            </TitleWrapper>
            <OptionContainer visible={expanded}>
                {roles.map((r, i) => {
                    return (
                    <RoleOptionHolder 
                    key={i} 
                    selected={role === r} 
                    onClick={(e) => handleRoleClick(e,r)}>
                        <p>{roleTitle(r)}</p>
                        <RoundCheckBox checked={role === r} checkedColor='var(--color-dark-purple)' uncheckedColor='#ccc'/>
                    </RoleOptionHolder>)
                })}
            </OptionContainer>
        </PickerContainer>
    )
}

export default Picker;