import React from 'react';
import styled from 'styled-components';
import IconButtonSmall from '../../../shared-lib/components/IconButtonSmall'

const Container = styled.div`
    width: fit-content;
    height: 44px
    background-size: cover;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-self: center;
    justify-content: space-evenly;
    color: #69469d;

    :hover {
        cursor: pointer;
        color: #060368;
    }   
`

const Title = styled.div`
    width: fit-content;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    font-weight: 400;
    text-decoration: none solid rgb(105, 70, 157);
    margin-left: 10px;
    text-transform: none;
    
`

function AddTaskIconComponent(props) {
    return(
        <Container onClick={props.onClick}>
        <IconButtonSmall icon='plus' />   
            <Title>{props.title}</Title>
        </Container>
    )
}

export default AddTaskIconComponent;