import styled from 'styled-components';
import checkIcon from '../../shared-lib/assets/check-purple.svg'
import smallCheckIcon from '../../shared-lib/assets/check-purple-small.svg'

export const StepperContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content:center;
    align-self: center;
    font-weight: bold;
    margin: 130px 10px 90px 10px;
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;

    @media(max-width: 675px) {
        margin: 56px 10px 20px 10px;
        padding-left:15px;
        padding-right:15px;
    }
`

export const OrderedList = styled.ol`
    list-style-type: none;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    max-width: 90%;
`

export const ListItem = styled.li`
    margin: 0px 2px 0px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 36px;

    @media(max-width: 675px) {
        width: 20px;
    }

    & label {
        background-color: ${(props) => props.complete ? 'white' : props.currentStep ? '#060368' : '#ebe9f4'}; 
        color: ${(props) => props.currentStep ? 'white' : '#060368'}; 
        width: 36px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        left: 0;
        top: 0;
        text-align: center;
        font-size: 14px;
        overflow: hidden;
        :hover {
            cursor: pointer;
        }
        @media(max-width: 675px) {
            border_radius:10px;
            line-height: 20px;
            width: 20px;
            height: 20px;
        }
    }

    & img {
        content:url(${(props) => props.complete ? checkIcon : '' });
        display: ${(props) => props.complete ? 'block' : 'none' }
        @media(max-width: 675px) {
            content:url(${(props) => props.complete ? smallCheckIcon : '' });
        }
    }
   
    & span {
        color: ${(props) => props.currentStep ? '#060368' : '#69489D'}; 
    } 

    :* {
        box-sizing: border-box;
        transition: 100ms;
    }
`

export const TextSpan = styled.span`

    text-align: center;
    width: 80px;
    top: 40px;
    left: -20px;
    font-weight: 400;
    font-size: 18px;
    font-family: var(--font-mrEaves);
    margin-top: 6px;

    @media(max-width: 675px) {
        top: 40px;
        width: 40px;
        left: 0px
        display: ${(props) => props.size === 'show-large' ? 'none' : 'default'};
        font-size: 16px;
        margin-top: 4px;
    }

    @media(min-width: 676px) {
        display: ${(props) => props.size === 'show-small' ? 'none' : 'default'};
        font-size: 18px;
    }
`

export const LineSvg = styled.svg`
    width: 100%;
    height: 2px;
    margin-top:18px;
    
    & line {
        stroke: #69469d;
        stroke-width: 4px;
        
        @media(max-width: 675px) {
            stroke-width: 2px;
        }
    }

    @media(max-width: 675px) {
        margin-top: 10px;
        height: 1px;
    }
`
