import Auth0Wrapper from './Auth0Wrapper';
import { MEMBERSHIP, USER, ROLES, IS_AUTHENTICATED, AUTH_SESSION } from '../utils/localStorage';


export const getAuthAccessToken = () => {
    const authSessionJsonString = localStorage.getItem(AUTH_SESSION) || '{}';
    const authSession = JSON.parse(authSessionJsonString) || {};

    const authResult = authSession && authSession.authResult; 
    return authResult && authResult.accessToken;
};

class AuthHandler {

  __auth0Wrapper

  constructor(config, connection) {
    this.__auth0Wrapper = new Auth0Wrapper(config, connection);
  }

  login = () => {
    this.__auth0Wrapper.login()
  }

  loginWithCredential = (credentials) => {
    return new Promise((resolve, reject) => {
      this.__auth0Wrapper.loginWithCredential(credentials, (error, loginResult) => {
        if (error) {
          reject(error)
        } else {
          resolve(loginResult)
        }
      })
    })
  }

  silentAuthenticate = (state) => {
    return this.__auth0Wrapper.silentAuthenticate(state)
  }

  signup = (email, password) => {
    return new Promise((resolve, reject) => {
      this.__auth0Wrapper.signup({email, password}, (error, signupResult) => {
          if (error) {
            reject(error)
          } else {
            resolve(signupResult)
          }
      })
    })
  }

  logout = (returnTo) => {
    this.clearStoredAuthData();
    this.__auth0Wrapper.logout(returnTo);
  }

  getUserInfo = async (accessToken) => {
    return this.__auth0Wrapper.getUserInfo(accessToken);
  }

  handleParseHash = async () => {
    return this.__auth0Wrapper.handleParseHash();
  }

  refreshSession = async () => {
    return this.__auth0Wrapper.handleCheckSession();
  }

  setupAuthSession = async (authResult) => {
    if (!authResult || !authResult.accessToken) {
      throw new Error('No AccessToken');
    }
    const userInfo = await this.getUserInfo(authResult.accessToken);
    const session = this.composeSessionObject(authResult, userInfo);
    return session;
  }

  auth0SignupAndSignin = async (email, password) => {
    const authResult = await this.__auth0Wrapper.signupAndAuthorizeNewUser(email, password);
    const userInfo = await this.getUserInfo(authResult.accessToken);
    const session = this.composeSessionObject(authResult, userInfo);
    return session;
  };

  composeSessionObject = (authResult, userInfo) => {
    return {
      authSession: {
        authResult: authResult,
        userInfo: userInfo,
        tokens: {
          accessToken: authResult.accessToken
        },
      },
      isAuthenticated: true
    }
  };

  getSessionFromLocalStorage = () => {
    const isAuthenticatedString = localStorage.getItem(IS_AUTHENTICATED)
    const authSessionString = localStorage.getItem(AUTH_SESSION)
    return {
      authSession: JSON.parse(authSessionString),
      isAuthenticated: JSON.parse(isAuthenticatedString)
    }
  }

  persistAuthData = (authData) => {
    localStorage.setItem(AUTH_SESSION, JSON.stringify(authData.authSession));
    localStorage.setItem(IS_AUTHENTICATED, JSON.stringify(authData.isAuthenticated));
  };

  clearStoredAuthData = () => {
    localStorage.removeItem(AUTH_SESSION);
    localStorage.removeItem(IS_AUTHENTICATED);
    localStorage.removeItem(MEMBERSHIP);
    localStorage.removeItem(USER);
    localStorage.removeItem(ROLES);
  };
}

export default AuthHandler;
