import React from 'react';


const loading = "https://village-co-callback.s3.amazonaws.com/images/loading.svg"
const Spinner = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            flexGrow: 1,
        }}>
            <img 
                style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: 120,
                    maxHeight: 120,
                }}
                src={loading}
                alt="loading"
            />
        </div>)
}


export default Spinner;