import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../shared-lib/components/general/Modal';
import EmailPanel from '../EmailPanel';
import { useSelector } from 'react-redux';
import { Status } from '../../../../data/api';

const Title = styled.label`
    font-family: var(--font-mrEaves);
    font-weight: bold;
    color: var(--color-dark-purple);
    font-size: 42px;
    margin-bottom: 20px;
`


const EmailInviteModal = ({isOpen = true, handleClose, isSmall, personPassed, inviteAs='cont'}) => {

    const inviteContState = useSelector(state => state.VillageReducer.inviteContState)
    const {
        error:sendError,
        status:sendStatus,
        response: sendResponse
    } = inviteContState

    const errorMsg = sendError ? "Sorry, we're having trouble sending your invitation. Please try again later." : null
    const successMsg = sendResponse ? 'Your email invitation has been sent.' : null

    return( 
        <Modal open={isOpen} handleClose={handleClose} closable={!isSmall} width='100%' error={errorMsg} success={successMsg}>
            <Title>Email volunteers</Title>
            <EmailPanel sendEmailInvitationsState={inviteContState} personPassed={personPassed} handleClose={handleClose} bodyHeight={isSmall ? '500px':'350px'} inviteAs={inviteAs} maxWidth={'570px'}/>
        </Modal>
    )
}

export default EmailInviteModal;
