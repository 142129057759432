import { titles, categories } from './taskSeries';

export const selectChildcare = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.childcare)
}

export const selectPetcare = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.petcare)
}

export const selectActivity = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.activity)
}

export const selectCheckin = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.checkin)
}

export const selectCompanionship = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.companionship)
}

export const selectFinanceCoaching = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.financeCoaching)
}

export const selectDishes = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.dishes)
}

export const selectLaundry = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.laundry)
}

export const selectBathrooms = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.bathrooms)
}

export const selectLawnCare = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.lawncare)
}

export const selectTidyingUp = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title ===  titles.tidyingUp)
}

export const selectGroceries = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.groceries)
}

export const selectMeals = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.meals)
}

export const selectEldercare = (state) => {
    const {taskSeries} = state.VillageReducer
    return taskSeries.find(i => i.title === titles.eldercare)
}

export const selectOther = (state) => {
    const {taskSeries} = state.VillageReducer;
	const otherTaskSeries = taskSeries.filter(item => {
		return item.category.toUpperCase() === categories.other.toUpperCase();
    });
    return otherTaskSeries
}

