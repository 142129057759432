import React, { useState, useEffect } from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {FormField} from './FormField';
import Button from '../Button';
import FlexForm from '../FlexForm';

import { ActivityIndicator } from '../general/ActivityIndicator';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email invalid')
    .required('Email is required'),
})

const PasswordResetForm = ({
  submitHandler = () => {},
  isHidden,
  togglePasswordReset,
  emailAddress,
}) => {

  const [isEmailSent, setEmailSent] = useState(false)

  const handleSendEmail = (values) => {
    setEmailSent(true)
    submitHandler(values)
  }

  const formik = useFormik({
    initialValues: { email: emailAddress },
    validationSchema: validationSchema,
    onSubmit: handleSendEmail,
  })



  const gobackToLogin = () => {
    togglePasswordReset(true, formik.values.email)
    formik.resetForm()
    setEmailSent(false)
  }

  return (
    <FlexForm onSubmit={formik.handleSubmit} isHidden={isHidden}>
      <p>Enter your email below and we will send a link to reset your password.</p>
      <FormField
        config={{
            title: "Your email",
            touched: formik.touched.email,
            error: formik.errors.email,
            onBlur: formik.handleBlur,
            id: "email_password_reset",
            name: "email",
            onChange: formik.handleChange,
            type: "email",
            value: formik.values.email
        }}
      />
      { !isEmailSent ?
        <Button type="submit" enabled={formik.isValid && !formik.isSubmitting}>
            Reset password
        </Button>
      :
      <p style={{color: "#5b7750"}}>Password reset email sent.</p>
      }
      <a className="link" onClick={gobackToLogin}>Back to login</a>
    </FlexForm>
  )
}

export default PasswordResetForm;