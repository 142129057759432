import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '46px',
        height: '46px',
        borderRadius: '23.5px',
        padding: 7,
    },
    primary: {
        backgroundColor: '#69469d',
        border: 'none',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#060368'
        },
        '&:active': {
            border: '2px solid #ffffff'
        },
        '&:focus' : {
            outline:'none'
        },
        '&:disabled': {
            border: '1px solid #cccccc',
            backgroundColor:' #ffffff'
        },
        '&:disabled > svg': {
            fill: '#cccccc'
        },
        '& > svg': {
            fill: 'white'
        },
    },
    secondary: {
        backgroundColor: '#ffffff',
        border: '2px solid #69469d',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#060368',
            border: '2px solid #060368',
        },
        '&:active': {
            border: '2px solid #ffffff'
        },
        '&:focus' : {
            outline:'none'
        },
        '&:disabled': {
            border: '1px solid #cccccc',
            backgroundColor:' #ffffff'
        },
        '&:disabled > svg': {
            fill: '#cccccc'
        },
        '& > svg': {
            fill: '#69469d'
        },
        '&:hover svg': {
            fill: 'white'
        }
    },
    small: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        padding: 0,
        border: '2px solid #69469d',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#69469d',
            border: '2px solid #69469d',
        },
        '& > svg': {
            fill: '#69469d',
            padding: '3px',
        },
        '&:hover svg': {
            fill: 'white'
        }
    }
})


function IconButton({ 
    variant = "primary", 
    disabled = false, 
    style = {} ,
    onClick = () => {}
}) {
    const classes = useStyles()
    return (
        <button style={style} disabled={disabled} className={clsx(classes.root, classes[variant])} onClick={onClick}>
            <svg overflow="visible" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
        </button>
    )
}

IconButton.propTypes = {
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func
}

export default IconButton;
