import styled from 'styled-components';

const LightButton = styled.button`
    width: 216px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: ${(props) => props.enabled ? 'var(--color-savo-purple)' : '#767676'};
    font-weight: bold;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    pointer-events:  ${(props) => props.enabled ? 'default' : 'none'};

    :hover {
        cursor: ${(props) => props.enabled ? 'pointer' : 'default'};
        background-color: ${(props) => props.enabled ? 'var(--color-light-purple)' : '#ffffff'};
        color: ${(props) => props.enabled ? 'var(--color-dark-purple)' : '#767676'};
    }
`

export default LightButton;