import React from 'react';
import styled from 'styled-components';

const Background = styled.div`
  background-color: #ebe9f4;
  border-radius: 5px;
  padding: 10px;
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
  
  ul {
    margin: 10px 0;
  }

  p {
    margin: 10px 0;
  }
`

const NoteList = ({ recentNotes }) => {

  const hidden = recentNotes.length < 1

  return <Background hidden={hidden}>
    <p>Others who are volunteering for this task have left some notes that may be helpful as you plan:</p>
    <ul>
      {recentNotes.map((item, idx) => {
          return <li key={idx}>{item.note}</li>
      })}
    </ul>
  </Background>
}

export default NoteList;