import { 
    WIZARD_STEP_1, 
    WIZARD_STEP_2, 
    WIZARD_STEP_3, 
    WIZARD_STEP_4,  
    SKIP_WIZARD_STEP_4,
   } from '../actions/actionTypes';


const initialState = {
    step1: null,
    step2: "ready to start",
    step3: {
        name: "",
        familyMembers: []
    },
    step4: ["", "", "", "", "", ""],
}

const WizardReducer = (state = initialState, action) => {
    const payload = action.payload
    switch (action.type) {
        case WIZARD_STEP_1:
            return {
                ...state,
                ...action.payload,
            };
        case WIZARD_STEP_2:
            return {
                ...state,
                step2: action.payload.step2
            }
        case WIZARD_STEP_3:
            return {
                ...state,
                step3: action.payload
            };
        case WIZARD_STEP_4:
            return {
                ...state,
                step4: payload.step4
            }
        case SKIP_WIZARD_STEP_4:
            return {
                ...state,
                step4: ["", "", "", "", "", ""]
            }
        default:
            return state;
    }
}

export default WizardReducer;