
import styled from 'styled-components';

export const ColContainer = styled.div`
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0px;
    flex-grow: 1;
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`

export const Title = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-weight: bold;
    color: #060368;
    height: auto;
    font-size: 64px;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin: 20px 0px 50px 0px;
`

export const ButtonCard = styled.label`
    width: 310px;
    height: 231px;
    border: 2px solid #a694c7;
    border-radius: 8px;
    background-color: ${({isChecked}) => isChecked ? "#ebe9f4" : "white"};
    background-size: cover;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 15px 70px 15px;

    &:hover {
        background: #ebe9f4;
        cursor: pointer;
    }

    & input {
        width: 10px;
        height: 10px;
        opacity: 0;
        position: absolute;
    }
`

export const Icon = styled.img`
    height: 71px;
`

export const Option = styled.span`
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 43px;
    color: #69489d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 72, 157);
    text-align: center;
    margin: 20px
`