import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
`

const PanelContainer = ({children}) => {
    return <Container>
        {children}
    </Container>
}

export default PanelContainer;