import React from 'react'
import styled from 'styled-components';
import checkIcon from '../../assets/check-white.svg';

const ItemRoot = styled.div`
    min-height: 20px;
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: notwrap;
    font-family: mrs-eaves-xl-serif, serif;
    font-size: 14px;
    color: #111111;
    text-decoration: none solid rgb(17, 17, 17);
    padding-left: 14px;
`

const RoundIconWrapper = styled.div`
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${props => props.fulfilled ? "#91b77a" : "#cccccc"};
    background-size: cover;
    display:flex;
    align-items: center;
    justify-content: center;
`

const RoundIcon = styled.img`
    height: 6px;
    width: 8px;
    visibility: ${props => props.fulfilled ? "visible" : "hidden"};
`
const ItemText = styled.p`
    margin:0 0 0 10px;
    text-align: left;
`


export function CheckableListItem(props) {
    const fulfilled = props.requirement.test(props.value);
    return(
        <ItemRoot>
            <RoundIconWrapper fulfilled={fulfilled}>
                <RoundIcon src={checkIcon} fulfilled={fulfilled}></RoundIcon>
            </RoundIconWrapper>
            <ItemText>{props.requirement.name}</ItemText>
        </ItemRoot>
    )
}