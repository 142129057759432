import React from 'react';
import styled from 'styled-components';
import careIcon from './assets/care.svg';
import cleaningIcon from './assets/cleaning.svg';
import foodIcon from './assets/food.svg';
import emotionalIcon from './assets/emotional.svg';
import starIcon from './assets/star.svg';

const TabButton = styled.button`

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 10px 20px 20px;
    width: 100%;
    height: 133px;
    border-width: 0px;
    border-top-width: ${props => props.indx === 0 ? "1px" : "0px"};
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #a694c7;
    background-color:${(props) => props.isActive ? "#ffffff" : "#ebe9f4"};
    background-size: cover;
    box-shadow: ${(props) => props.isActive ? "none" : "inset -2px 0px 2px rgba(0,0,0,0.1)"};
    :active {
        background-color: #ffffff;
    }

    :focus {
        outline: none;
    }
    cursor: pointer;

    @media (max-width: 1024px) {
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-left-width: 1px;
        border-right-width: 1px;
        align-items: center;
        margin: 0px;

        flex-direction: column;
        width: auto;
        height: auto;
        padding: 0px;
        flex: 1 1 20%;
    }
`

const TabIcon = styled.img`
    height: 29px;
    @media (max-width: 1024px) {
        flex: 1 1 50%;
        padding: 5px;
        height: 50%;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 1024px) {
        flex: 1 1 50%;
        align-items: center;
    }
`

const TabTitle= styled.div`
    width: auto;
    margin-bottom: auto;
    font-family: var(--font-mrEaves); 
    font-size: 26px;
    color: #69489d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 72, 157);
    text-align: left;
    margin-bottom: 7px;
    font-weight: bold;
    margin-left: 10px;

    @media (max-width: 1024px) {
        font-size: 16px;
        text-align: center;
        margin: 0px;
    }
`

const TabSubtitle = styled.div`
    width: auto;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #555555;
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 21px;
    font-style: italic;
    text-align: left;
    margin-left: 10px;

    @media (max-width: 1024px) {
        display: none;
    }
`

function Tabbar(props) {

    return (
        <div className="tabbar-root">
            <TabButton idx={0} isActive={props.activeTab === 0} onClick={() => props.handleTabChange(0)}>
                <TabIcon src={careIcon} />
                <TitleWrapper>
                    <TabTitle>Providing care</TabTitle>
                    <TabSubtitle>Childcare, eldercare, and petcare</TabSubtitle>
                </TitleWrapper>
            </TabButton>
            <TabButton idx={1} isActive={props.activeTab === 1} onClick={() => props.handleTabChange(1)}>
                <TabIcon src={cleaningIcon} />
                <TitleWrapper>
                    <TabTitle>Cleaning</TabTitle>
                    <TabSubtitle>Bathrooms, laundry, and other housework</TabSubtitle>
                </TitleWrapper>
            </TabButton>
            <TabButton idx={2} isActive={props.activeTab === 2} onClick={() => props.handleTabChange(2)}>
                <TabIcon src={foodIcon} />
                <TitleWrapper>
                    <TabTitle>Delivering food</TabTitle>
                    <TabSubtitle>Grocery shopping and meals</TabSubtitle>
                </TitleWrapper>
            </TabButton>
            <TabButton idx={3} isActive={props.activeTab === 3} onClick={() => props.handleTabChange(3)}>
                <TabIcon src={emotionalIcon} />
                <TitleWrapper>
                    <TabTitle>Emotional support</TabTitle>
                    <TabSubtitle>Activities, checking in, and companionship</TabSubtitle>
                </TitleWrapper>
            </TabButton>
            <TabButton idx={4} isActive={props.activeTab === 4} onClick={() => props.handleTabChange(4)}>
                <TabIcon src={starIcon} />
                <TitleWrapper>
                    <TabTitle>Other ways to help</TabTitle>
                    <TabSubtitle>Watering plants</TabSubtitle>
                </TitleWrapper>
            </TabButton>
        </div>
    )
}

export default Tabbar;