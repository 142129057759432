import Modal from '../../../shared-lib/components/general/Modal';
import styled from 'styled-components';
import React from 'react';

const OkButton = styled.button`
    width: 140px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;

    :hover {
        background-color: #060368;
        cursor: pointer;
    }
    @media(max-width: 500px) {
        width: 120px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
`

const Title = styled.div`
    font-weight: bold;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 36px;
    color: #060368;
    text-align: center;
    max-width: 650px;

`

const SubTitle = styled.div`
    font-family: ${props => props.theme.mrsEaves}; 
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
`

const InviteRedirectModal = ({reason='', handleClose}) => {

    const reasonText = reason === 'expired' ? 'This invitation has expired.' : 'This invitation has already been accepted.';

    return(
        <Modal open={true} closable={false}>
            <Title>Invitation no longer valid </Title>
    <SubTitle>{reasonText}</SubTitle>
            <ButtonContainer>
                <OkButton onClick={handleClose}>OK</OkButton>
            </ButtonContainer>
        </Modal>

    )
}

export default InviteRedirectModal;