import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { addTask, cleanAddTask } from '../../../data/actions'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useUser } from '../../../auth/UserContext';
import PropTypes from 'prop-types';
import IconButtonPlus from '../../../shared-lib/components/IconButtonPlus';
import Tooltip from '../../../shared-lib/components/Tooltip';
import loadingIcon from '../../../shared-lib/assets/loading.svg';
import { StatusBanner } from '../../../shared-lib/components/general/StatusBanner';
import moment from 'moment';

const Root = styled.div`
    position: relative;
    max-width: 120px;
    flex: 1 1 10%
    width: 10%;
    height: 87px;
    background: ${props => props.backgroundColor};
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #a694c7;
    pointer-events: ${props => props.clickable ? 'auto' : 'none'}
`

const Container = styled.div`
    max-width: 100px;
    height: 75px;
    width: calc(100% - 8px);
    margin: 0px 5px;
    padding: 1px;
    font-family: ${props => props.theme.mrEaves}; 
    border: ${props => props.hasAssignee? 'solid  2px #a694c7' : props.isEnabled ? '2px solid #69469d' : '2px solid clear'};
    border-radius: 5px;
    background-color: ${props => props.hasAssignee? '#ffffff' : props.isEnabled? '#69469d' : 'clear'};
    background-size: cover;
    box-shadow: ${props => props.isEnabled? '0px 1px 2px 2px rgba(0,0,0,0.05)' : 'none'} ;
    color: ${props => props.hasAssignee? '#69469d' : props.isEnabled? '#ffffff' : 'clear'};;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    word-break: break-word;
    :hover {
        background-color: ${props => props.hasAssignee? '#ebe9f4' : props.isEnabled? '#060368' : 'clear'};
        border: ${props => props.hasAssignee? 'solid  2px #a694c7' : props.isEnabled ? '2px solid #060368' : '2px solid clear'};
    }

    & p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 20px;
        height: 24px;
    }

    @media (min-width: 400px) and (max-width: 675px) {
        & p {
            transform: rotate(-90deg);
            font-size: 14px;
            height: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0px;
        }

    }

    @media (min-width: 300px) and (max-width: 400px) {
        & p {
            transform: rotate(-90deg);
            font-size: 10px;
            height: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0px;
        }

    }

    @media (max-width: 300px) {
        & p {
            transform: rotate(-90deg);
            font-size: 10px;
            height: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0px;
        }
    }

`

const TaskAddRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & button {
        display: none;
        background: none;
    }
    :hover {
        cursor: pointer;
        background-color: rgba(6, 3, 104, 0.3);
    }
    :hover button {
        display: flex;
    }
    :hover .tooltip {
        visibility: visible;
    }
`

const getAddTaskResponse = (state) => state.VillageReducer.addTaskResponse
const getAddTaskError = (state) => state.VillageReducer.addTaskError
const selectMission = (state) => state.VillageReducer.mission

// make timeslot non-clickable if date is outside of mission start/end
export const checkMissionStartEnd = (mission, date) => {
    const { creation_date } = mission
    const tsDate = moment(date)
    const missionStart = moment(creation_date).utc().startOf('day')
    const missionEnd = missionStart
                        .clone()
                        .add(1, 'year')
                        .endOf('day')
    return tsDate >= missionStart && tsDate <= missionEnd
}

function Timeslot({
    timeslot,
    series_id,
    backgroundColor,
    showDayModal
}) {
    
    const dispatch = useDispatch();
    const addTaskResponse = useSelector(getAddTaskResponse, shallowEqual);
    const addTaskError = useSelector(getAddTaskError, shallowEqual);
    const mission = useSelector(selectMission, shallowEqual)

    const [addTaskStatus, setAddTaskStatus] = useState({info: null, error: null, loading: false})
    const { membership } = useUser();
    const { isEnabled, assignee, date } = timeslot
    const hasAssignee = (isEnabled && assignee !== null)
    const [loop, setLoop] = useState();

    const clickable = useMemo(() => {
        if (mission && mission.creation_date) {
            return checkMissionStartEnd(mission, date)
        } else {
            return false
        }
    }, [mission, date])

    const handleAddTask = () => {
        if (membership && membership.mission_uuid) {
            dispatch(addTask({
                date: date,
                series_id: series_id,
                mission_uuid: membership.mission_uuid,
                exceptionId: timeslot.exception_id
            }))
            setAddTaskStatus({...addTaskStatus, loading: true})
        }
    }

    const handleTaskCardClick = () => {
        if(isEnabled){
            showDayModal(timeslot, series_id)
        }
    }

    useEffect(() => {
        if (addTaskResponse) {
            setAddTaskStatus({...addTaskStatus, loading: false})
        }
    }, [addTaskResponse])

    useEffect(() => {
        if (addTaskError) {
            setAddTaskStatus({
                info: null,
                error: "Failed to add task.",
                loading: false
            })
            const interval = setInterval(() => {
                setAddTaskStatus({info: null, error: null, loading: false});
            }, 5000);
            setLoop(interval)
            return () => clearInterval(loop); 
        }
    }, [addTaskError, loop])

    if (addTaskStatus.loading) {
        return (
            <Root backgroundColor={backgroundColor}>
                <img 
                    style={{width: 80, height: 80, display: 'flex'}} 
                    src={loadingIcon} alt="Loading"
                />
            </Root>
        )
    }

    return(
    <Root backgroundColor={backgroundColor} clickable={clickable}>
        <div style={{position: 'fixed', top: 51, left: 0, width: "100%"}}>
            <StatusBanner info={addTaskStatus.info} error={addTaskStatus.error}></StatusBanner>
        </div>
        { isEnabled ?
            <Container 
                isEnabled={isEnabled} 
                hasAssignee={hasAssignee} 
                onClick={handleTaskCardClick}
                >
                <p>
                    { hasAssignee ? assignee : isEnabled ? "Open" : ""}
                </p>
            </Container>
            :
            <TaskAddRoot onClick={handleAddTask}>
                <IconButtonPlus 
                    variant="secondary" 
                    width={20} height={20} /* size for svg icon */
                    style={{height: 26, width: 26}} /* size for button */
                    />
                <Tooltip>Add Task</Tooltip>
            </TaskAddRoot>
        }
    </Root>);
}


Timeslot.propTypes = {
    timeslot: PropTypes.shape({
        isEnabled: PropTypes.bool.isRequired,
        assignee: PropTypes.string,
        date: PropTypes.string.isRequired
    }),
    series_id: PropTypes.number.isRequired,
    showDayModal: PropTypes.func
}

export default Timeslot;