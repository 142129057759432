import React from 'react';
import PropTypes from 'prop-types';
import { StepperContainer, OrderedList, ListItem, LineSvg, TextSpan } from './style'
function Stepper(props) {

    const { activeStep } = props;
    const completeStep = activeStep-1;

    return (
        <StepperContainer>
            <OrderedList>
                <ListItem complete={completeStep>=1} currentStep={activeStep===1}>
                    <label>
                        <img alt="1"/>
                        1 
                    </label>
                    <TextSpan size='show-large'>Family information</TextSpan>
                    <TextSpan size='show-small'>Family</TextSpan>
                </ListItem>
                <LineSvg ><line x1="0%" y1="0%" x2="100%" y2="0"/></LineSvg>

                <ListItem complete={completeStep>=2} currentStep={activeStep===2}>
                    <label>
                        <img alt='2'/>
                        2
                    </label>
                    <TextSpan size='show-large'>How to help</TextSpan>
                    <TextSpan size='show-small'>Needs</TextSpan>
                </ListItem>
                <LineSvg><line x1="0%" y1="0%" x2="100%" y2="0"/></LineSvg>
                
                <ListItem complete={completeStep>=3} currentStep={activeStep===3}>
                    <label>
                        <img alt='3'/>
                        3
                    </label>
                    <TextSpan size='show-large' style={{width: 125, left: -40}}>Create village</TextSpan>
                    <TextSpan size='show-small' style={{marginLeft: -8}}>Village</TextSpan>
                </ListItem>
            </OrderedList>
        </StepperContainer>
    )
}

Stepper.propTypes = {
    activeStep: PropTypes.number.isRequired
}

export default Stepper;