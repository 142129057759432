import React, { useState, useEffect } from 'react';
import { useUser } from '../../auth/UserContext';
import { getBookings, resetGetBookings, deleteBooking, deleteBookingReset } from '../../data/actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IconButtonPlus from '../../shared-lib/components/IconButtonSmall';
import BookingList from './BookingList';
import Spinner from '../../shared-lib/components/Spinner';
import ServerBanner from '../../shared-lib/components/general/ServerBanner';
import CancelTaskDialog from './CancelTask';
import { Status } from '../../data/api';
import { useAnalytics } from '../../analytics/AnalyticsContext';

const FamilyTasks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 580px;

    & h6 {
        font-family: var(--font-mrEaves);
        font-size: 36px;
        font-weight: 400;
        color: #060368;
        text-align: center;
        margin: 40px 0px 9px 0px;
    }
`

const AddMoreTaskWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px 0px 10px 0px;

    & a {
        font-size: 21px;
        font-family: var(--font-mrEaves);
        color: #69469d;
        margin: 0px 10px;
    }

    & > a:hover {
        cursor: pointer;
        color: #060368;
    }
`


function UpcomingTaskPage({
    getBookings,
    getBookingsError,
    getBookingsResponse,
    resetGetBookings,
    deleteBookingState,
    deleteBooking,
    deleteBookingReset
}) {

    const { user, roles, changeMissionHandler } = useUser();
    const user_uuid = user && user.user_uuid
    const [isRequesting, setIsRequesting] = useState(false)
    const [message, setMessage] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [bookingItem, setBookingItem] = useState({title: "hi", date: "2018-01-01" })

    const { mpTrackBooking } = useAnalytics()

    const openConfirmDialog = (booking) => {
        setIsOpen(true)
        setBookingItem(booking)
    }

    const removeHandler = () => {
        const bookingData = {
                booking: bookingItem,
                isFromTaskPage: true,
                user_uuid,
        }
        deleteBooking(bookingData)
        mpTrackBooking(bookingData, "delete")
        setIsOpen(false)
    }

    useEffect(() => {
        var timer;
        switch(deleteBookingState.status) {
            case Status.failure:
                setMessage("Delete booking failed.")
                timer = setTimeout(() => {
                    deleteBookingReset()
                    setMessage(null)
                }, 5000)
                break;
            default:
                setMessage(null)
        }

        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line
    }, [deleteBookingState])

    useEffect(() => {
        if (user_uuid) {
            resetGetBookings()
            setIsRequesting(true)
            getBookings(user_uuid)
        }
        // eslint-disable-next-line
    },[user_uuid])

    useEffect(() => {
        if (isRequesting) {
            if (getBookingsResponse || getBookingsError) {
                setIsRequesting(false)
            }
        }
    },[
        isRequesting,
        getBookingsResponse,
        getBookingsError
    ])

    if (isRequesting) {
        return <Spinner />
    }

    return (
    <div className="upcoming-tasks-root">
        <ServerBanner message={getBookingsError || message} />
        {
            roles.map((item, idx) => {
                return (
                <FamilyTasks>
                    <h6 key={idx}>{item.person_passed} family</h6>
                    <BookingList 
                        mission_uuid={item.mission_uuid}
                        bookings={getBookingsResponse || []}
                        openConfirmDialog={openConfirmDialog}
                        />
                    <AddMoreTaskWrapper onClick={() => changeMissionHandler(item.mission_uuid, item.person_passed, 'task-page')}>
                        <IconButtonPlus />
                        <a>Volunteer for more tasks</a>
                    </AddMoreTaskWrapper>
                </FamilyTasks>
                )
            })
        }
        <CancelTaskDialog 
            isOpen={isOpen}
            cancelHandler={() => setIsOpen(false)}
            removeHandler={removeHandler}
            bookingItem={bookingItem}
        />
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        getBookingsError: state.VillageReducer.getBookingsError,
        getBookingsResponse: state.VillageReducer.getBookingsResponse,
        deleteBookingState: state.VillageReducer.deleteBookingState,
    }
}

export default connect(mapStateToProps, {getBookings, resetGetBookings, deleteBooking, deleteBookingReset})(UpcomingTaskPage);