import _ from 'lodash';
export const structureInviteData = (data) => {
    const invitees = data.invites || []
    const members = data.members || []

    const recipients = members.filter(member => member.role === 'rec');
    const pendingRecipients = invitees.filter(member => member.role === 'rec');

    const volunteers = members.filter(member => member.role === 'cont');
    const pendingVolunteers = invitees.filter(member => member.role === 'cont');

    const organizers = members.filter(member => member.role === 'org');
    const pendingOrganizers = invitees.filter(member => member.role === 'org');
    const roles = [
        {
            title: 'Recipients',
            pending: pendingRecipients,
            accepted: recipients,
            id: 'rec'

        }, 
        {
            title: 'Organizers', 
            pending: pendingOrganizers,
            accepted: organizers,
            id: 'org'
        },
        {
            title: 'Volunteers',
            pending: pendingVolunteers,
            accepted: volunteers,
            id: 'cont'
        }
    ]

    const _selectRoles = (ids = ['org', 'rec', 'cont']) => {
        return roles.filter( role => {
            const result = _.find(ids, (id) => id === role.id);
            return result;
        })
     }


    const inviteData = {
        getCounts: (ids = ['org', 'rec', 'cont']) => {
            const counts = _selectRoles(ids).reduce((accum, current)=>{
                return {pending: accum.pending + current.pending.length, accepted:accum.accepted + current.accepted.length}
            }, {pending:0, accepted:0});
            return counts
        },
         selectRoles: (ids) => {
            return _selectRoles(ids);
         }
    }
    return inviteData
}