import { makeRequest } from '../api';
import env from '../../.env';

const urlPrefix = env.API_URL;

const icons = [
    { name: "Eldercare", url: "https://village-co-callback.s3.amazonaws.com/images/eldercare.svg" },
    { name: "Petcare", url: "https://village-co-callback.s3.amazonaws.com/images/petcare.svg" },
    { name: "Bathrooms", url: "https://village-co-callback.s3.amazonaws.com/images/bathrooms.svg" },
    { name: "Dishes", url: "https://village-co-callback.s3.amazonaws.com/images/dishes.svg" },
    { name: "Laundry", url: "https://village-co-callback.s3.amazonaws.com/images/laundry.svg" },
    { name: "Tidying Up", url: "https://village-co-callback.s3.amazonaws.com/images/tidying.svg" },
    { name: "Check In", url: "https://village-co-callback.s3.amazonaws.com/images/checkin.svg" },
    { name: "Finance Coaching", url: "https://village-co-callback.s3.amazonaws.com/images/financial.svg" },
    { name: "Meals", url: "https://village-co-callback.s3.amazonaws.com/images/meals.svg" },
    { name: "Lawn Care", url: "https://village-co-callback.s3.amazonaws.com/images/lawncare.svg" },
    { name: "Activity", url: "https://village-co-callback.s3.amazonaws.com/images/activity.svg" },
    { name: "Companionship", url: "https://village-co-callback.s3.amazonaws.com/images/companion.svg" },
    { name: "Groceries", url: "https://village-co-callback.s3.amazonaws.com/images/groceries.svg" },
    { name: "Childcare", url: "https://village-co-callback.s3.amazonaws.com/images/childcare.svg" },
    { name: "Other", url: "https://village-co-callback.s3.amazonaws.com/images/star.svg" },
]

const start_icon =  "https://village-co-callback.s3.amazonaws.com/images/star.svg"

export const fetchTaskSeries = async (mission_uuid, weekStart, weekEnd) => {
    const weekStartString = weekStart.toISOString()
    const weekEndString = weekEnd.toISOString()
    return makeRequest({
        uri: `${urlPrefix}/task/weeklyTimeslots?mission_uuid=${mission_uuid}&weekStart=${weekStartString}&weekEnd=${weekEndString}`,
        json: true, 
        method: "GET"
    }).then(response => {
        const taskSeriesWithIcons = response.map((item) => {
            const iconUrl = icons.filter(i => item.title === i.name )
            if (iconUrl.length > 0) {
                item.iconUrl = iconUrl[0].url
            } else {
                item.iconUrl = start_icon
            }
            return item
        })
        return Promise.resolve(taskSeriesWithIcons)
    })
}

export const getTaskPageTaskSeries = async (mission_uuid, today, weeksLater) => {
    return await makeRequest({
        uri: `${urlPrefix}/task/timeslots?mission_uuid=${mission_uuid}&weekStart=${today}&weekEnd=${weeksLater}`,
        json: true,
        method: "get"
    })
}

export const createBooking = async (
    date, 
    user_uuid, 
    series_id, 
    note = "No additional notes"
    ) => {
    return makeRequest({
        uri: `${urlPrefix}/task/assign`,
        json: true,
        method: "POST",
        body: {
            date: date,
            user_uuid: user_uuid,
            series_id: series_id,
            note: note
        }
    }).then((data) => {
        return Promise.resolve(data.rows)
    }).catch(error => {
        return Promise.reject(error)
    })
}

export const deleteBooking = async (booking) => {
    return makeRequest({
        uri: `${urlPrefix}/task/delete`,
        json: true,
        method: 'DELETE',
        body: {
            booking 
        }
    })
}

export const updateException = async (exception_id, type = "exclude") => {
    return makeRequest({
        uri: `${urlPrefix}/exception`,
        json: true,
        method: 'PUT',
        body: {
            exception_id: exception_id,
            type: type
        }
    })
}

export const addException = async (series_id, date, type, mission_uuid) => {
    return makeRequest({
        uri: `${urlPrefix}/exception`,
        json: true,
        method: 'POST',
        body: {
            type: type,
            series_id: series_id,
            date: date,
            mission_uuid: mission_uuid
        }
    })
}

export const getUserByEmail = async (_email) => {
    return makeRequest({
        uri: `${urlPrefix}/users/getByEmail`,
        method: "GET",
        qs: {
            email: _email
        },
        json: true
    })
}

export const updateTaskSeries = async (body) => {
    return makeRequest({
        uri: `${urlPrefix}/task`,
        json: true,
        method: "PUT",
        body: body
    })
}

export const deleteTaskSeries = async (id) => {
    return makeRequest({
        uri: `${urlPrefix}/task/deleteTaskSeries`,
        json: true,
        method: 'DELETE',
        body: {id}
    })
}

export const batchModifyTaskSeriesInCategories = async (crudData) => {
    return makeRequest({
        uri: `${urlPrefix}/task/update/batch`,
        json: true,
        method: `POST`,
        body: crudData
    });
};

export const updateSettings = async (body) => {
    return makeRequest({
        uri: `${urlPrefix}/mission`,
        json: true,
        method: "PUT",
        body: body
    })
}

export const getSettings = async (mission_uuid) => {
    console.log('get settings called in village.handler')
    return makeRequest({
        uri: `${urlPrefix}/wizard?mission_uuid=${mission_uuid}`,
        json: true,
        method: "GET"
    })
}

export const getMission = async (mission_uuid) => {
    return makeRequest({
        uri: `${urlPrefix}/mission?mission_uuid=${mission_uuid}`,
        json: true,
        method: 'GET'
    })
}

export const getBookings = async (user_uuid) => {
    return makeRequest({
        uri: `${urlPrefix}/bookings/user?user_uuid=${user_uuid}`,
        json: true,
        method: 'GET'
    })
}

export const fetchRecentNotes = async (date, seriesId) => {
    return makeRequest({
        uri: `${urlPrefix}/task/fetchRecentNotes?date=${date}&taskSeriesId=${seriesId}`,
        json: true,
        method: 'GET'
    })
}

export const sendEmailInvitations = async (data) => {
    return makeRequest({
        uri: `${urlPrefix}/mission/sendEmailInvitations`,
        json: true,
        method: 'post',
        body: data
    })
}

export const getInvitesAndMembers = async (mission_uuid) => {
    //console.log('making network request to get invites and members in village.handlers');
    return makeRequest({
        uri: `${urlPrefix}/mission/membershipData`,
        method: "GET",
        qs: {
            mission_uuid: mission_uuid
        },
        json: true,
    });
}

export const updateInvitesAndMembers = async (data) => {
    //console.log('making network request to update invites and members in village.handlers', data);

    return makeRequest({
        uri: `${urlPrefix}/membership`,
        method: "PUT",
        body:data,
        json: true,
    });
}