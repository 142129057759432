import React from 'react';
import { useFormik } from 'formik'
import { validationSchema, passwordRequirements } from '../../utils/validation';
import { ActivityIndicator } from '../general/ActivityIndicator';
import RequirementsGuide from './RequirementsGuide';
import FormField from './FormField';
import Button from '../Button';
import FlexForm from '../FlexForm';
import TermsAndConditions from '../TermsConditions';

function SignupForm({
    submitHandler,
    requestPending,
    checkUserExist,
    isHidden,
    prefill
}) {


    const initialValues = {
        name: "",
        email: (prefill && prefill.email) || "",
        password: "",
        isEmailEnabled: false
    }

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: initialValues,
        onSubmit: submitHandler,
        validationSchema: validationSchema
    })

    const handleEmailBlur = (e) => {
        formik.handleBlur(e)
        checkUserExist(e.target.value)
    }

    return (
    <FlexForm onSubmit={formik.handleSubmit} isHidden={isHidden}>
        <FormField
            config={{
                title: "Your name",
                touched: formik.touched.name,
                error: formik.errors.name,
                onBlur: formik.handleBlur,
                id: "name",
                name: "name",
                onChange: formik.handleChange,
                type: "text",
                value: formik.values.name
            }}
        />
        <FormField
            config={{
                title: "Your email",
                touched: formik.touched.email,
                error: formik.errors.email,
                onBlur: handleEmailBlur,
                id: "email_signup",
                name: "email",
                onChange: formik.handleChange,
                type: "email",
                value: formik.values.email
            }}
        />
        <FormField
            config={{
                title: "Password",
                touched: formik.touched.password,
                error: formik.errors.password,
                onBlur: formik.handleBlur,
                id: "password_signup",
                name: "password",
                onChange: formik.handleChange,
                type: "password",
                value: formik.values.password
            }}
        />
         <RequirementsGuide 
            requirements={passwordRequirements}
            title='Password requirements:'
            value={formik.values.password} 
            hidden={!(formik.touched.password || formik.values.password)}
        />
        <ActivityIndicator active={requestPending}>
            <Button type="submit" enabled={formik.isValid && !formik.isSubmitting}>
                Sign up
            </Button>
        </ActivityIndicator>
        <TermsAndConditions/>
    </FlexForm>
    )
}


export default SignupForm;