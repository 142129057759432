import { makeRequest } from './base.api';
import taskApi from './task.api';



const Status = {
    idle: "idle",
    loading: "loading",
    updated: "updated",
    success: "success",
    failure: "failure",
}

export {
    makeRequest,
    taskApi,
    Status
}
