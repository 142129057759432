import styled from 'styled-components';

export const RootContainer = styled.div`
    flex-grow: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const H2 = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 64px;
    color: #060368;
    font-weight: bold;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin: 50px;
    margin-top: 20px;

    @media(max-width: 450px){
        margin-top:0px; // wiz stepper provides ample margin
        font-size: 37px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0px;
    }
`

export const H4 = styled.div`
    font-size: 32px;
    color: #555555;
    font-style: italic;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 40px;
    text-align: center;
    margin: 10px 100px 10px 100px;
    max-width: 660px;

    @media(max-width: 450px){
        font-size: 24px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 11px;
        margin-bottom: 0px;
    }
`
export const H6 = styled.div`
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 27px;
    text-align: center;
    margin: 50px;
    max-width: 660px;

    @media(max-width: 450px){
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 29px;
        margin-bottom: 0px;
    }
`

export const ButtonStyled = styled.button`
    width: 225px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves}; 
    font-size: 21px;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin: 30px 0px 30px 0px;
    
    &:hover {
        cursor: pointer;
        background-color: #060368;
    },
    &:active: {
        border: 2px solid #ffffff
    },
    &:focus : {
        outline:none
    },
    &:disabled: {
        border: 1px solid #cccccc,
        background-color: #ffffff
    },

    @media(max-width: 450px){
        margin-top: 40px;
    }
`