import React, { useState, useRef, useEffect } from 'react';
import InlineTooltip from '../../../../shared-lib/components/general/InlineTooltip';
import { useAnalytics } from '../../../../analytics/AnalyticsContext';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.isSmall ? 'column' : 'row'};
    align-items: center;
	justify-content: center;
    padding: 20px 0px;
    padding-bottom: 20px;

`;

const LinkHolderTextFiled = styled.input`
    width: 100%;
    height: 45px;
    padding: 5px 10px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    border: black solid 1px;
    border-radius: 5px;
    box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);

    :focus {
        outline-width: 0;
    }
`;

const InputField = styled.div`    
    width: 100%;
    flex: 5;
`;

const CopyButton = styled.button`
    flex: 1;
    height: 47px;
    padding: 5px 30px;
    border-radius: 5px;
    border: none;
    background-color: var(--color-savo-purple);
    background-size: cover;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    font-family: ${props => props.theme.mrEaves};

    :disabled {
        background-color: #555555;
    }

    :hover{
        cursor: pointer;
        background-color: var(--color-dark-purple);
    }
`;

const ToolTipContainer = styled.div`
    flex: 1 1 0%;
    position: relative;
    width: ${props => props.isSmall ? '100%' : 'auto'}
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
`;

const LinkPanel = (props) => {

	const invitationUrl = props.urlToShare;
	const hasInvitationUrl = !!invitationUrl;
	const invitationUrlAsString = hasInvitationUrl ? invitationUrl : 'https://joinvillage.org';
	const [copied, setCopied] = useState(false);
    const input = useRef()
    const {isSmall} = props

    const { track, pageview } = useAnalytics();
    
    useEffect(() => {
        track("Page View", {"Page Name": "Invitations - Link"})
        pageview("/invitations/link",[], "Invitations - Link")
        // eslint-disable-next-line
	}, [])

	const copyToClipboard = () => {
		input.current.select();
		document.execCommand('copy');
		track("Invite People", {
			"Method": "Link" 
		})
		setCopied(true);
		const timeout = setTimeout(() => {
			setCopied(false);
			clearTimeout(timeout)
		}, 1500);
    };
    
    const buttonStyle = isSmall ? {marginTop: '29px', width: '100%'} : {marginLeft: '10px',  width: '140px'}

	return (
		<Container isSmall={isSmall}>
			<InputField>
				<LinkHolderTextFiled readOnly={true} value={invitationUrlAsString} ref={input} />
			</InputField>
			<ToolTipContainer isSmall={isSmall}>
				<CopyButton disabled={!hasInvitationUrl} onClick={copyToClipboard} style={buttonStyle}>Copy</CopyButton>
				<InlineTooltip hidden={!copied} top='40px' left='35px' position='absolute'>Copied!</InlineTooltip>
			</ToolTipContainer>
		</Container>
	);
};

export default LinkPanel;
