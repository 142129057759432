import styled from 'styled-components';

export const RootContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 50px 50px 50px;
    text-align: center;
    flex-grow: 1;

    @media(max-width: 675px) {
        margin-left: 20px;
        margin-right: 23px;
    }
`

export const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
`

export const Title = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 64px;
    color: #060368;
    font-weight: bold;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin-bottom: 25px;

    @media(max-width: 675px) {
        font-size: 29px;
        margin-top:30px;
    }
`


export const Subtitle = styled.div`
    margin-bottom: 25px;
    font-size: 26px;
    font-style: italic;
    color: #555555;
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 32px;
    text-align: center;

    @media(max-width: 675px) {
        margin-top:24px;
    }

`
export const PurpleText = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 42px;
    color: #69489d;
    font-weight: bold;
    text-decoration: none solid rgb(105, 72, 157);
    text-align: center;
    margin-bottom: 20px;

`

export const SmallText = styled.div`
    margin-bottom: 30px;
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 26px;
    text-align: left;
    font-family: ${props => props.theme.mrsEaves};
`

export const SectionHeader = styled.div`
    margin-bottom: 15px;
    font-family: ${props => props.theme.mrEaves};
    font-size: 36px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);

    @media(max-width: 675px) {
        font-size: 29px;
    }
`

export const Button = styled.button`
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
`