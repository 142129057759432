import React from 'react';
import styled from 'styled-components';

const TermsAndConditions = styled.div`
    width: 100%;
    font-family: var(--font-mrsEaves); 
    font-size: 14px;
    color: #6e6e6e;
    text-decoration: none solid rgb(110, 110, 110);
    line-height: 20px;
    font-style: italic;
    align-self: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    & a {
        color: #69489d;
    }
`

const Index = () => {
  return (
  <TermsAndConditions>
    By providing your email, you agree to receive emails about Savo. <br/>
    By creating an account, you agree to the 
    <a href="https://www.aarp.org/about-aarp/privacy-policy/" target="_blank">
        Privacy policy
    </a> and <a href="https://www.aarp.org/about-aarp/terms-of-service/" target="_blank">Terms of Use</a>.
  </TermsAndConditions>)
}

export default Index;