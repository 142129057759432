import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  font-family: var(--font-mrEaves);
  font-size: 42px;
  color: #060368;
  text-decoration: none solid rgb(6, 3, 104);
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 400px;
  padding-left:20px;
  padding-right:20px;

  @media (max-width: 414px) {
      font-size: 37px;
  }
`

export const Subtitle = styled.div`
  font-family: var(--font-mrsEaves);
  font-size: 18px;
  color: #111111;
  font-weight: 400;
  max-width: 375px;
  height: auto;
  align-self: center;
  text-decoration: none solid rgb(17, 17, 17);
  line-height: 26px;
  display: ${(props) => props.show ? 'block' : 'none'};
  text-align: center;

  @media (max-width: 414px) {
      line: 37px;
  }
`

export const Instruction = styled.p`
    margin-top: 47px;
    margin-bottom: 0px;
    font-family: var(--font-mrEaves);
    font-size: 36px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
`
export const InstructionDetail = styled.p`
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #111111;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 26px;
    text-align: center;
`
