
import { makeRequest } from '../api';
import { titles, categories } from '../../shared-lib/utils/taskSeries';
import env from '../../.env';
import { defaultRecurringPeriod, defaultRecurringDays, makeTask } from '../api/task.api'
const urlPrefix = env.API_URL;




export const batchCreateTask = async (mission_uuid, taskSeriesList) => {
    const postBody = {
        mission_uuid,
        taskSeriesList
    }
    const response = await makeRequest({
        uri: `${urlPrefix}/task/batch`,
        json: true,
        method: "POST",
        body: postBody
    })
    return response
}

export const saveWizardState = async (body) => {
    return makeRequest({
        uri: `${urlPrefix}/wizard`,
        json: true,
        method: "POST",
        body: body
    })
}


export function generateTasks(wizardInfo){
   
    const { relatives, tasksSelected } = wizardInfo
    const petTasks = generatePetTasks(relatives)
    const emotionalTasks = generateEmotionalTasks(relatives)
    const choreTasks = generateChoreTasks(tasksSelected)

    return[...petTasks, ...emotionalTasks, ...choreTasks]
}

function generatePetTasks(relatives) {
    var tasks = [];
        //dog: 7 days a week, cat: 3 days a week
    if (relatives.some(relative => relative != null && relative.relationship === "Dog")){
        tasks.push(makeTask(null, titles.petcare, categories.care, defaultRecurringDays('Petcare'), defaultRecurringPeriod('Petcare')))
    } else if (relatives.some(relative => relative != null && relative.relationship === "Cat")){
        tasks.push(makeTask(null, titles.petcare, categories.care, defaultRecurringDays('PetcareCat'), defaultRecurringPeriod('PetcareCat')))
    }
    return tasks
}

function generateEmotionalTasks(relatives) {
    var tasks = [];
    const people = ["Spouse or Partner", "Child", "Grandchild", "Parent", "Brother", "Sister", "Friend", "In Law", "Other"]
    const nonPetRelatives = relatives.filter(relative => relative != null && people.includes(relative.relationship))
    if ( nonPetRelatives.length > 0 ){
        if (nonPetRelatives.filter(nonPetRelative => nonPetRelative != null && nonPetRelative.age <18).length > 0) {
            tasks.push(makeTask(null, titles.childcare, categories.care, defaultRecurringDays('Childcare'), defaultRecurringPeriod('Childcare')))
        }
        if (nonPetRelatives.filter(nonPetRelative => nonPetRelative != null && nonPetRelative.age >=65).length > 0) {
            tasks.push(makeTask(null, titles.eldercare, categories.care, defaultRecurringDays('Eldercare'),defaultRecurringPeriod('Eldercare')))
        }
    }
      //frequency change for meals after one month is happening in the backend (queryTaskSeriesHandler function)
      tasks.push(
        makeTask(null, titles.activity, categories.emotion, defaultRecurringDays('Activity'), defaultRecurringPeriod('Activity')),
        makeTask(null, titles.checkin, categories.emotion, defaultRecurringDays('Check In'), defaultRecurringPeriod('Check In')),
        makeTask(null, titles.companionship, categories.emotion, defaultRecurringDays('Companionship'), defaultRecurringPeriod('Companionship')),
        makeTask(null, titles.financeCoaching, categories.emotion, defaultRecurringDays('Finance Coaching'), defaultRecurringPeriod('Finance Coaching')),
        makeTask(null, titles.meals, categories.food, defaultRecurringDays('Meals'), defaultRecurringPeriod('Meals'))

    )
    return tasks
}

function generateChoreTasks(tasksSelected) {
    var tasks = [];
    if (tasksSelected.includes("Cleaning bathrooms")){
        tasks.push(makeTask(null, titles.bathrooms, categories.cleaning, defaultRecurringDays('Bathrooms'), defaultRecurringPeriod('Bathrooms')))
    }
    if (tasksSelected.includes("Washing dishes")){
        tasks.push(makeTask(null, titles.dishes, categories.cleaning, defaultRecurringDays('Dishes'), defaultRecurringPeriod('Dishes')))
    }
    if (tasksSelected.includes("Lawn care")){
        tasks.push(makeTask(null, titles.lawncare, categories.cleaning, defaultRecurringDays('Lawn Care'), defaultRecurringPeriod('Lawn Care')))
    }
    if (tasksSelected.includes("Tidying up")){
        tasks.push(makeTask(null, titles.tidyingUp, categories.cleaning, defaultRecurringDays('Tidying Up'), defaultRecurringPeriod('Tidying Up')))
    }
    if (tasksSelected.includes("Laundry")){
        tasks.push(makeTask(null, titles.laundry, categories.cleaning, defaultRecurringDays('Laundry'), defaultRecurringPeriod('Laundry')))
    }
    if (tasksSelected.includes("Shopping")){
        tasks.push(makeTask(null, titles.groceries, categories.food,  defaultRecurringDays('Groceries'), defaultRecurringPeriod('Groceries')))
    }
    return tasks
}
