import React from 'react';
import { useUser } from '../../auth/UserContext';
import styled from 'styled-components';
import { villageIcon } from '../../shared-lib/assets/svgIcons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AddButton from '../../shared-lib/components/AddButton';

const VillageCard = styled.a`

    width: 325px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    border: 1px solid #a694c7;
    border-radius: 5px;
    padding: 10px;

    img {
        margin: 10px
    }

    & h6 {
        color: #69489d;
        font-size: 26px;
        font-family: var(--font-mrEaves)
    }

    & p {
        font-family: var(--font-mrsEaves);
        font-size: 18px;
        color: #555555;
        margin: 5px 0px;
    }

    & .title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start
        margin: 0px 10px;
    }
`

const NoOrganizer = styled.div`
    & p {
        text-align: center;
        color: #111111;
        font-size: 18px;
        margin: 10px;
        font-style: italic;
    }

    & h6 {
        font-family: var(--font-mrEaves);
        font-size: 36px;
        color: #060368;
        text-align: center;
        margin: 40px 0px 0px 0px;
    }
`

const convertDate = (dateString) => {
    const date = moment(dateString)

    return date.format("MMMM D, YYYY").toString()
}
function ManageVillagePage() {

    const { roles, changeMissionHandler } = useUser()
    
    const history = useHistory()

    const handleMissionClick = (item) => {
        changeMissionHandler(item.mission_uuid, item.person_passed)
    }
    const orgRoles = roles.filter(i => i.role === "org" || i.role === "rec")
    const isEmptyOrg = orgRoles.length === 0;

    const handleAddMission = () => {
        history.push(`/wizard`)
    }

    return (<div className="villages-root">
        <ul className="villages-list">
        {
            isEmptyOrg ?
            <NoOrganizer>
                <h6>No villages available</h6>
                <p>You have not created any villages yet.</p>
            </NoOrganizer>
            :
            orgRoles.map((item, idx) => {
                return (
                <li className="villages-list-item"
                    key={idx}
                >
                    <VillageCard id={idx} href="#" onClick={() => handleMissionClick(item)}>
                        <img src={villageIcon} alt="villge-icon" />
                        <div className="title-wrapper">
                            <h6>{item.person_passed} Family</h6>
                            <p>Created {convertDate(item.creation_date)}</p>
                        </div>
                    </VillageCard>
                 </li>)
            })
        }
        </ul>
        <AddButton onClick={handleAddMission}>Create a new village</AddButton>
    </div>)
}

export default ManageVillagePage;