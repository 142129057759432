import auth0 from 'auth0-js';

class Auth0Wrapper {

    __auth0Client;
    __connection;

    constructor(config, connection) {
        this.__auth0Client = new auth0.WebAuth(config);
        this.__connection = connection;
    }

    login = () => {
        this.__auth0Client.authorize()
    }

    logout = (returnTo) => {
        this.__auth0Client.logout();
    }

    getUserInfo = async (accessToken) => {
        return new Promise((resolve, reject) => {
            this.__auth0Client.client.userInfo(accessToken, (error, userInfo) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(userInfo);
                }
            });
        });
    }

    handleParseHash = async () => {
        return new Promise((resolve, reject) => {
            this.__auth0Client.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken) {
                    resolve(authResult);
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    handleCheckSession = async () => {
        return new Promise((resolve, reject) => {
            this.__auth0Client.checkSession({}, (err, authResult) => {
                if (authResult && authResult.accessToken) {
                    resolve(authResult);
                } else if (err) {
                    this.logout(`${window.location.origin}?logout=true&mission_uuid=none`);
                    reject(err);
                }
            });
        });
    }

    setupAuthSession = async (authResult) => {
        if (!authResult || !authResult.accessToken) {
            throw new Error('No AccessToken');
        }
        const userInfo = await this.getUserInfo(authResult.accessToken);
        const session = this.composeSessionObject(authResult, userInfo);
        return session;
    }

    loginWithCredential = async (credentials, cb) => {
        credentials.realm = this.__connection;
        this.__auth0Client.login(credentials, cb)
    }

    signup = async (options, cb) => {
        options.connection = this.__connection;
        this.__auth0Client.signup(options, cb);
    }

    silentAuthenticate = async (sessionState) => {
        const _nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        return new Promise((resolve, reject) => {
            this.__auth0Client.checkSession({
                responseMode: "web_message",
                state: sessionState,
                nonce: _nonce,
                prompt: "none",
            }, (err, authResult) => {
                if (authResult && authResult.accessToken) {
                    resolve(authResult);
                } else if (err) {
                    this.logout(`${window.location.origin}`);
                    reject(err);
                }
            });
        })
    }
    
    signupAndAuthorizeNewUser = (email, password) => {
        const authPayload = {
          email,
          password,
          connection: this.__connection,
        };
      
        return new Promise((resolve, reject) => {
          this.__auth0Client.signupAndAuthorize(authPayload, (error, authResult) => {
            if (error) {
              reject(error);
              return;
            }
  
            if (!authResult) {
              reject(new Error(`New user Signup error - there is no AuthResult`));
              return;
            }
  
            if (!authResult.accessToken) {
              reject(new Error(`New user Signup error, cant Authorize new user - there is no access token returned`));
              return;
            }
            
            this.__authResult = authResult
            resolve(authResult);
          });
        })
      };
}

export default Auth0Wrapper;
