import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButtonSmall from '../../../../shared-lib/components/IconButtonSmall'


const Container = styled.div`
    height: 45px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 0px 2px 1px rgba(56,38,84,0.2);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 10px;
    width: 100%;
`

const ExampleTextField = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    margin: 0 10px;
    font-size: 21px;
    font-family: var(--font-mrEaves);

`

const NewTaskField = ({ idx, field, handleAddTaskInput, onAddTaskRemove }) => {
    return(
        <Container>
            <ExampleTextField 
                placeholder="Enter new task"
                value={field.title || ''}
                onChange={event => handleAddTaskInput(idx, event)} />
            <IconButtonSmall onClick={() => onAddTaskRemove(idx)} icon='close'/>
        </Container>
    );
}

NewTaskField.propTypes = {
	idx: PropTypes.number.isRequired,
	field: PropTypes.object.isRequired,
	handleAddTaskInput: PropTypes.func.isRequired,
	onAddTaskRemove: PropTypes.func.isRequired,
}

export default NewTaskField;