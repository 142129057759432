import React, { useState, useMemo } from 'react';
import { icons, categories, titles } from '../../../../shared-lib/utils/taskSeries';
import * as selectors from '../../../../shared-lib/utils/selectors';
import SmallAddTaskIconComponent from '../../../Share/SmallAddTaskIconComponent';
import TaskToSelectComponent from '../../../Share/TaskToSelectComponent'
import NewTaskField from '../NewTaskField'
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { modifyTasksSeriesCategories } from '../../../../data/actions';
import { useUser } from '../../../../auth/UserContext';
import Modal from '../../../../shared-lib/components/general/Modal';
import { useAnalytics } from '../../../../analytics/AnalyticsContext';


const Title = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    position: relative;
    height: 46px;
    font-family: var(--font-mrEaves);
    font-size: 42px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    font-weight: bold;
`

const SubTitle = styled.div`
    height: 26px;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #111111;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 26px;
    text-align: center;
` 
const CategoryTitle = styled.div`
    height: 40px;
    font-family: var(--font-mrEaves);
    font-size: 36px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    margin-bottom: 5px;
    margin-top: 25px;
`
const CancelButton= styled.div`
    minWidth: 100;
    border-width: 2px;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves}; 
    max-width: 140px;
    min-width: 100px
    height: 47px;
    border-radius: 5px;
    background-size: cover;
    font-weight: bold;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    background-color: #ffffff;
    border: solid #69469d 2px;
    color: #69469d;
    margin: 80px 20px 30px 20px;

    &:hover {
        cursor: pointer;
        color: #060368;
    }
`

const SaveButton = styled.div`
    min-width: 100;
    background-color: #69469d;
    color: white;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves};
    max-width: 140px;
    min-width: 100px
    height: 47px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    border: solid #69469d 2px;
    margin: 80px 20px 30px 20px;
    font-weight: bold;

    &:hover {
        cursor: pointer;
        background-color: #060368;
    }
`

const AddTaskContent = (props) => {
	
    const { closeHandler, modifyTasksSeriesCategories, categoriesData } = props;
    const { membership } = useUser();
    const { mpTrackTaskSeries } = useAnalytics()

    const initialState = useMemo(() => {
        const otherTaskSeries = categoriesData.other.taskSeries || [];
        return otherTaskSeries.map(item => ({ seriesId: item.series_id, title: item.title  }));
    }, [categoriesData]);
		
	const [newTaskFields, setNewTaskFields] = useState(initialState);
    const [selectedTaskFields, setSelectedTaskFields] = useState([]);

    const handleAddTaskInput = (i, event) => {
		const values = [...newTaskFields];
        values[i].title = event.target.value;
        setNewTaskFields(values);
    }

    const onAddTaskClick = () => {
        const values = [...newTaskFields];
        values.push({ seriesId: null, title: null });
        setNewTaskFields(values);
    }

    const onAddTaskRemove = (i) => {
        const values = [...newTaskFields];
        values.splice(i, 1);
        setNewTaskFields(values);
	}   
		
    const collectOtherTaskSeriesData = () => {
        const notEmptyItems = newTaskFields.filter(item => item && item.title);
        return {
            items: notEmptyItems
        };
    };
    
    const handleSelect = (category, title, checked) => {
        const task = categoriesData[category].find(i => i.name === title)
        const isExist = selectedTaskFields.find(i => i.name === title)
        if (task.taskSeries === undefined) {
            if (checked) {
                if (!isExist) {
                    const selected = {...task, method: 'create'}
                    setSelectedTaskFields([...selectedTaskFields, selected])
                } else {
                    // no action needed because it already exist in selectedTaskFields
                }
            } else {
                if (isExist) {
                    const filtered = selectedTaskFields.filter(i => i.name !== title)
                    setSelectedTaskFields(filtered)
                } else {
                    // don't remove taskSeries because it doesn't exist
                }
            }
        }

        if (task.taskSeries !== undefined) {
            if (checked) {
                if (isExist) {
                    const filtered = selectedTaskFields.filter(i => i.name !== title)
                    setSelectedTaskFields(filtered)
                } else {
                    // don't remove because it is not in crud
                }
            } else {
                if (!isExist) {
                    const selected = {...task, method: 'delete'}
                    setSelectedTaskFields([...selectedTaskFields, selected])
                } else {
                    // n/a
                }
            }
        }

    }
    
    const diffObjectArray = (A, B) => {
        var createArray = [];
        
        const matchedArray = B
            .filter(b => {
                const match = A.find(a => a.title === b.title);
                if (match) {
                    return true
                } else {
                    createArray.push({...b, method: "create"})
                    return false
                }
            })
        
        const deleteArray = A
            .filter(a => {
                const match = matchedArray.find(i => i.title === a.title)
                return match ? false : true
            }).map(d => {
                d["method"] = "delete"
                return d
            })

        return [...createArray, ...deleteArray]
    }

    const normalizeUpdateData = (dataToSave) => {

        const otherA = categoriesData && categoriesData.other
        const A = otherA && otherA.taskSeries
        
        const otherB = dataToSave && dataToSave.other
        const B = otherB && otherB.items

        const otherDiff = diffObjectArray(A, B)
        const mainDiff = dataToSave.selectedTaskFields.map(i => {
            return {...i, ...i.taskSeries}
        })

        return [
            ...mainDiff, 
            ...otherDiff
        ]
    }

    const handleSave = () => {
        const { mission_uuid } = membership;
        const dataToSave = {
            selectedTaskFields: selectedTaskFields,
            other: collectOtherTaskSeriesData()
        };
        
        // for mixpanel tracking only
        const normalized = normalizeUpdateData(dataToSave)
        mpTrackTaskSeries(normalized, "create/delete")

        modifyTasksSeriesCategories(mission_uuid, dataToSave);
        closeHandler();
    };

    return (
    <React.Fragment> 
        <Title>Select tasks</Title>
        <SubTitle>Add or remove tasks from the calendar.</SubTitle>
        <div id="add-modal-layout">
        <div className="add-modal-tasks">
        <CategoryTitle>Care</CategoryTitle>
        {categoriesData["care"].map((careItem, idx) => (
            <TaskToSelectComponent
                key={idx}
                taskSeries={careItem.taskSeries}
                title={careItem.name}
                svgUrl={careItem.icon}
                category={careItem.category}
                handleSelect={handleSelect} 
                            handleSelect={handleSelect} 
                handleSelect={handleSelect} 
            />
        ))}
        </div>
        <div className="add-modal-tasks">
        <CategoryTitle>Cleaning</CategoryTitle>
        {categoriesData["cleaning"].map((cleanItem, idx) => (
            <TaskToSelectComponent 
                key={idx}
                taskSeries={cleanItem.taskSeries} 
                title={cleanItem.name} 
                svgUrl={cleanItem.icon}
                category={cleanItem.category}
                handleSelect={handleSelect}
            />
        ))}
        </div>
        <div className="add-modal-tasks">
        <CategoryTitle>Emotional</CategoryTitle>
        {categoriesData["emotion"].map((emotionItem, idx) => (
            <TaskToSelectComponent
                key={idx}
                taskSeries={emotionItem.taskSeries}
                title={emotionItem.name}
                svgUrl={emotionItem.icon}
                category={emotionItem.category}
                handleSelect={handleSelect}
            />
        ))}
        </div>
        <div className="add-modal-tasks">
        <CategoryTitle>Food</CategoryTitle>
        {categoriesData["food"].map((foodItem, idx) => (
            <TaskToSelectComponent
                key={idx}
                taskSeries={foodItem.taskSeries} 
                            taskSeries={foodItem.taskSeries} 
                taskSeries={foodItem.taskSeries} 
                title={foodItem.name} 
                            title={foodItem.name} 
                title={foodItem.name} 
                svgUrl={foodItem.icon}
                category={foodItem.category}
                handleSelect={handleSelect}
            />
        ))}
        </div>
        </div>
        <CategoryTitle>Other</CategoryTitle>
        {newTaskFields.map((field, idx) => ( 
                <NewTaskField 
                    key={`${field}-${idx}`}
                    idx={idx} 
                    field={field} 
                    handleAddTaskInput={handleAddTaskInput} 
                    onAddTaskRemove={onAddTaskRemove} /> 
        ))}
	    <SmallAddTaskIconComponent title="Add task" onClick={() => onAddTaskClick()}/>
        <div className="flex-row flex-center">
            <CancelButton onClick={closeHandler}>Cancel</CancelButton>
            <SaveButton onClick={handleSave}>Save</SaveButton>
        </div>
    </React.Fragment>);
}

const AddTaskModalDialog = ({ isOpen, ...rest }) => {
	return (
		<Modal open={isOpen} handleClose={rest.closeHandler} closable={false}>
			<AddTaskContent {...rest} />
		</Modal>
	);
};

AddTaskModalDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeHandler: PropTypes.func.isRequired,
	modifyTasksSeriesCategories: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		categoriesData: {
            care: [	
                {
                    name: titles.eldercare, 
                    icon: icons.eldercare, 
                    category: categories.care,
                    taskSeries: selectors.selectEldercare(state)
                }, 
                {
                    name: titles.petcare, 
                    icon: icons.petcare, 
                    category: categories.care,
                    taskSeries: selectors.selectPetcare(state)
                },
                {
                    name: titles.childcare, 
                    icon: icons.childcare, 
                    category: categories.care,
                    taskSeries: selectors.selectChildcare(state)
                }, 
            ],
            emotion: [
                {
                    name: titles.activity, 
                    icon: icons.activity, 
                    category: categories.emotion,
                    taskSeries: selectors.selectActivity(state)
                }, 
                {
                    name: titles.checkin, 
                    icon: icons.checkIn, 
                    category: categories.emotion,
                    taskSeries: selectors.selectCheckin(state)
                }, 
                {
                    name: titles.companionship, 
                    icon: icons.companionship, 
                    category: categories.emotion,
                    taskSeries: selectors.selectCompanionship(state)
                },
                {
                    name: titles.financeCoaching, 
                    icon: icons.financeCoaching, 
                    category: categories.emotion,
                    taskSeries: selectors.selectFinanceCoaching(state)
                }
            ],
            cleaning: [
                {
                    name: titles.bathrooms, 
                    icon: icons.bathrooms, 
                    category: categories.cleaning,
                    taskSeries: selectors.selectBathrooms(state)
                }, 
                {
                    name: titles.dishes, 
                    icon: icons.dishes, 
                    category: categories.cleaning,
                    taskSeries: selectors.selectDishes(state)
                }, 
                {
                    name: titles.laundry, 
                    icon: icons.laundry, 
                    category: categories.cleaning,
                    taskSeries: selectors.selectLaundry(state)
                },
                {
                    name: titles.lawncare, 
                    icon: icons.lawnCare, 
                    category: categories.cleaning,
                    taskSeries: selectors.selectLawnCare(state)
                },
                {
                    name: titles.tidyingUp, 
                    icon: icons.tidyingUp,
                    category: categories.cleaning,
                    taskSeries: selectors.selectTidyingUp(state)
                }
            ],
            food: [
                {
                    name: titles.groceries, 
                    icon: icons.groceries, 
                    category: categories.food,
                    taskSeries: selectors.selectGroceries(state)
                }, 
                {
                    name: titles.meals, 
                    icon: icons.meals, 
                    category: categories.food,
                    taskSeries: selectors.selectMeals(state)
                },
            ],
			other: {
                name: titles.other,
                category: categories.other,
				taskSeries: selectors.selectOther(state)
			},
        }
	};
};

const mapDispatchToProps = { 
	modifyTasksSeriesCategories 
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModalDialog);
