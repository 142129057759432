import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 45px;
    width: 100%;
`

const SectionHeader = styled.label`
    font-family: var(--font-mrEaves);
    font-size: 31px;
    color: var(--color-savo-purple);
    font-weight: bold;
    text-align: center;
`

const SectionContentWrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.isSmall ? 'column' : 'row'};
    width: 100%;
    justify-content: space-evenly;
`

const Table = styled.table`
    display: ${props => props.hidden ? 'none' : 'table'};
    border-radius: 5px;
    margin-top:10px;
    border-spacing: 0px;
`

const StatusLabel = styled.thead`
    & td {
        font-family: var(--font-mrEaves);
        font-size: 21px;
        color: #555555;
        font-weight: bold;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
`
const TableHeader = styled.thead`
    height: 45px;
    width: 100%;
    border-color: var(--color-dark-purple);
    background-color: var(--color-dark-purple);
    
    & td {
        display: table-cell;
        padding: 5px 10px; 
        color: #ffffff;
        font-size:21px;
        font-family: var(--font-mrEaves);
    }
`

const TableRow = styled.tbody`
    height: 45px;
    width: auto;
    border: 1px solid #cccccc;
    
`

const LD = styled.td`
    padding: 5px 10px;
    width: auto;
    color: #555555;
    font-size:21px;
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    font-family: var(--font-mrEaves);
    border-bottom-left-radius:${props => props.isLast ? '5px' : '0px'};
    box-shadow: ${props => props.isLast ? '0px 1px 2px rgba(0,0,0,0.2)' : 'none'};
    border-collapse: collapse;
    display: table-cell;

    & p {
        margin: 0px;
        overflow-wrap: break-word;
        max-width: 200px;
        display: block;
    }

    @media (max-width: 475px) {
        & p {
            max-width: 150px
        }
    }

    @media (max-width: 375px) {
        & p {
            max-width: 125px;
        }
    }
`

const RD = styled.td`
    padding: 5px 10px;
    width: auto;
    color: #555555;
    font-size:21px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    font-family: var(--font-mrEaves);
    border-bottom-right-radius:${props => props.isLast ? '5px' : '0px'};
    box-shadow: ${props => props.isLast ? '0px 1px 2px rgba(0,0,0,0.2)' : 'none'};
    border-collapse: collapse;
    display: table-cell;

    & p {
        margin: 0px;
        overflow-wrap: break-word;
        max-width: 500px;
        display: block;
    }

    @media (max-width: 475px) {
        & p {
            max-width: 200px;
        }
    }

    @media (max-width: 375px) {
        & p {
            max-width: 150px;
        }
    }
`

const LRD = styled.td`
    padding: 5px 10px;
    width: auto;
    color: #555555;
    font-size:21px;
    border: 1px solid #cccccc;
    font-family: var(--font-mrEaves);
    border-bottom-left-radius:${props => props.isLast ? '5px' : '0px'};
    border-bottom-right-radius:${props => props.isLast ? '5px' : '0px'};
    box-shadow: ${props => props.isLast ? '0px 1px 2px rgba(0,0,0,0.2)' : 'none'};
    border-collapse: collapse;
    display: table-cell;

    & p {
        margin: 0px;
        overflow-wrap: break-word;
        max-width: 500px;
        display: block;
    }

    @media (max-width: 475px) {
        & p {
            max-width: 425px;
        }
    }

    @media (max-width: 375px) {
        & p {
            max-width: 325px;
        }
    }
`

const InviteListSection = ({item, isSmall}) => {
    const acceptedLength = item.accepted.length;
    const pendingLength = item.pending.length;

    return( 
        <Section hidden={acceptedLength === 0 && pendingLength === 0}>
        <SectionHeader>{item.title}</SectionHeader>
        <SectionContentWrapper isSmall={isSmall}>
        <Table style={{flexGrow:'4'}} >
        <StatusLabel><tr><td>Accepted</td></tr></StatusLabel>
            <TableHeader><tr><td style={{ borderTopLeftRadius: '5px', }}>Name</td><td style={{ borderTopRightRadius: '5px', borderLeft:'1px solid #ffffff'}}>Email</td></tr></TableHeader>
            {
            item.accepted.map((person, index) => {
                return <TableRow key={index}><tr><LD isLast={index + 1 === acceptedLength}><p>{person.name}</p></LD><RD isLast={index + 1 === acceptedLength}><p>{person.email}</p></RD></tr></TableRow>
            })}
        </Table>
        <div style={{flexGrow:'1'}}></div>
        <Table style={{flexGrow:'2'}} hidden={pendingLength === 0}>
        <StatusLabel><tr><td>Pending</td></tr></StatusLabel>
        <TableHeader><tr><td style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>Email</td></tr></TableHeader>
        {
            item.pending.map((person, index) => {
                return <TableRow key={index}><tr><LRD isLast={index + 1 === pendingLength}><p>{person.email}</p></LRD></tr></TableRow>
            })}
        </Table>
        </SectionContentWrapper>
    </Section>)
}

export default InviteListSection;