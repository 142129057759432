import React from 'react';
import styled from 'styled-components';


const ButtonRoot = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & svg {
        fill: #69469d;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #69469d;
    }

    & span {
        margin: 0px 10px;
        color: #69469d;
        font-size: 21px;
        font: var(--font-mrEaves);
    }

    & span:hover {
        color: #060368;
        cursor: pointer;
    }

    &:hover > svg {
        fill: #060368;
        border: 1px solid #060368;
    }
`

const AddButton = ({children, onClick}) => {


    return (
    <ButtonRoot onClick={onClick}>
            <svg
            overflow="visible" 
            preserveAspectRatio="none" 
            width="30" 
            height="30" 
            viewBox="0 0 24 24">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
            </svg>
        <span>{children}</span>
    </ButtonRoot>)
}

export default AddButton;