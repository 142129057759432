import React from 'react';
import styled from 'styled-components';

const backgroundErrorColor = 'rgba(199,107,68,.1)';
const backgrounRegularColor=  'rgba(145,183,122,.1)';

const BorderedBox = styled.div`
    width: 100%;
    min-width: 100%;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color:${(props) => props.hasError? '#c76b44' : '#91b77a'}; 
    background-color: ${(props) => props.hasError? backgroundErrorColor : backgrounRegularColor};
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: ${(props) => props.hasError? '#a95632' : '#5b7750'};
    text-decoration: none solid rgb(169, 86, 50);
    text-align: center;
    justify-content: center;
    align-content: center;
`

export const StatusBanner = (props) => {
    const {info, error} = props;
    const hidden = !(info || error);
    const message = info || error;
    const hasError = info == null;
    return( hidden ? null : 
        <BorderedBox hasError={hasError}>
            <p>{message}</p>
        </BorderedBox>
    )
}