import React, { useState } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../shared-lib/components/Checkbox';

const Container = styled.div`
    width: 225px;
    height: 60px;
    border: 2px solid #a694c7;
    border-radius: 5px;
    background-color: ${props => props.checked ? '#ebe9f4' : '#FFF'};
    background-size: cover;
    box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 12px;
    padding-right: 12px;

    &:hover {
        cursor: pointer;
    }
`

const RightIcon = styled.img`
    height: 35px
`

const Title = styled.div`
    font-family: ${props => props.theme.mrEaves};
    font-size: 24px;
    color: #69489d;
    text-decoration: none solid rgb(105, 72, 157);
    flex: 1;
    font-weight: bold;
    width: 100%;
    text-align: left;
    margin-left: 5px;
    margin-right: 5 px;
`

function TaskToSelectComponent({
    key,
    title,
    category,
    svgUrl,
    taskSeries,
    handleSelect
}) {

    const initCheck = taskSeries === undefined ? false : true;
    const [checked, setChecked] = useState(initCheck)

    const selectHandler = () => {
        handleSelect(category, title, !checked)
        setChecked(!checked)
    }

    const checkBGColor = checked ? '#69469d' : '#FFF';
    const checkBorderColor =  checked ?'#FFFFFF' : '#69469d';

    return(
        <Container id="task-select" key={key} onClick={selectHandler} checked={checked}>
            <Checkbox checked={checked} backgroundColor={checkBGColor} borderColor={checkBorderColor}/>
            <Title>{title}</Title>
            <RightIcon src={svgUrl} alt="RightIcon" />
        </Container>
    )
}

export default TaskToSelectComponent;