import { useState, useEffect } from "react";
import { makeRequest } from "../../data/api";
import env from "../../.env.js";
import { useUser } from "../../auth/UserContext";
import { getInviteUuid, getMissionUuid, getRole } from "../../shared-lib/utils/url_util";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

export const useInvite = () => {

  const location = useLocation();
  const inviteUuid = location ? getInviteUuid(location.search) : null;
  const missionUuid = location ? getMissionUuid(location.search) : null;
  const oldInviteRole = location ? getRole(location.search) : null
  const personPassedFromWizard = useSelector(state => state.WizardReducer.step3.name);
  const taskSelectedFromWizard = useSelector(state => state.WizardReducer.step4);
  const relativesFromWizard = useSelector(state => state.WizardReducer.step3.familyMembers);

  const { checkUserMembership } = useUser();
  const [fetchingInvite, setFetchingInvite] = useState(true)
  const [invite, setInvite] = useState({
    role: "org",
    mission_uuid: null,
    person_passed: null,
    status: "pending",
    inviteUuid: null
  });

  useEffect(() => {
    const getInviteInfo = async (inviteUuid=null, missionUuid=null) => {
      const data = await makeRequest({
        uri: `${env.API_URL}/mission/getInviteInfo?inviteUuid=${inviteUuid}&missionUuid=${missionUuid}`,
        method: "get",
        json: true,
      });
      const missionInfo = data && data.mission
      const inviteInfo = data && data.invite
      
      if ((inviteInfo && inviteInfo.status === "pending" && inviteInfo.role === "rec") || 
          (inviteInfo && inviteInfo.role === "cont") || 
          (missionInfo && missionInfo.mission_uuid && oldInviteRole !== "rec")
      ) {
        checkUserMembership(
          missionInfo ? missionInfo.mission_uuid : inviteInfo.mission_uuid, 
          missionInfo ? "cont" : inviteInfo.role, 
          missionInfo ? null : inviteInfo.invite_uuid
        );
      }
      setInvite({
        role: missionInfo ? "cont" : inviteInfo.role,
        mission_uuid: missionInfo ? missionInfo.mission_uuid : inviteInfo.mission_uuid,
        person_passed: missionInfo ? missionInfo.person_passed : inviteInfo.person_passed,
        status: missionInfo ? "pending" : inviteInfo.status,
        inviteUuid: missionInfo ? null : inviteInfo.invite_uuid,
        inviteEmail: inviteInfo && inviteInfo.email
      });
      setFetchingInvite(false)
    };

    if (inviteUuid || missionUuid) {
      getInviteInfo(inviteUuid, missionUuid).catch(error => console.error(error));
    } else {
      setFetchingInvite(false)
    }
    // eslint-disable-next-line
  }, [inviteUuid, missionUuid]);

  return {
    personPassed: invite.person_passed || personPassedFromWizard,
    missionUuid: invite.mission_uuid,
    role: invite.role,
    inviteStatus: invite.status,
    inviteUuid: invite.inviteUuid,
    tasksSelected: taskSelectedFromWizard,
    relatives: relativesFromWizard,
    fetchingInvite: fetchingInvite,
    inviteEmail: invite.inviteEmail,
    oldInviteRole: oldInviteRole || null
  };
};
