import React from 'react';
import VillageLogo from '../../assets/village-logo';
import Dropdown from '../../components/Dropdown';
import { useAnalytics } from '../../../analytics/AnalyticsContext';
import { useUser } from '../../../auth/UserContext';
const Menubar = ({
    hideAccount = false,
    history, 
    url,
    showAccountMenu,
    setShowAccountMenu
}) => {

    const { logoutUser } = useUser()
    const { mpTrackLogout } = useAnalytics()

    const handleTasks = () => {
        history.push(`${url}/dashboard/tasks`)
    }

    const handleManageVillage = () => {
        history.push(`${url}/dashboard/villages`)
    }

    const handleAccountSetting = () => {
        history.push(`${url}/dashboard/account`)
    }

    const handleLogout = () => {
        mpTrackLogout()
        logoutUser()
    }

    return (
    <div className="menubar-wrapper">
        <img src={VillageLogo} alt="logo" className="header-logo" onClick={handleTasks}/>
        { 
            hideAccount ?
            <div></div>
            :
            <Dropdown isShown={showAccountMenu} setIsShown={setShowAccountMenu}>
                <span onClick={handleTasks} href='tasks'>Upcoming Tasks</span>
                <span onClick={handleManageVillage} href='manage'>Manage Villages</span>
                <span onClick={handleAccountSetting} href='account'>Account Settings</span>
                <span onClick={handleLogout} href='logout'>Log out</span>
            </Dropdown>
        }
    </div>
    )
}

export default Menubar;
