import _ from 'lodash';

export const selectImportantDate = (state) => {

    const date_passed = { date: state.WizardReducer.step4.passDate, significance: "passed away" }
    const birthday = { date: state.WizardReducer.step4.birthDate, significance: "birthday" }

    const important_date_list = state.WizardReducer.step4.importantDateList.slice();
    if (date_passed.date) {
        important_date_list.push(_.cloneDeep(date_passed));
    }
    if (birthday.date) {
        important_date_list.push(_.cloneDeep(birthday));
    }
    
    return important_date_list           
}

export const selTaskSeries = (state) => {
    return state.VillageReducer.taskSeries;
}