import React, { 
    createContext, 
    useContext,
    useState,
    useEffect, 
    useMemo
} from 'react';
import {
    getUserByEmail,
    createMembership,
    databaseSignup,
    createMission,
    checkUserExist,
    getMembershipAndRoles,
    validateSignupArgs,
    saveSigninInputToLocal,
    saveSignupInputToLocal,
    resetPasswordHandler,
    changeMembership,
    getInviteAndMembershipData,
    updateUserInfo
} from './UserHandlers';
import { useAuth0 } from '../shared-lib/auth-provider';
import history from './history';
import {
    generateTasks,
    batchCreateTask,
    saveWizardState
} from '../data/middlewares/wizard.handlers';
import { 
    getLocalItem,
    setLocalItem,
    deleteLocalItem,
    USER,
    MEMBERSHIP,
    ROLES,
    SIGNUP_NEW_USER,
    SIGNIN_EXIST_USER,
} from '../shared-lib/utils/localStorage';
import _ from 'lodash';
import { useAnalytics } from '../analytics/AnalyticsContext';
import { createSharableContributorLink } from '../shared-lib/utils/url_util';

export const UserContext = createContext()
export const useUser = () => useContext(UserContext)

const localMembership = getLocalItem(MEMBERSHIP)
const localUser = getLocalItem(USER)
const localRoles = getLocalItem(ROLES)

export const UserProvider = ({ children }) => {

    const [membership, setMembership] = useState(localMembership)
    const [roles, setRoles] = useState(localRoles || [])
    const [user, setUser] = useState(localUser)
    const [isRequesting, setIsRequesting] = useState(false)
    const [networkError, setNetworkError] = useState()

    const { mpSetIdentity, mpTrackSignup, mpTrackLogin } = useAnalytics()

    const updateUser = (_user) => {
        setUser(_user)
        mpSetIdentity(_user, "user")
        return setLocalItem(USER, _user);
    }

    const updateMembership = (_membership) => {
        setMembership(_membership)
        mpSetIdentity(_membership, "membership")
        return setLocalItem(MEMBERSHIP, _membership);
    }

    const updateRoles = (_roles) => {
        setRoles(_roles)
        return setLocalItem(ROLES, _roles);
    }

    const { authSession, isAuthenticated, signup, loginWithCredential, clearAuthData, logout } = useAuth0()

    const changeMissionHandler = async (mission_uuid, person_passed, redirect = "calendar") => {
        const _membership = { ...membership, mission_uuid, person_passed }
        updateMembership(_membership)

        history.push(`/mission/${redirect}`)
    }

    const fetchUserAndRoles = async (email, mission_uuid) => {
        const _user = await getUserByEmail(email)
        const _roles = await getMembershipAndRoles(email)


        const missionIdx = mission_uuid ? _.findIndex(_roles, i => i.mission_uuid === mission_uuid) : 0
        const _membership = _.cloneDeep(_roles[missionIdx]);

        updateRoles(_roles);
        updateUser(_user)
        updateMembership(_membership);

        return {_roles, _user, _membership}
    }

    const redirectUserByRole = (role, isFromSignup, missionUuid = null) => {
        if (isFromSignup) {
            if (role === "org") {
                history.push(`/wizard/edit-task`, { missionUuid: missionUuid })
            } else if (role === "rec") {
                history.push(`/mission/calendar`, { missionUuid: missionUuid })
            } else {
                history.push(`/mission/task-page`)
            }
        } else {
            const lastUrlString = localStorage.getItem("last_url")
            const lastUrl = JSON.parse(lastUrlString)
            if (lastUrl) {
                updateMembership(lastUrl)
                localStorage.removeItem("last_url")
                history.push(`/mission/task-page`)
            } else {
                history.push(`/mission/dashboard/tasks`)
            }
        }
    }

    const getUserHandler = async (email) => {
        const signupInput = getLocalItem(SIGNUP_NEW_USER);
        const signinInput = getLocalItem(SIGNIN_EXIST_USER);
        try {
            setIsRequesting(true)
            if (signupInput) {
                deleteLocalItem(SIGNUP_NEW_USER)
                const {signupFunc} = SignUpFactory(signupInput)
                const {
                    role, 
                    mission_uuid,
                    email
                } = await signupFunc()
                await fetchUserAndRoles(email, mission_uuid);
                redirectUserByRole(role, true, mission_uuid)
            } else if (signinInput) {
                deleteLocalItem(SIGNIN_EXIST_USER)
                const {
                    role, 
                    mission_uuid, 
                    email
                } = await SignUpFactory(signinInput).signinFunc()
                await fetchUserAndRoles(email, mission_uuid);
                redirectUserByRole(role, true, mission_uuid)
            } else {
                await fetchUserAndRoles(email);
                redirectUserByRole(null, false)
            }
        } catch (error) {
            setNetworkError(error)
            deleteLocalItem(USER);
            deleteLocalItem(MEMBERSHIP);
            deleteLocalItem(ROLES)
            console.trace(error)
        } finally {
            setIsRequesting(false)
        }
    }

    const SignUpFactory = ({
        name, 
        email,
        auth0Id,
        isEmailEnabled=true,
        role,
        userExist,
        mission_uuid, 
        relatives,
        person_passed,
        tasksSelected,
        inviteStatus,
        inviteUuid=null
    }) => {

        const signupFunc = async () => {
            // 1. create user
            var user_uuid;
            if (userExist) {
                const userData = await getUserByEmail(email);
                user_uuid = userData && userData.user_uuid
            } else {
                user_uuid = await databaseSignup(email, name, isEmailEnabled, auth0Id, role)
            }

            // 2. create mission 
            var newMissionUuid = _.cloneDeep(mission_uuid);
            if (role === "org") {
                const missionData = await createMission(person_passed)
                newMissionUuid = missionData && missionData.mission_uuid;
            }

            // 3. create memebership
            await createMembership(newMissionUuid, user_uuid, role, inviteUuid)

            // 4. generate taskSeries
            if (role === "org") {
                await wizardFunc(newMissionUuid)
            }
            mpTrackSignup(user_uuid, role, isEmailEnabled)

            // 5. return the role for redirect
            return {role, mission_uuid: newMissionUuid, email}
        }

        const signinFunc = async () => {
            let newMissionUuid;
            if (role === "org") {
                const userData = await getUserByEmail(email);
                const user_uuid = userData && userData.user_uuid
                const missionData = await createMission(person_passed)
                newMissionUuid = missionData && missionData.mission_uuid;
                await createMembership(newMissionUuid, user_uuid, role, null)
                await wizardFunc(newMissionUuid)
            }

            if (role === "cont" || 
                role === "rec" && inviteStatus !== "accepted"
            ) {
                await memberFunc() // upgrade role if needed
            }
            
            mpTrackLogin(role)
            return {role: role, mission_uuid: newMissionUuid || mission_uuid, email: email}
        }

        const memberFunc = async () => {
            const _user = await getUserByEmail(email)
            const _roles = await getMembershipAndRoles(email);
            const filteredRole = _roles.filter(i => i.mission_uuid === mission_uuid)
            if (filteredRole.length < 1) {
                await createMembership(mission_uuid, _user.user_uuid, role, inviteUuid)
            } else if (filteredRole[0].role !== role) {
                // only allow promote contributor to recipient
                if (filteredRole[0].role === "cont" && role === "rec") {
                    await changeMembership(mission_uuid, _user.user_uuid, role, inviteUuid)
                }                
            }
            return {role, email, mission_uuid}
        }

        const wizardFunc = async (newMissionUuid) => {
            const taskSeriesList = generateTasks({relatives, tasksSelected})
            await batchCreateTask(newMissionUuid, taskSeriesList)
            await saveWizardState({
                mission_uuid: newMissionUuid, 
                person_passed, 
                family_members_of_passed: relatives,
            })
        }
        return {signupFunc, signinFunc, wizardFunc}
    }

    const signupNewUser = async (args) => {
        try {
            const { email, password, role} = args
            args.email = email.toLowerCase()
            validateSignupArgs(args, role)

            let auth0Id = null;

            if (!isAuthenticated) {
                const signupResult = await signup(email, password)
                auth0Id = `auth0|${signupResult.Id}`
                args["userExist"] = false
            } else {
                args["userExist"] = true
            }

            saveSignupInputToLocal(args, role, auth0Id)
            
            if (!isAuthenticated) {
                await loginWithCredential({email, password})
            } else {
                await getUserHandler(email)
            }
            return Promise.resolve(true)
        } catch(error) {
            setNetworkError(error)
            clearAuthData();
            deleteLocalItem(SIGNUP_NEW_USER);
            return Promise.reject(error);
        }
    }

    const createNewMissionForExistingUser = async (args) => {

        try {
            const user_uuid = isAuthenticated && user && user.user_uuid;

            if (!user_uuid) {
                throw(new Error("Signed in user required to use this method"));
            }

            const {person_passed} = args;
            const missionData = await createMission(person_passed)
            const newMissionUuid = missionData && missionData.mission_uuid;

            await createMembership(newMissionUuid, user_uuid, 'org', null)
            await SignUpFactory(args).wizardFunc(newMissionUuid)
            await fetchUserAndRoles(user.email, newMissionUuid);
            
            history.push(`/wizard/edit-task`, { missionUuid: newMissionUuid })

        } catch(error) {
            return Promise.reject(error); 
        }
        
    }

    const signinExistUser = async (args) => {
        const { 
            email, 
            password,
            role,
        } = args
        
        saveSigninInputToLocal(args, role)

        await loginWithCredential({
            email: email,
            password: password
        })
    }

    const checkUserMembership = async (_missionUuid, _role, inviteUuid) => {
        try {
            if (user && roles) {
                const user_uuid = user && user.user_uuid
                const email = user && user.email
                const missionRoles = roles.filter(i => i.mission_uuid === _missionUuid)
                const hasRole =  missionRoles.length > 0 
                const sameRole =  hasRole && missionRoles[0].role === _role
                setIsRequesting(true)
                if (hasRole && sameRole) { 
                    redirectUserByRole(_role, true, _missionUuid)
                    setIsRequesting(false)
                    return
                } else if (hasRole && !sameRole) {
                    // only allow promote contributor to recipient
                    if (missionRoles[0].role === "cont" && _role === "rec") {
                        await changeMembership(_missionUuid, user_uuid, _role, inviteUuid)
                    }                    
                } else {
                    await createMembership(_missionUuid, user_uuid, _role, inviteUuid)
                }
                const newRoles = await getMembershipAndRoles(email)
                const newMembership = newRoles.filter(i => i.mission_uuid === _missionUuid)[0]
                updateMembership(newMembership)
                updateRoles(newRoles)
                redirectUserByRole(_role, true, _missionUuid)
            }
        } catch (error) {
            console.error("checkUserMembership.error", error)
        } finally {
            setIsRequesting(false)
        }
    }

    const logoutUser = async () => {
        try {
            setIsRequesting(true)
            deleteLocalItem(USER)
            deleteLocalItem(MEMBERSHIP)
            deleteLocalItem(ROLES)
            await logout()
        } catch (error) {
            console.error("logout user error", error)
        } finally {
            setIsRequesting(false)
        }
    }

    const getContributorInviteLink = ({medium, source}) => {
        const { mission_uuid, person_passed } = membership
        return createSharableContributorLink({mission:mission_uuid, personPassed: person_passed, medium, source})
    }

    const resetPassword = async (email) => {
        try {
            const response = await resetPasswordHandler(email)
            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        } finally {

        }
    }

    useEffect(() => {
        if (isAuthenticated && authSession && !networkError) {
            const { userInfo } = authSession
            if (!membership && !isRequesting) {
                getUserHandler(userInfo.email)
            }
        }
    }, [
        isAuthenticated, 
        authSession, 
        isRequesting, 
        membership,
        networkError
    ])

    return <UserContext.Provider value={{
        user: user,
        membership: membership,
        roles: roles,
        checkUserExist: (e) => checkUserExist(e),
        checkUserMembership: (...e) => checkUserMembership(...e),
        signupNewUser: (...p) => signupNewUser(...p),
        signinExistUser: (...p) => signinExistUser(...p),
        logoutUser: (...p) => logoutUser(...p),
        changeMissionHandler: (...p) => changeMissionHandler(...p),
        createNewMissionForExistingUser: (...p) => createNewMissionForExistingUser(...p),
        getContributorInviteLink: (...p) => getContributorInviteLink(...p),
        resetPassword: (...p) => resetPassword(...p),
        updateUserInfo: (...p) => updateUserInfo(...p),
        fetchUserAndRoles: (...p) => fetchUserAndRoles(...p),
    }}>
        {children}
    </UserContext.Provider>
}