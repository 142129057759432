import { makeRequest } from '../data/api';
import rp from 'request-promise';
import env from '../.env.js';
import {INVITE_URL_ROOT_PATH} from '../shared-lib/utils/url_util'; 
import {
    SIGNIN_EXIST_USER,
    CHECK_MEMBERSHIP,
    setLocalItem,
    SIGNUP_NEW_USER,
} from '../shared-lib/utils/localStorage'
export const urlPrefix = env.API_URL
const UUID_CHAR_LENGTH = 36;

export const getUserByEmail = async (email) => {
    const _email = email.replace("+", "%2B")
    const data = await makeRequest({
        uri: `${urlPrefix}/users/getByEmail?email=${_email}`,
        method: "get",
        json: true
    })
    return data
}

export const checkUserExist = async (email) => {
    let uri = `${urlPrefix}/users/signup/check`
    const res = await rp({
        uri: uri,
        method: 'post',
        json: true,
        body: {
            email: email
        }
    })
    return res;
}

export const createMembership = async (mission_uuid, user_uuid, role = 'org', inviteUuid=null) => {

    const body = {
        mission_uuid,
        user_uuid,
        role,
        'status': 'active',
        inviteUuid
    }
    
    const data = await makeRequest({
        uri: `${urlPrefix}/membership`,
        method: 'post',
        json: true,
        body
    })
    return data
}

export const changeMembership = async (mission_uuid, user_uuid, role, inviteUuid=null) => {

    const body = {
        mission_uuid,
        user_uuid,
        role,
        inviteUuid
    }
    
    const data = await makeRequest({
        uri: `${urlPrefix}/membership`,
        method: 'put',
        json: true,
        body
    })
    return data
}

export const databaseSignup = async (email, name, allow_email=true, auth0_id = null, role = 'org') => {
    const data = await makeRequest({
        uri: `${urlPrefix}/users/signup`,
        json: true,
        method: "post",
        body: {
            name: name,
            auth0_id: auth0_id,
            email: email,
            allowEmail: allow_email, // default to true
            roles: [role]
        }
    })

    if (!data || !data[0] || !data[0].user_uuid) {
        return new Error("No User_UUID")
    }
    return data[0].user_uuid
}

export const createMission = async (person_passed) => {
    const data = await makeRequest({
        uri: `${urlPrefix}/mission`,
        json: true,
        method: "POST",
        body: {
            title: `${person_passed}'s village`,
            description: `null description`,
            creation_date: new Date().toISOString(),
            person_passed: person_passed,
            pause_mission: false,
        }
    })
    return data
}

export const getMembershipAndRoles = async (email) => {
    const _email = email.replace("+", "%2B")
    const data = await makeRequest({
        uri: `${urlPrefix}/membership/roles?email=${_email}`,
        json: true,
        method: "get"
    })
    return data
}

export const validateSignupArgs = (args, role) => {

    const {
        mission_uuid,
    } = args

    if (role === "cont" && mission_uuid.length !== UUID_CHAR_LENGTH) {
        const err = new Error("Mission is invalid for signup.")
        err.code = "mission_uuid_invalid"
        throw err
    }
}

export const saveSigninInputToLocal = (args, role) => {
    const {
        mission_uuid, 
        personPassed, 
        email
    } = args

    if (role === "org") {
        setLocalItem(SIGNIN_EXIST_USER, {
            ...args,
            role,
            redirect: "calendar",
            userExist: true, // coming from wizard
        });
    }
    
    if (role === "cont") {
        setLocalItem(SIGNIN_EXIST_USER, {
            ...args,
            role: role,
            mission_uuid: mission_uuid,
            person_passed: personPassed,
            redirect: "task-page"
        })
    }

    if (role === "rec") {
        setLocalItem(SIGNIN_EXIST_USER, {
            ...args,
            role: role,
            mission_uuid: mission_uuid,
            person_passed: personPassed,
            redirect: "calendar"
        })
    }

    if (role === "cont" || role === "rec") {
        // check membership & upgrade role if needed
        setLocalItem(CHECK_MEMBERSHIP, {
            role: role,
            mission_uuid: mission_uuid,
            email: email
        })
    }
}

export const saveSignupInputToLocal = (args, role, auth0Id) => {
    const redirect = role === "cont" ? "task-page" : "calendar";
    setLocalItem(SIGNUP_NEW_USER, {
        ...args,
        redirect,
        auth0Id,
        role
    })
}

export const resetPasswordHandler = async (email) => {
    const data = await makeRequest({
        uri: `${urlPrefix}/users/resetPassword`,
        method: 'post',
        json: true,
        body: {
            'email': email,
        }
    })
    return data
}

export const updateUserInfo = async (args) => {
    const data = await makeRequest({
        uri: `${env.API_URL}/users`,
        method: "PUT",
        body: {
            email: args.email,
            allowEmail: args.allowEmail,
            name: args.name,
            password: args.password
        },
        json: true,
    });
    return data;
}