import React, {useEffect, useState} from 'react';
import PanelContainer from '../InvitePanelContainer';
import InviteListSection from './InvitesListSection';
import useMediaQuery from '../../../shared-lib/utils/useMediaQuery';
import {structureInviteData} from '../../../shared-lib/utils/invite_utils';
import {connect} from 'react-redux';
import {getInvitesAndMembers} from '../../../data/actions';
import {useUser} from '../../../auth/UserContext';
import styled from 'styled-components';
import ManageMembersModal from '../../Settings/ManageMembersModal';

const ManageRolesButton = styled.p`
    color: var(--color-savo-purple);
    font-family: var(--font-mrsEaves);
    text-align: center;
    text-decoration: underline solid rgb(105, 72, 157);
    font-size: 18px;
    margin-bottom: 36px;
    margin-top: 15px;
    cursor: pointer;
`

const InviteListPanel = (props) => {
    
    const {invitesAndMembers, getInvitesAndMembers} = props;
    const membershipList = (invitesAndMembers.selectRoles(['rec', 'cont']) || []);
    const [managingMembers, setManagingMembers] = useState(false)

    const counts = invitesAndMembers.getCounts(['rec', 'cont'])

    const isSmall = useMediaQuery('(max-width: 1050px)');
    const {membership} = useUser()

    useEffect(() => {
        if (membership.mission_uuid) {
            getInvitesAndMembers(membership.mission_uuid);
        }
    },[membership, getInvitesAndMembers])



    return (
    <PanelContainer>
    <div style={{textAlign:'center'}}>
        <h4>Everyone who has been invited to this village.</h4>
    <h6 style={{marginTop:'0px'}}>{counts.pending} {counts.pending === 1 ? 'person has':'people have'} been invited. {counts.accepted} {counts.accepted === 1 ? 'person has':'people have'} joined.</h6>
    {managingMembers ? <ManageMembersModal handleClose={() => {setManagingMembers(false)}}/> : <ManageRolesButton onClick={()=>{setManagingMembers(true)}}>Manage member roles</ManageRolesButton>}
    </div>
        {membershipList.map((item, index) => {
            return <InviteListSection item={item} key={index} isSmall={isSmall}/>
        })}
    </PanelContainer>)
}

const mapStateToProps = (state) => {
    const invitesAndMembers = structureInviteData(state.VillageReducer.invitesAndMembers.data)
    return {
        invitesAndMembers
    }
} 

const mapDispatchToProps = { 
    getInvitesAndMembers
};
  
export default connect(mapStateToProps, mapDispatchToProps)(InviteListPanel);
