import React, { useEffect } from 'react';
import styled from 'styled-components';
import EmailPanel from '../EmailComponents/EmailPanel';
import PanelContainer from '../InvitePanelContainer';
import ServerBanner from '../../../shared-lib/components/general/ServerBanner';
import { useSelector } from 'react-redux';
import { Status } from '../../../data/api';


const PanelHeader = styled.div`
    text-align: center;

    h6 {
        font-family: var(--font-mrsEaves);
        font-weight: 400;
        color: #111111
    }
`

const BannerWrapper = styled.div`
    height: 66px;
    width: calc(100% + 40px);
    display: ${({show}) => show ? 'block':'none'};
`

const RecipientsPanel = ({personPassed}) => {
    
    const inviteRecState = useSelector(state => state.VillageReducer.inviteRecState)
    const {
        error:sendError,
        status:sendStatus,
        response: sendResponse
    } = inviteRecState

    const errorMsg = sendError ? "Sorry, we're having trouble sending your invitation. Please try again later." : null
    const successMsg = sendResponse ? 'Your email invitation has been sent.' : null

    useEffect(() => {
        if (sendStatus === Status.success || 
            sendStatus === Status.failure
        ) {
            window.scrollTo(0,0, {
                behavior: 'smooth'
            });
        }
    }, [sendStatus])

    return(
        <PanelContainer>
            <BannerWrapper show={errorMsg || successMsg}>
                <ServerBanner isError={sendError !== null} message={errorMsg || successMsg}/>
            </BannerWrapper>
            <div style={{width: '100%'}}>
            <PanelHeader>
                <h4>Invite the family to view and manage the requests.</h4>
                <h6 style={{marginBottom:'36px', marginTop:'0px'}}>They will be able to view, add, remove, modify, and cancel tasks.</h6>
            </PanelHeader>
            <EmailPanel 
                sendEmailInvitationsState={inviteRecState}
                personPassed={personPassed} 
                inviteAs='rec'
            />
            </div>
            
        </PanelContainer>
    )
}

export default RecipientsPanel;