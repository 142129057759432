import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import PanelContainer from '../InvitePanelContainer';
import LinkPanel from './LinkPanel';
import SocialMediaPanel from './SocialMediaPanel';
import { useUser } from '../../../auth/UserContext';
import useMediaQuery from '../../../shared-lib/utils/useMediaQuery';
import { useHistory } from 'react-router-dom';
import EmailInviteModal from '../EmailComponents/EmailInviteModal';
import { useDispatch } from 'react-redux';
import {invteContReset} from '../../../data/actions';
import RecipientsPanel from '../RecipientsPanel';
import Modal from '../../../shared-lib/components/general/Modal';

const Header = styled.div`
    text-align: center;
    margin-bottom: 30px;

    h4 {
        font-size: ${(props) => props.isWizard ? '65px' : '35px'};
        font-family: ${(props) => props.isWizard ? 'var(--font-mrEaves)' : 'var(--font-mrEaves)'};
        font-weight: ${(props) => props.isWizard ? '600' : '400'};
    }

    h6 {
        font-size: ${(props) => props.isWizard ? '18px' : '18px'};
        font-family: var(--font-mrsEaves);
        color: #111111;
        font-weight: 400;
    }
`
const PreviewLink = styled.label`
	color: var(--color-savo-purple);
    text-decoration: underline solid rgb(105, 72, 157);
    font-size: 18px;
    font-family: ${props => props.theme.mrsEaves}; 
    margin: 0px;
    
    :hover {
		cursor: pointer;
	}
`

const LinkHeader = styled.label`
    font-family: var(--font-mrEaves); 
    font-size: 26px;
    color: var(--color-savo-purple);
    margin-top: 50px;
    text-align: center;
`

const InviteFamilyButton = styled.button`
    width: 180px;
    height: 47px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #69469d;
    background-color: white;
    background-size: cover;
    font-family: ${props => props.theme.mrEaves};
    font-size: 21px;
    color: #69469d;
    text-align: center;
    margin-right: 15px;
    font-weight: bold;

    :hover {
        background-color: #060368;
        cursor: pointer;
        color: #ffffff;
        border-color: #060368;
    }

    @media(max-width: 500px) {
        width: 180px;
    }
`

const VolunteersPanel = (props) => {

    const {getContributorInviteLink} = useUser();
    const isSmall = useMediaQuery('(max-width: 900px)');

    const { membership } = useUser()
    const mission_uuid = membership && membership.mission_uuid
    const personPassed = membership && membership.person_passed;
    const history = useHistory()

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showRecipientModal, setRecipientModal] = useState(false);

    const dispatch = useDispatch()
    const gotoContributor = () => {
        history.push(`/${mission_uuid}/task-page`)
    }

    const link = useMemo(() => {
        return getContributorInviteLink({medium:'link', source:'direct'})
    }, [getContributorInviteLink])

    const handleClose = () => {
        setShowEmailModal(false)
        dispatch(invteContReset())
    }

    return(
        <PanelContainer>
            <Header isWizard={props.isWizard}>
                <h4>Invite friends and family to help out.</h4>
                <h6>They can volunteer for tasks and get all the details on how to help. </h6>
                <PreviewLink onClick={gotoContributor}>Preview the page they will be sent.</PreviewLink>
            </Header>
            <SocialMediaPanel isSmall={isSmall} personPassed={personPassed} openEmail={()=>{setShowEmailModal(true)}}/>
            <LinkHeader>Copy this link and share anywhere</LinkHeader>
            <LinkPanel {...props} urlToShare={link} isSmall={isSmall}/>
            {showEmailModal ? <EmailInviteModal {...props} inviteAs={'cont'} handleClose={handleClose} isSmall={isSmall} personPassed={personPassed}/> : null}
            {!props.isWizard && (<>
                <Header>
                    <h4>Invite the family to help manage the village.</h4>
                    <h6>Some family members may want to see what is happening in the village. Others may want to be involved in the day-to-day management of available tasks.</h6>
                </Header>
                <InviteFamilyButton
                    type="button"
                    onClick={() => setRecipientModal(true)} >
                    Invite the family
                </InviteFamilyButton>
                <Modal
                    open={showRecipientModal}
                    handleClose={() => setRecipientModal(false)}
                >
                    <RecipientsPanel />
                </Modal>
            </>
            )}
        </PanelContainer>
    )
}

export default VolunteersPanel;