import React from 'react';
import { useFormik } from 'formik'
import {FormField} from './FormField';
import * as Yup from 'yup';
import Button from '../Button';
import { ActivityIndicator } from '../general/ActivityIndicator';
import FlexForm from '../FlexForm';

const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password is too short')
      .required('Password is required')
}); 


function SigninForm({
    submitHandler,
    requestPending,
    togglePasswordReset,
    isHidden,
    emailAddress,
}) {

    const initialValues = {
        email: emailAddress,
        password: ""
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: submitHandler,
        validationSchema: validationSchema
    })

    return (
    <FlexForm onSubmit={formik.handleSubmit} isHidden={isHidden}>
        <FormField
            config={{
                title: "Your email",
                touched: formik.touched.email,
                error: formik.errors.email,
                onBlur: formik.handleBlur,
                id: "email_signin",
                name: "email",
                onChange: formik.handleChange,
                type: "email",
                value: formik.values.email
            }}
        />
        <FormField
            config={{
                title: "Password",
                touched: formik.touched.password,
                error: formik.errors.password,
                onBlur: formik.handleBlur,
                id: "password_signin",
                name: "password",
                onChange: formik.handleChange,
                type: "password",
                value: formik.values.password
            }}
        />
        <a className="link" onClick={() => togglePasswordReset(false, formik.values.email)} style={{marginTop: 0}}>Forgot password</a>
        <ActivityIndicator active={requestPending}>
            <Button type="submit" enabled={formik.isValid && !formik.isSubmitting}>
                Login
            </Button>
        </ActivityIndicator>
    </FlexForm>
    )
}


export default SigninForm;