import * as actions from '../actions/actionTypes';

const initialState = {
    taskSeries: []
}

const TaskReducer = (state = initialState, action) => {
switch (action.type) {
   case actions.GET_TP_TASKS_SUCCESS:
       return {
           ...state,
           taskSeries: action.payload
       }
   default:
       return state
}
}

export default TaskReducer;