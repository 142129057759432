import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    & > input[type=checkbox] + svg {
        position: absolute;
        fill: white;
        visibility: hidden;
        pointer-events: none;
    }

    & > input[type=checkbox]:checked + svg {
        visibility: visible;
        pointer-events: none;
    }
`


const Chk = styled.input`
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#060368'};
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid ${props => props.borderColor ? props.borderColor : '#060368'};
    border-radius: 12.5px;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
`


function Checkbox ({
    checked = false,
    height = "8px",
    width = "11px",
    backgroundColor,
    borderColor
}) {


    return (
        <Label>
            <Chk type="checkbox" readOnly checked={checked} backgroundColor={backgroundColor} borderColor={borderColor}/>
            <svg 
                version="1.2" 
                overflow="visible" 
                preserveAspectRatio="none" 
                viewBox="0 0 28 20" 
                height={height}
                width={width}
            >
                <polyline 
                    points="10.6206897 21.0517241 19.278069 29.5862069 33.3793103 14.4137931"
                    fill="none" 
                    stroke="#FFFFFF" 
                    strokeWidth="2" 
                    strokeLinejoin="round" 
                    strokeLinecap="round"  
                    fillRule="evenodd"
                    transform="translate(-8.000000, -11.000000)"
                />
            </svg>
        </Label>
    )
}

export default Checkbox;