import React from 'react';
import styled from 'styled-components';
import LoadingIcon from '../../assets/loading.svg';

const Spinner = styled.img`
    margin: 10px;
    height: 50px;
    width: 50px;
    align-self: center;
    justify-content: center;
`

export const ActivityIndicator = (props) => {
    const {active, children} = props;

    return(<div>
            {active ? 
            <Spinner src={LoadingIcon}/> 
            : 
           <div> {children}</div>}
        </div> )
}