import * as actions from '../actions/actionTypes';
import moment from 'moment';
import { Status } from '../api';
import _ from 'lodash';

const initialState = {
    mission: null,
    getMissionResponse: null,
    getMissionError: null,
    taskSeries: [],
    weekStart: moment().utc().startOf('week'),
    weekEnd: moment().utc().endOf('week'),
    getBookingsResponse: null,
    getBookingsError: null,
    settings:{
        status: Status.idle,
        error:null,
        data: {
            person_passed:null,

        }
    },
    addTaskResponse: null,
    addTaskError: null,
    updateTaskSeriesSuccess: null,
    updateTaskSeriesError: null,
    deleteTaskSeriesSuccess: null,
    deleteTaskSeriesError: null,
    modifyTaskSeriesCategoriesError: null,
    deleteBookingState: {
        status: Status.idle,
        response: null,
        error: null,
    },
    createBookingState: {
        status: Status.idle,
        response: null,
        error: null,
    },
    fetchRecentNotesState: {
        status: Status.idle,
        response: null,
        error: null,
    },
    inviteContState: {
        status: Status.idle,
        response: null,
        error: null
    },
    inviteRecState: {
        status: Status.idle,
        response: null,
        error: null 
    },

    invitesAndMembers: {
        status: Status.idle,
        data: {invites:[], members:[]},
        error: null
    }
    
}

const VillageReducer = (state = initialState, action) => {

    const _setError = (key) => {
        return {
            ...state,
            [key]: {
                status: Status.failure,
                response: null,
                error: action.payload  
            }
        }
    }
    
    const _setResp = (key) => {
        return {
            ...state,
            [key]: {
                status: Status.success,
                response: action.payload,
                error: null 
            }
        }
    }
    
    const _setIdle = (key) => {
        return {
            ...state,
            [key]: {
                status: Status.idle,
                response: null,
                error: null  
            }
        }
    }
    
    const _setLoad = (key) => {
        return {
            ...state,
            [key]: {
                status: Status.loading,
                response: null,
                error: null  
            }
        }
    }

    console.log("HANDLING ACTION TYPE", action.type);

    switch(action.type) {
        case actions.UPDATE_TASK_SERIES_RESET:
            return {
                ...state,
                updateTaskSeriesSuccess: null,
                updateTaskSeriesError: null,
            }
        case actions.UPDATE_TASK_SERIES_SUCCESS:
            return {
                ...state,
                updateTaskSeriesSuccess: action.payload,
            }
        case actions.UPDATE_TASK_SERIES_ERROR:
            return {
                ...state,
                updateTaskSeriesError: action.payload
            }
        case actions.DELETE_TASK_SERIES_RESET:
            return {
                ...state,
                deleteTaskSeriesSuccess: null,
                deleteTaskSeriesError: null
            }
        case actions.DELETE_TASK_SERIES_SUCCESS:
            return {
                ...state,
                deleteTaskSeriesSuccess: action.payload
            }
        case actions.DELETE_TASK_SERIES_ERROR:
            return {
                ...state,
                deleteTaskSeriesError: action.payload
            }
        case actions.FETCHED_TASK_SERIES_SUCCESS:
            return {
                ...state,
                taskSeries: action.payload
            }
        case actions.DELETE_BOOKING:
            var optimistic = _.cloneDeep(state.getBookingsResponse)
            const bookingId = action.payload.booking.booking_id
            _.remove(optimistic, (item) => item.booking_id === bookingId)
            return {
                ...state,
                getBookingsResponse: optimistic,
                deleteBookingState: {
                    status: Status.loading,
                    response: null,
                    error: null
                }
            }
        case actions.DELETE_BOOKING_SUCCESS:
            return {
                ...state,
                deleteBookingState: {
                    status: Status.success,
                    response: true,
                    error: null
                },
            }
        case actions.DELETE_BOOKING_ERROR:
            return {
                ...state,
                deleteBookingState: {
                    status: Status.failure,
                    response: null,
                    error: action.payload
                },
            }
        case actions.DELETE_BOOKING_RESET:
            return {
                ...state,
                deleteBookingState: {
                    status: Status.idle,
                    response: null,
                    error: null
                },
            }
        case actions.CREATE_BOOKING:
            return {
                ...state,
                createBookingState: {
                    status: Status.loading,
                    response: null,
                    error: null,
                },
            }
        case actions.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                createBookingState: {
                    status: Status.success,
                    response: action.payload,
                    error: null,
                }
            }
        case actions.CREATE_BOOKING_ERROR:
            return {
                ...state,
                createBookingState: {
                    status: Status.failure,
                    response: null,
                    error: action.payload
                }
            }
        case actions.CREATE_BOOKING_RESET:
            return {
                ...state,
                createBookingState: {
                    status: Status.idle,
                    response: null,
                    error: null
                }
            }
        case actions.GET_BOOKINGS_SUCCESS:
            return {
                ...state,
                getBookingsResponse: action.payload
            }
        case actions.GET_BOOKINGS_ERROR:
            return {
                ...state,
                getBookingsError: action.payload
            }
        case actions.GET_BOOKINGS_RESET:
            return {
                ...state,
                getBookingsError: null,
                getBookingsResponse: null
            }
        case actions.UPDATE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    status: Status.loading,
                    error: null
                }
            }
        case actions.UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: {
                    status: Status.updated,
                    data: action.payload,
                    error: null
                }
            }
        case actions.UPDATE_SETTINGS_ERROR:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    status: Status.idle,
                    error:action.payload
                }
            }
        case actions.GET_SETTINGS: 
            return {
                ...state,
                settings: {
                    ...state.settings,
                    status:Status.loading,
                    error: null
                }
            }
        case actions.GET_SETTINGS_SUCCESS:
            const {person_passed} = action.payload; 
            return {
                ...state,
                settings: {
                    status:Status.idle,
                    data: action.payload,
                    error: null
                },
                mission: {
                    ...state.mission,
                    person_passed: person_passed
                },
            }
        case actions.GET_SETTINGS_ERROR:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    status: Status.failure,
                    error: action.payload
                },
            }
        case actions.ADD_TASK:
            return { ...state, addTaskResponse: null, addTaskError: null }
        case actions.ADD_TASK_RESPONSE: 
            return { ...state, addTaskResponse: action.payload }
        case actions.ADD_TASK_ERROR:
            return { ...state, addTaskError: action.payload }
        case actions.MODIFY_TASK_SERIES_CATEGORIES_SUCCESS:
            return { ...state, modifyTaskSeriesCategoriesError: null }
        case actions.MODIFY_TASK_SERIES_CATEGORIES_ERROR:
            return { ...state, modifyTaskSeriesCategoriesError: action.payload }
        case actions.SET_WEEK:
            const { weekStart, weekEnd } = action.payload
            return { 
                ...state, 
                weekStart: moment(weekStart), 
                weekEnd: moment(weekEnd),
            }
        case actions.GET_MISSION:
            return { ...state, getMissionResponse: null, getMissionError: null}
        case actions.GET_MISSION_RESPONSE:
            return { 
                ...state, 
                getMissionResponse: action.payload,
                mission: action.payload[0]
            }
        case actions.GET_MISSION_ERROR:
            return { ...state, getMissionError: action.payload }

        // MARK: - Fetching recent notes in the Contributor Task Page
        case actions.FETCH_RECENT_NOTES:
            return _setLoad("fetchRecentNotesState")
        case actions.FETCH_RECENT_NOTES_SUCCESS:
            return _setResp("fetchRecentNotesState")
        case actions.FETCH_RECENT_NOTES_FAILURE:
            return _setError("fetchRecentNotesState")

        // MARK: - Sending Email Invite to **Contributor**
        case actions.INVITE_CONT:return _setLoad("inviteContState")
        case actions.INVITE_CONT_SUCCESS:return _setResp("inviteContState")
        case actions.INVITE_CONT_FAILURE:return _setError("inviteContState")
        case actions.INVITE_CONT_RESET:return _setIdle("inviteContState")

        // MARK: - Sending Email Invite to **Recipient**
        case actions.INVITE_REC:return _setLoad("inviteRecState")
        case actions.INVITE_REC_SUCCESS:return _setResp("inviteRecState")
        case actions.INVITE_REC_FAILURE:return _setError("inviteRecState")
        case actions.INVITE_REC_RESET:return _setIdle("inviteRecState")

        case actions.GET_INVITES_AND_MEMBERS:
            return {
                ...state,
                invitesAndMembers: {
                    status: Status.loading,
                    data: state.invitesAndMembers.data,
                    error: null  
                }
            }
        case actions.INVITE_AND_MEMBER_RESPONSE:
            const {error} = action.payload;
            return {
                ...state,
                invitesAndMembers: {
                    status: Status.idle,
                    data: action.payload || state.invitesAndMembers.data,
                    error
                }
            }
        case actions.UPDATE_INVITES_AND_MEMBERS:
            return {
                ...state,
                invitesAndMembers: {
                    status: Status.loading,
                    data: state.invitesAndMembers.data,
                    error: null
                }
            }
        case actions.UPDATE_INVITES_AND_MEMBERS_RESPONSE:
            const updateError = action.payload.error;    
            if (updateError) {
                return {
                    ...state,
                    invitesAndMembers: {
                        status: Status.idle,
                        data: state.invitesAndMembers.data,
                        error: 'Update failed. Please try again later.'
                    }
                }

            } else {
                const newData = _.cloneDeep(state.invitesAndMembers.data)
                newData.members = newData.members.map(mem => {
                    if (mem.user_uuid === action.payload.user_uuid) {
                        mem.role = action.payload.role
                    }
                    return mem;
                })

                return {
                    ...state,
                    invitesAndMembers: {
                        status: Status.idle,
                        data: newData,
                        error: null
                    }
                }
            }

            
        default: 
            return state;
    }
}

export default VillageReducer;