const user_exists = "user_exists";
const access_denied = "access_denied";
const mission_uuid_invalid = "mission_uuid_invalid"

export const normalizedMessage = (error) => {
    if (!error) {
        return null;
    }
    if (error.message === "failed_send_email") {
        return "Sorry please try send email again"
    }
    if (error.message === "invalid_invite") {
        return "Invitation is invalid"
    }
    
    if (error.code === access_denied) {
        return "Incorrect email or passsword."
    }

    if (error.code === mission_uuid_invalid) {
        return "Mission is invalid for sign up"
    }

    if (error.code === user_exists) {
        return "This user has already signed up. Please login."
    } else {
        return "There was a problem with the request."
    }
}