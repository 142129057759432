import React, { useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '../../shared-lib/utils/useMediaQuery';
import CheckWhite from '../../assets/check-white.svg';

const Root = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isSmall ? 'column' : 'row'};
  width: 100%;
  height: ${(props) => props.isSmall ? 'auto' : '75px'};;
  border: 2px solid #a694c7;
  border-radius: 5px;
  background-color: ${(props) => props.hasOpen ? '#ebe9f4' : 'white'};
  background-size: cover;
  box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.05);
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
  }
`

const Number = styled.div`
  width: 35px;
  min-width: 35px !important;
  height: 35px;
  min-height: 35px !important;
  border: 2px solid #ffffff;
  background-color: #ebe9f4;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 10px;
`

const CheckMark = styled.div`
  width: 35px;
  min-width: 35px !important;
  height: 35px;
  min-height: 35px !important;
  border: 2px solid white;
  background-color: #69469d;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 10px;
`

const Title = styled.div`
  height: auto;
  font-family: var(--font-mrEaves);
  font-size: 26px;
  color: #69469d;
  flex-grow: 1;
  font-weight: bold;
  text-decoration: none solid rgb(105, 70, 157);
  margin: 0 10px;
`

const Divider = styled.div`
  height: ${(props) => props.isSmall ? '1px' : '100%'} !important;
  width: ${(props) => props.isSmall ? '100%' : '1px'} !important;
  background: #a694c7;
`

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  min-width: 230px;
  margin:  ${(props) => props.isSmall ? '10px' : '0'} !important;
  img {
    margin: 0 10px 0 20px;
  }

  p:first-child {
    margin: 0 20px 0 0;
    height: 24px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #69489d;
    font-weight: bold;
    text-transform: capitalize;
    text-align: left;
  }

  p:last-child {
    width: 126px;
    height: 15px;
    font-family: var(--font-mrsEaves);
    font-size: 15px;
    color: #111111;
    font-style: italic;
    font-weight: normal;
    text-align: left;
    margin: 0;
  }
`

const getPreference = (ts) => {
  const { title, } = ts
  return `Include preferences for ${title}`
}
// 
const TaskBar = ({
  idx,
  ts,
  setEdit,
}) => {

  const { title, category, recurring_days, iconUrl } = ts;
  const frequency = recurring_days.slice(1, -1).split(",").length;
  const [hasOpen, setHasOpen] = useState(false);
  const isSmall = useMediaQuery('(max-width: 600px)');
  
  const handleEdit = () => {
    setHasOpen(true);
    setEdit(ts);
  }

  if (!ts) { return <div key={idx}></div>; }
  return (
  <Root onClick={handleEdit} key={idx} isSmall={isSmall} hasOpen={hasOpen}>
    <div style={{flexGrow: 1, display: 'flex', alignItems: 'flex-start', margin: isSmall ? '10px 10px' : ''}}>
      {hasOpen ? 
        <CheckMark><img src={CheckWhite} alt="v" /></CheckMark> :
        <Number>{idx + 1}</Number>
      }
      
      <Title>{getPreference(ts)}</Title>
    </div>
    <Divider isSmall={isSmall}/>
    <Card isSmall={isSmall}>
      <img src={iconUrl} alt="ts-icon"/>
      <div>
        <p>{title}</p>
        <p>{frequency} times a week</p>
      </div>
    </Card>
  </Root>
  )
}

export default TaskBar;