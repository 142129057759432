import React from 'react';

function InvitationsHeader() {
    return (
        <div className="inv-header">
            <h2>Savo works best when it's shared.</h2>
            <p className="inv-p">Recruit help for the village through email, social media, and links. </p>
        </div>
    )
}

export default InvitationsHeader;