import React from 'react';
import InvitationTabComponent from '../Invitations/InvitationTabComponent'
import { Route, Redirect, Switch, useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import VolunteersPanel from './VolunteersPanel';
import RecipientsPanel from './RecipientsPanel';
import InviteListPanel from './InviteListPanel';
import { useDispatch } from 'react-redux';
import { invteRecReset } from '../../data/actions';
function InvitationsTabs (props) {

 
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const tabs = [
    {name:'volunteers', title: 'Send Invites', render: (rProps) => {return <VolunteersPanel {...rProps} {...props} />}},
    {name: 'invites', title: 'Who has been invited', render: (rProps) => {return <InviteListPanel {...rProps} {...props}/>}}
  ]

  const handleTabClick = (i) => {
    dispatch(invteRecReset())
    history.push(`${url}/${tabs[i].name}`)
    window.scrollTo(0,0, {
      behavior: 'smooth'
    });
  }

  
  return (
    <div className="inv-body">
      <div className="tab-root" >

        {tabs.map((tab, index) => {
          const edge = index === 0 ? 'left' : index === tabs.length - 1 ? 'right' : null;
          return <InvitationTabComponent
          key={index}
          edge={edge} 
          title={tab.title}
          isChecked={pathname.includes(tab.name)}
          onClick={() => handleTabClick(index)}/>
        })}

      </div>
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${url}/${tabs[0].name}`}/>} />

        {tabs.map( (tab, index) => {
          return  <Route path={`${path}/${tab.name}`} render={tab.render} key={index}/>
        })}

      </Switch>
    </div>
  )
}

export default InvitationsTabs;