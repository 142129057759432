import React, { useState } from 'react';
import Modal from '../../shared-lib/components/general/Modal';
import ManageMembers from './ManageMembers';

const ManageMembersModal = ({handleClose}) => {
    const [error, setError] = useState(null)

    return (
    <Modal open={true} handleClose={handleClose} error={error} title={<h2>Manage Members</h2>}>
        <ManageMembers setError={setError}/>
    </Modal>)
}

export default ManageMembersModal