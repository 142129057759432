import React from 'react'
import styled from 'styled-components';
import checkIcon from '../../assets/check-white.svg';

const RoundIconWrapper = styled.div`
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: ${props => props.bgColor};
    background-size: cover;
    display:flex;
    align-items: center;
    justify-content: center;
`

const Icon = styled.img`
    height: 6px;
    width: 8px;
    visibility: ${props => props.checked ? "visible" : "hidden"};
`
const RoundCheckBox = ({checkedColor, uncheckedColor, checked}) => {
    return(
        <RoundIconWrapper bgColor={checked ? checkedColor : uncheckedColor}>
            <Icon src={checkIcon} checked={checked}></Icon>
        </RoundIconWrapper>
    )
}

export default RoundCheckBox;