import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid #cccccc;

    & > input {
        cursor: pointer;
        opacity: 0;
        display: none;
    }

    #signup:checked + label {
        font-weight: bold;
    }

    #login:checked + label {
        font-weight: bold;
    }

    #signup:checked ~ span {
        left: 0px;
      }
    
    #login:checked ~ span {
        left: 50%;
    }
`

const Line = styled.span`
    width: 50%;
    height: 4px;
    top: 37px;
    position: absolute;
    background: #69469d
    transition: all .2s;
`

const Label = styled.label`
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #69469d;
    text-decoration: none solid rgb(105, 70, 157);
    text-align: center;
    font-weight: normal;
`


function SignupToggle ({
    isSignin = false,
    handleRadio = () => {}
}) {
    return <Container>
            <input 
                type="radio" 
                name="signup_login" 
                id="signup" 
                value="signup_form"
                checked={isSignin === false}
                onChange={handleRadio}
                />
            <Label htmlFor="signup">Signup</Label>
            <input 
                type="radio" 
                name="signup_login" 
                id="login" 
                value="login_form"
                checked={isSignin === true}
                onChange={handleRadio}
                />
            <Label htmlFor="login">Login</Label>
            <Line />
    </Container>
}


export default SignupToggle;