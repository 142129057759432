import * as handlers from './village.handlers';
import * as actions from '../actions/actionTypes';
import { fetchTaskSeries, 
    modifyTasksSeriesCategoriesSuccess, 
    modifyTasksSeriesCategoriesError } from '../actions';
import { prepareTaskSeriesCrudData } from './village.mapper';
import moment from 'moment';

export const villageMiddleWare = ({ getState, dispatch }) => next => async action => {

    console.log("village middleware", action);

    next(action)

    const payload = action.payload;
    const currentState = getState();

    if (action.type === actions.GET_MISSION) {
        try {
            const mission_uuid = action.payload
            const mission = await handlers.getMission(mission_uuid)
            dispatch({type: actions.GET_MISSION_RESPONSE, payload: mission})
        } catch (error) {
            dispatch({type: actions.GET_MISSION_ERROR, payload: error})
        }
        return action
    }

    if (action.type === actions.FETCH_TASK_SERIES) {
        try {
            const { mission_uuid, weekStart, weekEnd } = action.payload
            const taskSeriesWithIcons = await handlers.fetchTaskSeries(
                mission_uuid, 
                weekStart, 
                weekEnd
            )        
            dispatch({ 
                type: actions.FETCHED_TASK_SERIES_SUCCESS, 
                payload: taskSeriesWithIcons
            })
        } catch (error) {
            dispatch({ 
                type: actions.FETCH_TASK_SERIES_ERROR, 
                payload: error
            })
        }
        return action
    }

    if (action.type === actions.GET_TP_TASKS) {
        try {
            if (action.payload) {
                const mission_uuid = action.payload
                const today = moment().toISOString()
                const weeksLater = moment().add(25, 'week').toISOString()
                const response = await handlers.getTaskPageTaskSeries(mission_uuid, today, weeksLater)
                dispatch({ 
                    type: actions.GET_TP_TASKS_SUCCESS, 
                    payload: response
                })
            } else {
                throw(new Error("payload doesn't have mission_uuid"))
            }
        } catch (error) {
            dispatch({
                type: actions.GET_TP_TASKS_ERROR,
                payload: "payload doesn't have mission_uuid"
            })
        }
    }

    if (action.type === actions.CREATE_BOOKING) {
        try {
            const {
                date,
                user_uuid,
                series_id,
                mission_uuid,
                isFromTaskPage,
                note
            } = action.payload
            const weekStart = getState().VillageReducer.weekStart
            const weekEnd = getState().VillageReducer.weekEnd
            const response = await handlers.createBooking(
                date, 
                user_uuid,
                series_id,
                note
            )

            dispatch({ 
                type: actions.CREATE_BOOKING_SUCCESS, 
                payload: response
            })

            if (isFromTaskPage) {
                dispatch({ 
                    type: actions.GET_TP_TASKS, 
                    payload: mission_uuid
                })
            } else {
                dispatch({ 
                    type: actions.FETCH_TASK_SERIES, 
                    payload: {mission_uuid: mission_uuid, weekStart, weekEnd}
                })
            }
        } catch (error) {
            dispatch({
                type: actions.CREATE_BOOKING_ERROR, 
                payload: error.message
            })
        }
        return action
    }

    if (action.type === actions.DELETE_BOOKING) {
        try { 
            const { 
                booking,
                mission_uuid,
                isFromTaskPage,
                user_uuid
            } = action.payload
            
            const response = await handlers.deleteBooking(booking)
            dispatch({ type: actions.DELETE_BOOKING_SUCCESS, payload: response })

            if (isFromTaskPage) {
                dispatch({type: actions.GET_BOOKINGS, payload: {user_uuid}})
                return action
            }

            const {series_id, assignee, exceptionId, date} = booking


            let bookingException = {
                assignee: assignee,
                series_id: series_id,
                date: moment(date).utc().format('YYYY-MM-DD').toString(),
                mission_uuid: mission_uuid,
                exceptionId: exceptionId,
            }
            if (exceptionId) {
                dispatch({type: actions.UPDATE_EXCEPTION, payload: bookingException})
            } else {
                dispatch({type: actions.ADD_EXCEPTION, payload: bookingException})
            }
        } catch (error) {
            dispatch({type: actions.DELETE_BOOKING_ERROR, payload: error})
        }
        return action
    }

    if (action.type === actions.UPDATE_EXCEPTION) {
        try { 
            const { exceptionId, mission_uuid } = action.payload
            const weekStart = getState().VillageReducer.weekStart
            const weekEnd = getState().VillageReducer.weekEnd
            const response = await handlers.updateException(
                exceptionId,
                "exclude" // TODO: to get from payload
            )
            dispatch({ 
                type: actions.UPDATE_EXCEPTION_RESPONSE, 
                payload: response 
            })
            dispatch({ 
                type: actions.FETCH_TASK_SERIES,
                payload: {mission_uuid, weekStart, weekEnd}
            })
        } catch (error) {
            dispatch({ 
                type: actions.UPDATE_EXCEPTION_ERROR, 
                payload: error.message
            })
        }
        return action
    }

    if (action.type === actions.ADD_EXCEPTION) {
        try {
            const {date, series_id, mission_uuid} = action.payload
            const dateString = moment(date).format('YYYY-MM-DD').toString()
            const weekStart = getState().VillageReducer.weekStart
            const weekEnd = getState().VillageReducer.weekEnd
            const response = await handlers.addException(
                series_id, 
                dateString, 
                "exclude", // TODO: should come from payload
                mission_uuid
            )
            dispatch({ 
                type: actions.ADD_EXCEPTION_RESPONSE, 
                payload: response
            })
            dispatch({ 
                type: actions.FETCH_TASK_SERIES, 
                payload: {mission_uuid, weekStart, weekEnd}
            })
        } catch (error) {
            dispatch({ 
                type: actions.ADD_EXCEPTION_ERROR, 
                payload: error.message
            })
        }
        return action
    }

    if (action.type === actions.ADD_TASK) {
        try {
            const {series_id, date, mission_uuid, exceptionId} = action.payload
            if (exceptionId) {
                const response = await handlers.updateException(
                    exceptionId,
                    "include"
                )
                dispatch({type: actions.ADD_TASK_RESPONSE, payload: response})
            } else {
                const response = await handlers.addException(
                    series_id, 
                    date, 
                    "include",
                    mission_uuid
                )
                dispatch({type: actions.ADD_TASK_RESPONSE, payload: response})
            }

            const weekStart = getState().VillageReducer.weekStart
            const weekEnd = getState().VillageReducer.weekEnd
            dispatch({type: actions.FETCH_TASK_SERIES, payload: {mission_uuid, weekStart, weekEnd}})
        } catch (error) {
            dispatch({type: actions.ADD_TASK_ERROR, payload: error})
        }
        return action
    }

    if (action.type === actions.UPDATE_TASK_SERIES) {
        try {
            let body = {
                series_id: action.payload.series_id,
                recurring_days: action.payload.recurring_days,
                recurring_cadence: action.payload.weekRepeat,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                preferences: action.payload.preferences,
                thirdPartyAllow: action.payload.thirdPartyAllow,
            }
            const response = await handlers.updateTaskSeries(body)
            dispatch({ type: actions.UPDATE_TASK_SERIES_SUCCESS, payload: response})
        } catch (error) {
            dispatch({ type: actions.UPDATE_TASK_SERIES_ERROR, payload: error }) 
        }
    }

    if (action.type === actions.DELETE_TASK_SERIES) {
        try {
            const id = action.payload
            const response = await handlers.deleteTaskSeries(id)
            dispatch({
                type: actions.DELETE_TASK_SERIES_SUCCESS, 
                payload: response
            })
        } catch (error) {
            dispatch({
                type: actions.DELETE_TASK_SERIES_ERROR, 
                payload: error
            })
        }
    }

    if (action.type === actions.MODIFY_TASK_SERIES_CATEGORIES) {
        const { missionUuid, taskSeriesCategoriesData } = payload;
        if (!taskSeriesCategoriesData) {
            return;
        }

        try {
            const crudData = prepareTaskSeriesCrudData(currentState, missionUuid, taskSeriesCategoriesData);

            const shouldUpdate = crudData.create.length > 0 
                || crudData.update.length > 0
                || crudData.delete.length > 0; 
            if (!shouldUpdate) {
                return;
            }

            const result = await handlers.batchModifyTaskSeriesInCategories(crudData);
            dispatch(modifyTasksSeriesCategoriesSuccess());

            const { weekStart, weekEnd } = currentState.VillageReducer;
            
            dispatch(fetchTaskSeries(missionUuid, weekStart, weekEnd));
        } catch(error) {
            console.error(error);
            dispatch(modifyTasksSeriesCategoriesError(error));
        }
    }

    if (action.type === actions.GET_SETTINGS) {
        const mission_uuid = action.payload.mission_uuid
        try {
            var settings = await handlers.getSettings(mission_uuid)
            if (settings.family_members_of_passed == null) {
                settings.family_members_of_passed = []
            }
            if (settings.important_date_list == null) {
                settings.important_date_list = []
            }
            dispatch({ type: actions.GET_SETTINGS_SUCCESS, payload: settings })
        } catch (error) {
            dispatch({ type: actions.GET_SETTINGS_ERROR, payload: error })
        }
        return action
    }

    if (action.type === actions.UPDATE_SETTINGS) {
        let body = {
            "mission_uuid": action.payload.settings.mission_uuid,
            "person_passed": action.payload.settings.name,
            "pause_mission": action.payload.settings.pause_mission,
            "important_date_list": action.payload.settings.importantDateList
        }
        try {
            await handlers.updateSettings(body)
            const settings = await handlers.getSettings(action.payload.settings.mission_uuid)
            dispatch({ type: actions.UPDATE_SETTINGS_SUCCESS, payload: settings })
        } catch (error) {
            dispatch({ type: actions.UPDATE_SETTINGS_ERROR, payload: error })
        }
        return action
    }
    
    if (action.type === actions.GET_BOOKINGS) {
        const { user_uuid } = action.payload
        try {
            const resp = await handlers.getBookings(user_uuid)
            dispatch({type: actions.GET_BOOKINGS_SUCCESS, payload: resp})
        } catch (error) {
            var errorMessage;
            if (error.name === "StatusCodeError") {
                errorMessage = "Server Error"
            } else {
                errorMessage = error.message
            }
            dispatch({type: actions.GET_BOOKINGS_ERROR, payload: errorMessage})
        }
        return action
    }

    if (action.type === actions.FETCH_RECENT_NOTES) {
        const { date, seriesId} = action.payload
        try {
            const recentNotes = await handlers.fetchRecentNotes(date, seriesId)
            dispatch({
                type: actions.FETCH_RECENT_NOTES_SUCCESS, 
                payload: recentNotes
            })
        } catch (error) {
            dispatch({
                type: actions.FETCH_RECENT_NOTES_FAILURE, 
                payload: error
            })
        }
        return action
    }

    if (action.type === actions.INVITE_CONT) {
        try {
            const data = action.payload
            const response = await handlers.sendEmailInvitations(data)
            dispatch({
                type: actions.INVITE_CONT_SUCCESS,
                payload: response,
            })
        } catch (error) {
            dispatch({
                type: actions.INVITE_CONT_FAILURE,
                payload: error,
            })
        }
        return action
    }

    if (action.type === actions.INVITE_REC) {
        try {
            const data = action.payload
            const response = await handlers.sendEmailInvitations(data)
            dispatch({
                type: actions.INVITE_REC_SUCCESS,
                payload: response,
            })
        } catch (error) {
            dispatch({
                type: actions.INVITE_REC_FAILURE,
                payload: error,
            })
        }
        return action
    }

    if (action.type === actions.GET_INVITES_AND_MEMBERS) {
        try {
            const {mission_uuid} = action.payload
            const response = await handlers.getInvitesAndMembers(mission_uuid);

            dispatch({
                type:actions.INVITE_AND_MEMBER_RESPONSE,
                payload: response
            })
        } catch (error) {

            dispatch({
                type:actions.INVITE_AND_MEMBER_RESPONSE,
                payload: error
            })

        }
        return action;
    }

    if (action.type === actions.UPDATE_INVITES_AND_MEMBERS) {
        try {
            const data = action.payload;
            await handlers.updateInvitesAndMembers(data);
            dispatch({
                type: actions.UPDATE_INVITES_AND_MEMBERS_RESPONSE,
                payload: action.payload
            })

        } catch(error) {
            dispatch({
                type:actions.UPDATE_INVITES_AND_MEMBERS_RESPONSE,
                payload: error
            })
        }
    }
}
