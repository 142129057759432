import React, { useEffect } from 'react';
import { RootContainer, H2, H4, H6, ButtonStyled } from './style';
import { connect } from 'react-redux';
import { wizardStep2 } from '../../../data/actions';
import { useAnalytics } from '../../../analytics/AnalyticsContext';

function SorryLoss(props) {
    
    const { track, pageview, mpTrackWizard } = useAnalytics()

    useEffect(() => {
      track("Page View", {"Page Name": "Wizard - Sorry for loss"})
      pageview("/wizard/sorry-loss", [], "Wizard - Sorry for loss")
      // eslint-disable-next-line
    }, [])

    const saveStep2 = () => {
        props.wizardStep2(true)
        props.goto('/wizard/about-family')
        mpTrackWizard("Sorry for loss", true, true)
    }

    return(<RootContainer>
        <H2>We are sorry you are going through a difficult time.</H2>
        <H4>A time like this is never easy. And sometimes talking or thinking about it can be difficult.</H4>
        <H6>We need to ask a few questions to help with set up. This should take no more than 5 minutes.</H6>
        <ButtonStyled onClick={saveStep2}>Okay, I'm ready</ButtonStyled>
    </RootContainer>)
}

const mapStateToProps = (state) => {
    return {  step2: state.WizardReducer.step2 }
}

const mapDispatchToProps = {
    wizardStep2
}

export default connect(mapStateToProps, mapDispatchToProps)(SorryLoss);