export const SET_WEEK = "SET_WEEK"

export const WIZARD_STEP_1 = "WIZARD_STEP_1";
export const WIZARD_STEP_2 = "WIZARD_STEP_2";
export const WIZARD_STEP_3 = "WIZARD_STEP_3";
export const WIZARD_STEP_4 = "WIZARD_STEP_4";

export const SKIP_WIZARD_STEP_4 = "SKIP_WIZARD_STEP_4";


export const FETCH_TASK_SERIES = "FETCH_TASK_SERIES";
export const FETCHED_TASK_SERIES_SUCCESS = "FETCHED_TASK_SERIES_SUCCESS";
export const FETCH_TASK_SERIES_ERROR = "FETCH_TASK_SERIES_ERROR";

export const MODIFY_TASK_SERIES_CATEGORIES = "[CALENDAR] MODIFY_TASK_SERIES_CATEGORIES";
export const MODIFY_TASK_SERIES_CATEGORIES_SUCCESS = "[CALENDAR] MODIFY_TASK_SERIES_CATEGORIES_SUCCESS";
export const MODIFY_TASK_SERIES_CATEGORIES_ERROR = "[CALENDAR] MODIFY_TASK_SERIES_CATEGORIES_ERROR";

export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_RESET = "CREATE_BOOKING_RESET";

export const GET_BOOKINGS = "GET_BOOKINGS"
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS"
export const GET_BOOKINGS_ERROR = "GET_BOOKINGS_ERROR"
export const GET_BOOKINGS_RESET = "GET_BOOKINGS_RESET"

export const UPDATE_EXCEPTION = "UPDATE_EXCEPTION";
export const UPDATE_EXCEPTION_RESPONSE = "UPDATE_EXCEPTION_RESPONSE";
export const UPDATE_EXCEPTION_ERROR  = "UPDATE_EXCEPTION_ERROR";

export const ADD_EXCEPTION = "ADD_EXCEPTION";
export const ADD_EXCEPTION_RESPONSE = "ADD_EXCEPTION_RESPONSE";
export const ADD_EXCEPTION_ERROR = "ADD_EXCEPTION_ERROR";

export const DELETE_BOOKING = "DELETE_BOOKING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR  = "DELETE_BOOKING_ERROR";
export const DELETE_BOOKING_RESET = "DELETE_BOOKING_RESET";

export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";

export const ADD_TASK = "ADD_TASK"
export const ADD_TASK_RESPONSE = "ADD_TASK_RESPONSE"
export const ADD_TASK_ERROR = "ADD_TASK_ERROR"

export const GET_MISSION = "GET_MISSION"
export const GET_MISSION_RESPONSE = "GET_MISSION_RESPONSE"
export const GET_MISSION_ERROR = "GET_MISSION_ERROR"

export const UPDATE_TASK_SERIES = "UPDATE_TASK_SERIES"
export const UPDATE_TASK_SERIES_SUCCESS = "UPDATE_TASK_SERIES_SUCCESS"
export const UPDATE_TASK_SERIES_ERROR = "UPDATE_TASK_SERIES_ERROR"
export const UPDATE_TASK_SERIES_RESET = "UPDATE_TASK_SERIES_RESET"

export const DELETE_TASK_SERIES = "DELETE_TASK_SERIES"
export const DELETE_TASK_SERIES_SUCCESS = "DELETE_TASK_SERIES_SUCCESS"
export const DELETE_TASK_SERIES_ERROR = "DELETE_TASK_SERIES_ERROR"
export const DELETE_TASK_SERIES_RESET = "DELETE_TASK_SERIES_RESET"

// From contributor
export const GET_TP_TASKS = "GET_TP_TASKS"
export const GET_TP_TASKS_SUCCESS = "GET_TP_TASKS_SUCCESS"
export const GET_TP_TASKS_ERROR = "GET_TP_TASKS_ERROR"

export const FETCH_RECENT_NOTES = "FETCH_RECENT_NOTES"
export const FETCH_RECENT_NOTES_SUCCESS = "FETCH_RECENT_NOTES_SUCCESS"
export const FETCH_RECENT_NOTES_FAILURE = "FETCH_RECENT_NOTES_FAILURE"

// Email
export const INVITE_CONT = "INVITE_CONT"
export const INVITE_CONT_SUCCESS = "INVITE_CONT_SUCCESS"
export const INVITE_CONT_FAILURE = "INVITE_CONT_FAILURE"
export const INVITE_CONT_RESET = "INVITE_CONT_RESET"

export const INVITE_REC = "INVITE_REC"
export const INVITE_REC_SUCCESS = "INVITE_REC_SUCCESS"
export const INVITE_REC_FAILURE = "INVITE_REC_FAILURE"
export const INVITE_REC_RESET = "INVITE_REC_RESET"

//InvitesAndMembers
export const GET_INVITES_AND_MEMBERS = "GET_INVITES_AND_MEMBERS";
export const INVITE_AND_MEMBER_RESPONSE = "INVITE_AND_MEMBER_RESPONSE";
export const UPDATE_INVITES_AND_MEMBERS = "UPDATE_INVITES_AND_MEMBERS";
export const UPDATE_INVITES_AND_MEMBERS_RESPONSE = "UPDATE_INVITES_AND_MEMBERS_RESPONSE";