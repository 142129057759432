import React, { createContext, useContext, useEffect, useState } from 'react';
import Mixpanel from './Mixpanel';
import GAnalytics from './GAnalytics';
import { hashUserUuid } from '../shared-lib/utils/url_util';

export const AnalyticsContext = createContext();
export const useAnalytics = () => useContext(AnalyticsContext);

const UserType = {
    org: "Organizer",
    cont: "Contributor",
    rec: "Recipient"
}

const Events = {
    login: "Login",
    signup: "Sign Up",
    pageView: "Page View",
    completeStep: "Complete Wizard Step",
    skipStep: "Skip Wizard Step",
    addDate: "Add Date",
    invitePeople: "Invite People",
    pauseVillage: "Pause Village",
    deleteTaskSeries: "Remove Task Series",
    createTaskSeries: "Add Task Series",
    updateTaskSeries: "Modify Task Series",
    createBooking: "Volunteer",
    deleteBooking: "Cancel Task",
    addToCalendar: "Add to Calendar",
}

const Props = {
    taskType: "Task type",
    userType: "User type",
    isEmailEnabled: "Accept email",
    lifeVolunteer: "Lifetime volunteer",
    lifeCancel: "Lifetime cancellation",
    firstVolunteer: "First volunteer",
    lastVolunteer: "Last volunteer",
    stepName: "Step name",
    stepSelect: "Step selection",
}

const InviteMethod = {
    twitter: "Twitter",
    facebook: "Facebook",
    link: "Link",
    email: "Email"
}

const CalendarType = {
    google: "Google",
    apple: "Apple"
}

export const AnalyticsProvider = ({children}) => {

    const [mixpanelUuid, setMixpanelUuid] = useState()

    const mpTrackSignup = (userUuid, role, isEmailEnabled = false) => {
        setMixpanelUuid(userUuid)
        const id = hashUserUuid(userUuid)
        setMixpanelUuid(id)
        Mixpanel.alias(id)
        Mixpanel.identify(id)
        Mixpanel.people.set({
            [Props.userType]: UserType[role],
            [Props.isEmailEnabled]: isEmailEnabled
        })
        Mixpanel.register({[Props.userType]: UserType[role]})
        Mixpanel.track(Events.signup)
    }

    const mpTrackLogin = (role) => {
        Mixpanel.track(Events.login, {
            [Props.userType]: UserType[role]
        })
    }

    const mpTrackLogout = () => {
        Mixpanel.reset()
    }

    const mpSetIdentity = (args, type) => {
        if (type === "user") {
            const userUuid = args && args.user_uuid
            const email = args && args.email
            const name = args && args.name
            const id = hashUserUuid(userUuid)
            Mixpanel.identify(id)
            Mixpanel.people.set({ "email": email, "name": name });
            setMixpanelUuid(id)
        }
        if (type === "membership") {
            const role = args && args.role
            Mixpanel.register({
                [Props.userType]: UserType[role]
            })
        }
    }

    const mpTrackBooking = (args, method) => {
        if (method === "create") {
            Mixpanel.people.set_once({
                [Props.firstVolunteer]: new Date().toISOString()
            })
            Mixpanel.people.set({
                [Props.lastVolunteer]: new Date().toISOString(),
            })
            Mixpanel.people.increment(Props.lifeVolunteer, 1)
            Mixpanel.identify(mixpanelUuid);
            Mixpanel.track(Events.createBooking, {
                [Props.taskType]: args.title,
            })
        } else {
            Mixpanel.track(Events.deleteBooking, {
                [Props.taskType]: args.booking.title
            })
            Mixpanel.people.increment(Props.lifeCancel, 1)
            Mixpanel.identify(mixpanelUuid);
        }
    }

    const mpTrackTaskSeries = (args, method) => {
        switch(method) {
            case "create":
                const other = args.other && args.other.items
                if (other.length > 0) {
                    other.map(i => {
                            if (!i.seriesId) {
                                Mixpanel.track(Events.createTaskSeries, {
                                    [Props.taskType]: i.title
                            })
                        }
                    })
                }
                Mixpanel.track(Events.createTaskSeries, {
                    [Props.taskType]: args.title
                })
                break;
            case "delete":
                Mixpanel.track(Events.deleteTaskSeries, {
                    [Props.taskType]: args.title
                })
                break;
            case "create/delete":
                args.map(i => {
                    if (i.method === "create"){
                        Mixpanel.track(Events.createTaskSeries, {
                            [Props.taskType]: i.name || i.title
                        })
                    } else {
                        Mixpanel.track(Events.deleteTaskSeries, {
                            [Props.taskType]: i.name || i.title
                        })
                    }
                })
                break;
            case "update":
                Mixpanel.track(Events.updateTaskSeries, {
                    [Props.taskType]: args.title,
                    "Change frequency": args.frequenceChange,
                    "Selected third party": args.thirdPartyAllow
                })
                break;
            default:
        }
    }

    const mpTrackWizard = (stepName, isComplete, selected = null) => {
        if (isComplete) {
            Mixpanel.track(Events.completeStep, {
                [Props.stepName]: stepName,
                [Props.stepSelect]: selected
            })
        } else {
            Mixpanel.track(Events.skipStep, {
                [Props.stepName]: stepName
            })
        }
    }

    return (
        <AnalyticsContext.Provider value={{
            track: (...p) => Mixpanel.track(...p),
            pageview: (...p) => GAnalytics.pageview(...p),
            mpTrackSignup: (...p) => mpTrackSignup(...p),
            mpTrackLogin: (...p) => mpTrackLogin(...p),
            mpTrackLogout: (...p) => mpTrackLogout(...p),
            mpTrackBooking: (...p) => mpTrackBooking(...p),
            mpTrackTaskSeries: (...p) => mpTrackTaskSeries(...p),
            mpSetIdentity: (...p) => mpSetIdentity(...p),
            mpTrackWizard: (...p) => mpTrackWizard(...p)
        }}>
            {children}
        </AnalyticsContext.Provider>
    )
}
