
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import IconButton from '../../shared-lib/components/IconButton';
import _ from 'lodash';
import NotesHolder from './BookingNotesHolder';

const BookingListItem = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 14px;
    border-radius: 5px;
    border: solid 1px #ccc;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
`
const BookingListRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width 100%;
    align-items: center;
    position: relative;

    & > h3 {
        color: #555555;
        font-family: var(--font-mrEaves);
        font-size: 21px;
        border-right: 1px solid #cccccc;
        padding: 8px 10px;
        margin: 0px;
        font-weight: 400;
        text-align: left;
    }

    & .booking-title {
        text-align: left;
    }

    & .notesHolder {
        padding: 7px 5px;
        width: 100%;
        border-top: 1px solid #cccccc;
    }

    @media (max-width: 375px) {
        flex-direction: column;
        & h3 {
            border: none;
            width: 100%;
        }

        & .booking-title {
            border-top: 1px solid #cccccc;
            width: 100%;
        }

        & button {
            position: absolute;
            top: 5px;
            right: -5px;
            margin: 5px;
        }
    }
`
const NoUpcoming = styled.p`
    text-align: center;
    color: #111111;
    font-size: 18px;
    margin: 10px;
    font-style: italic;
`

const BookingList = ({
    openConfirmDialog,
    mission_uuid,
    bookings = [], 
}) => {

    const filtered = bookings
                    .filter(i => i.mission_uuid === mission_uuid)
                    .sort((a, b) => {
                        const leftDate = moment(a.date)
                        const rightDate = moment(b.date)
                        return (leftDate - rightDate)
                    })

    const isEmpty = filtered.length === 0

    return (<ul style={{width: '100%', padding: 0, margin: 0}}>
        { isEmpty ? <NoUpcoming>No upcoming tasks</NoUpcoming>: <div></div>}
        {
            filtered.map((item, idx) => {
                console.log('item', item);
                return (
                    <BookingListItem key={idx}>
                        <BookingListRow>
                        <h3 style={{minWidth: 120}}>{moment(item.date).utc().format("MMMM D")}</h3>
                        <h3 style={{flexGrow: 1}} className="booking-title">{item.title}</h3>
                        <IconButton onClick={() => openConfirmDialog(item)} variant="small" style={{margin: '0px 10px'}}/>
                        </BookingListRow>
                        <BookingListRow>
                        <NotesHolder notes={item.note === 'No additional notes' ? null : item.note}></NotesHolder>
                        </BookingListRow>
                    </BookingListItem>
                )
            })
        }
    </ul>)
}

export default BookingList;