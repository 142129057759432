import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    z-index:100; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:${props => props.radius};
    background-color: ${props => props.backgroundFill};
    '2px solid #69469d'
    border-width: 2px;
    border-style: 'solid';
    outline: none;
    border-color: ${props => props.borderColor};

    :hover {
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
        background-color: ${props => props.disabled ? '#ffffff' : props.hoverFill};
        border-color: ${props => props.disabled ? props.disabledBorder : props.borderHover};
        
    }

    :disabled {
        border:  ${props => '1px solid ' + props.disabledBorder};
        background-color: #ffffff;
    },

    & > svg {
        fill: ${props => props.disabled ? '#cccccc' : props.iconFill};
    }

    :hover svg {
        fill: ${props => props.disabled ? '#cccccc' : props.iconHoverFill};
    }
`

const Path = (icon) => {
    if (icon.icon === 'plus') {
        return (
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
        )
    } else if (icon.icon === 'close'){
        return (
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        ) 
    }
}                 

//iconHeight = '18px', iconWidth = '18px', height = '30px', width = '30px'
function CustomIconButton({disabled = false, onClick = () => {}, style = {}, dimens = {}, colors={}, icon = "plus" }) {

    let iconHeight = '18px';
    let iconWidth = '18px';
    let height = '30px';
    let width = '30px';

    const iconFill = colors.iconFill || '#69469d';
    const hoverFill = colors.hoverFill || '#060368';
    const borderColor = colors.borderColor || iconFill;
    const iconHoverFill = colors.iconHoverFill || '#ffffff'
    const backgroundFill = colors.background || '#ffffff';
    const borderHover = colors.borderHover || iconHoverFill;
    const disabledBorder = colors.disabledBorder || '#cccccc';

    iconHeight = dimens.iconHeight || iconHeight;
    iconWidth = dimens.iconWidth || iconWidth;
    height = dimens.height || height;
    width = dimens.width || width;
    const radius = '' + parseInt(width.replace('px', '')) / 2 + 'px';
   
    return (
        <Button 
            type="button"
            disabled={disabled} 
            iconFill={iconFill}
            hoverFill={hoverFill}
            iconHoverFill={iconHoverFill}
            borderColor={borderColor}
            borderHover={borderHover}
            backgroundFill={backgroundFill}
            disabledBorder={disabledBorder}
            radius={radius}
            style={{...style, width, height}}
            onClick={onClick}>
            <svg style={{
                width: iconWidth,
                height: iconHeight
            }}
            overflow="visible" 
            preserveAspectRatio="none" 
            viewBox="0 0 24 23">
                <Path icon={icon}/>
            </svg>
        </Button>
    )
}

export default CustomIconButton;