import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

const TabBar = styled.div`

  display: flex;
  flex-direction: row;
  height: 60px;
  width: 280px;
  justify-content: space-evenly;
  visibility: ${props => props.visibility}; 
`

const Tab = styled.div`
  z-index:100;
  width: auto;
  height: 60px;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: ${props => props.checked ? '4px solid #69469d' : 'none'};
  font-weight: ${props => props.checked ? 'bold' : 'normal'};
  
  & :hover {
    cursor: pointer;
  }

  & > label {
    width: auto;
    text-align: center;
    font-family: var(--font-mrEaves);
    color: #69469d;
    font-size: 21px;
  }

  & > .spacer  {
    height: ${props => props.checked ? '6px' : '10px'};
  }

`

function DashboardTabbar() {

  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(0);
  const { url } = useRouteMatch();
  const history = useHistory()

  useEffect(() => {
    if (pathname.includes("tasks") && activeTab !== 0) {
      setActiveTab(0)
    } else if (pathname.includes("villages") && activeTab !== 1) {
      setActiveTab(1);
    } else if (pathname.includes("account") && activeTab !== 2){
      setActiveTab(2);
    }
  },[pathname, activeTab])

    return (
        <TabBar>
          <Tab checked={activeTab === 0} onClick={() => {history.push(`${url}/dashboard/tasks`)}}>
            <label>Upcoming tasks</label>
            <div className="spacer"/>
          </Tab>
          <Tab checked={activeTab === 1} onClick={() => {history.push(`${url}/dashboard/villages`)}}>
            <label>Manage villages</label>
            <div className="spacer"/>
          </Tab>
          <Tab checked={activeTab === 2} onClick={() => {history.push(`${url}/dashboard/account`)}}>
            <label>Account settings</label>
            <div className="spacer"></div>
          </Tab>
        </TabBar>
    )
}

export default DashboardTabbar;