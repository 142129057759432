import { createStore, compose, applyMiddleware} from 'redux';
import { villageMiddleWare } from './middlewares/village';

import combinedReducers from './reducers';

const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null
var enhancer;
if (process.env.NODE_ENV === 'development') {
    enhancer = compose(
        applyMiddleware(villageMiddleWare),
        devTools)
} else {
    enhancer = compose(
        applyMiddleware(villageMiddleWare),
    ) 
}

export default createStore(combinedReducers, enhancer)
