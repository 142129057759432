import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Picker from './RolePicker';
import { connect } from 'react-redux';
import {updateInvitesAndMembers} from '../../../data/actions';
import { useUser } from '../../../auth/UserContext';
import {Status} from '../../../data/api';

const ManageMembersContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    height: auto;

    & h2 {
        font-family: ${props => props.theme.mrEaves}; 
        font-size: 24px;
        margin: 0px 20px 20px 20px;
        text-align: center;
    }
`

const MembersTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    height: auto;
    border: 1px solid #111;
    border-radius: 5px;
`

const MembersRow = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    border-bottom: 1px solid #ccc;
    align-items: center;

    : last-child {
        border-bottom: none;
    }
`

const MembersNameHeader = styled.div`
    display: block;
    width: 70%;
    padding 0px 10px;
    border-right: 1px solid #ccc;

    & p {
        font-family: ${props => props.theme.mrsEaves}; 
        font-size: 18px;
        text-align: center;
        display:inline-block;
    }

    @media (max-width: 500px) {
        width: 50%;
    }
`

const MembersRoleHeader = styled.div`
    display: flex;
    width: 30%;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;

    & p {
        font-family: ${props => props.theme.mrsEaves}; 
        font-size: 18px;
        text-align: center;
        display:inline-block;
    }

    @media (max-width: 500px) {
        width: 50%;
    }
    
`

const InfoIcon = styled.div`
    display: none;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    border: 1px solid var(--color-dark-purple);
    background-color: var(--color-light-purple);
    color: border: 1px solid var(--color-dark-purple);
    font-size: 14px;
    margin-left: 5px;
    text-align: center;

    :hover {
        cursor: pointer;
    }
    :hover .tooltip {
        visibility: visible;
    }

`

const MembersNameCell = styled.div`
    display: block;
    width: 70%;
    padding 0px 10px;
    border-right: 1px solid #ccc;
    font-family: var(--font-mrEaves);
    font-size: 24px;

    @media (max-width: 500px) {
        width: 50%;
        font-size: 20px;
    }
`

const MembersRoleCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
        width: 50%;
    }
    
`



const ManageMembers = ({membershipList, loading, updateInvitesAndMembers, setError, error}) => {

    const [editingRole, setEditingRole] = useState(null);
    const { membership} = useUser()

    const setNewRole = (idx, role) => {
        const mem = membershipList[idx]
        if (mem.role === role) {
            setEditingRole(null);
            return
        }

        const updateData = {
            user_uuid: mem.user_uuid,
            mission_uuid: membership.mission_uuid,
            role: role
        }
        updateInvitesAndMembers(updateData)
    }

    useEffect(() => {
        if (!loading) {
            setEditingRole(null);
        }
    }, [loading])

    useEffect(() => {
        if(setError){
            setError(error)
        }
    }, [error, setError])


    return(
        <ManageMembersContainer>
        <MembersTableContainer>
            <MembersRow>
                <MembersNameHeader><p>Name</p></MembersNameHeader>
                <MembersRoleHeader>
                    <p>Role</p>
                    <InfoIcon>?
                    </InfoIcon>
                    </MembersRoleHeader>
            </MembersRow>
            {membershipList.map((member, idx) => {
                console.log(member);
                return member.role === 'org' ? null : (
                    <MembersRow key={idx}>
                        <MembersNameCell><p>{member.name}</p></MembersNameCell>
                        <MembersRoleCell>
                            <Picker 
                            onClick={()=>{setEditingRole(editingRole === idx ? null : idx)}} 
                            expanded={editingRole && editingRole === idx && !loading}
                            loading={loading && editingRole && editingRole === idx} 
                            role={member.role}
                            setRole={(r) => {setNewRole(idx,r)}}
                            />
                        </MembersRoleCell>
                    </MembersRow>
                )
            })}
        </MembersTableContainer>
        </ManageMembersContainer>
    )
}

const mapStateToProps = (state) => {
    const invitesAndMembers = state.VillageReducer.invitesAndMembers.data
    const membershipList = (invitesAndMembers && invitesAndMembers.members) || []

    return {
        membershipList,
        loading: state.VillageReducer.invitesAndMembers.status === Status.loading,
        error: state.VillageReducer.invitesAndMembers.error
    }
} 

const mapDispatchToProps = { 
    updateInvitesAndMembers
  };

  
  export default connect(mapStateToProps, mapDispatchToProps)(ManageMembers);
  