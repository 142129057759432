import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from '../../auth/UserContext';
import FormField from '../../shared-lib/components/user_entry/FormField';
import {ActivityIndicator} from '../../shared-lib/components/general/ActivityIndicator';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {StatusBanner} from '../../shared-lib/components/general/StatusBanner';
import { Status } from '../../data/api';

const TEN_STARS = "**********"

const CancelButton= styled.button`
    min-width: 100;
    border-width: 2px;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves}; 
    width: 140px;
    height: 47px;
    border-radius: 5px;
    background-size: cover;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    background-color: #ffffff;
    border: solid #69469d 2px;
    color: #69469d;
    margin: 40px 10px 30px 20px;
`

const SaveButton = styled.button`
    min-width: 100;
    background-color: #69469d;
    color: white;
    text-transform: none;
    font-family: ${props => props.theme.mrEaves};
    width: 140px;
    height: 47px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-size: 21px;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    line-height: 47px;
    white-space: nowrap;
    border: solid #69469d 2px;
    margin: 40px 20px 30px 10px;
`

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
`

const Title = styled.h1`
    color: var(--color-savo-purple);
`

const Subtitle = styled.h6`
    font-family: var(--font-mrsEaves); 
    color: #111111;
`

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;
    width: calc(100vw - 60px);
    max-width: 500px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 20px 20px;
    }
`

const InputLabel = styled.div`
    height: 21px;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #060368;
    font-style: italic;
    font-weight: bold;
    margin: 10px 0 0 0;
`

const InputText = styled.p`
    height: 26px;
    font-family: var(--font-mrsEaves); 
    font-size: 18px;
    color: #111111;
    line-height: 26px;
    margin: 10px 0 10px 0;
`

const SwtichWrapper = styled.div`
    display: flex;
    flex-direction: column;

`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`


const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }


    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
  
    input:checked + .slider {
        background-color: var(--color-savo-purple);
    }
  
    input:focus + .slider {
        box-shadow: 0 0 1px var(--color-savo-purple);
    }
  
    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
  
    .slider.round {
        border-radius: 34px;
    }
  
    .slider.round:before {
        border-radius: 50%;
    }
`
const FlexForm = styled.form`


`

const EmailWrapper = styled.div`
    display: flex;
    flex-direction: row;

    & .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    & h6 {
        color: #060368;
        font-size: 30px;
        margin: 0 20px;
        font-family: var(--font-mrsEaves);
        text-align: left;
    }

    & p {
        font-size: 18px;
        margin: 0 20px;
    }
`
function AccountPage (props) {

    const {user, updateUserInfo, fetchUserAndRoles, membership } = useUser()
    const email = user && user.email
    const name = user && user.name
    const allowEmail = user && user.allow_email
    const mission_uuid = membership && membership.mission_uuid

    // Mark: - local states
    const [updateUserInfoState, setUpdateUserInfoState] = useState({
        status: Status.idle, response: null, error: null
    });

    const handleUpdateUser = async (values) => {
        try {
            
            setUpdateUserInfoState({
                status: Status.loading, response: null, error: null
            })
            await updateUserInfo({
                allowEmail: values.allowEmail, 
                email,
                password: values.password === TEN_STARS ? null : values.password ,
                name: values.name
            })
            await fetchUserAndRoles(email, mission_uuid)
            setUpdateUserInfoState({
                status: Status.success, response: "success", error: null
            })
        } catch (error) {
            console.trace(error)
            setUpdateUserInfoState({
                status: Status.failure, response: null, error: error 
            })
        }
    }

    const initialValues = {
        name: name,
        password: "",
        allowEmail: allowEmail,
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required('Name is required'),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must contain 8 characters, one uppercase, one Lowercase, one number and one special case character"
          ),
        allowEmail: Yup.bool().default(false)
    });

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleUpdateUser,
        validationSchema: validationSchema
    })

    useEffect(() => {
        if (email && mission_uuid) {
            fetchUserAndRoles(email, mission_uuid)
                .catch(error => console.error(error))
                .then(_ => {
                    formik.resetForm();
                })
        }
    }, [email, mission_uuid])

    const info = updateUserInfoState.response ? "Your settings have been updated." : null
    const errorMessage = updateUserInfoState.error ? "There was a problem with the request." : null

    return (<Root>
        <StatusBanner info={info} error={errorMessage}></StatusBanner>
        <FlexForm onSubmit={formik.handleSubmit}>
        <Title>My account settings</Title>
        <Subtitle>Update your communication preferences</Subtitle>
        <FormWrapper>
        <FormField
            config={{
                title: "Name",
                touched: formik.touched.name,
                error: formik.errors.name,
                onBlur: formik.handleBlur,
                id: "account_name",
                name: "name",
                onChange: formik.handleChange,
                type: "text",
                value: formik.values.name
            }}
        />
        <FormField
            config={{
                title: "Password",
                touched: formik.touched.password,
                error: formik.errors.password,
                onBlur: formik.handleBlur,
                id: "account_password",
                name: "password",
                onChange: formik.handleChange,
                type: "password",
                value: formik.values.password,
                placeholder: TEN_STARS
            }}
        />
        <InputLabel>Email Address</InputLabel>
        <InputText>{email}</InputText>
        <EmailWrapper>
            <SwtichWrapper>
                <Switch>
                    <input 
                        type="checkbox" 
                        name="allowEmail" 
                        checked={formik.values.allowEmail} 
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}/>
                    <span className="slider round"></span>
                </Switch>
                <p>{formik.values.allowEmail ? 'on' : 'off'}</p>
            </SwtichWrapper>
            <div className="info">
            <h6>Allow Savo to send me email</h6>
            <p>Includes reminders about upcoming tasks and weekly digests for your villages.</p>
            </div>
        </EmailWrapper>
        </FormWrapper>
        <div style={{justifyContent: 'center', display:'flex'}}>
        <ActivityIndicator active={updateUserInfoState.status === Status.loading}>
            <ButtonWrapper>
            <CancelButton type="button" onClick={() => formik.resetForm()}>
                Cancel
            </CancelButton>
            <SaveButton type="submit" enabled={formik.isValid && !formik.isSubmitting}>
                Save
            </SaveButton>
            </ButtonWrapper>
        </ActivityIndicator>
        </div>
        </FlexForm>
    </Root>)
}

export default AccountPage;
