import styled from 'styled-components';

const FlexForm = styled.form`
    width: 100%;
    min-height: 375px;
    display: ${props => props.isHidden ? "none" : "flex"};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;

    @media(max-width: 675px) {
        margin: 0;
    }
`

export default FlexForm;