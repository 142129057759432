import React from 'react';
import {
    Content, 
    Title, 
    Subtitle, 
    RemoveButton, 
    CancelButton,
    ButtonsBar 
} from './cancelTaskDialog.styles';
import moment from 'moment';
import Modal from '../../../shared-lib/components/general/Modal'

export default ({
    isOpen, cancelHandler, removeHandler, bookingItem = {}
}) => {

    const { title = '', date } = bookingItem;
    const formattedDate = moment(date).utc().format('MMMM DD');
    const doRemovalHandler = () => {
        removeHandler(bookingItem);
    };
    
    return (
        <Modal open={isOpen} handleClose={cancelHandler}>
            <Content>
                <Title>Are you sure you want to remove this {title} task for {formattedDate}?</Title>
                <Subtitle>This will remove the task from your list and re-open it for volunteering in the village. 
                A note will be sent to the organizer to let them know.</Subtitle>
                <ButtonsBar>
                    <CancelButton onClick={cancelHandler}>I want to keep this task</CancelButton>
                    <RemoveButton onClick={doRemovalHandler}>Yes, remove task</RemoveButton>
                </ButtonsBar>
            </Content>
        </Modal>
    );
};
