import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useUser } from '../../auth/UserContext';
import { useAuth0 } from '../../shared-lib/auth-provider';
import loading from '../../auth/loading.svg'
import env from '../../.env';
import { useAnalytics } from '../../analytics/AnalyticsContext';

const isProduction = env.connection === "production"

const Spinner = <div style={{
    display: "flex",
    height: '100vh',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
}}>
    <img src={loading} alt="loading"/>
</div>

/*
    this page will be replace by the marketing page, and then being working
    as redirect to either /login route or /:mission_uuid/calendar route 
    based on if the user has authenticated or not
*/
function Home (props) {

    const history = useHistory()
    const { isAuthenticated, isRestoring, login, logout } = useAuth0()
    const { membership, logoutUser } = useUser()
    const [toApp, setToApp] = useState(false);
    const { mpTrackLogout } = useAnalytics()

    const loginUser = () => {
        history.push("/login")
    };

    const handleLogout = () => {
        mpTrackLogout()
        logoutUser()
    };

    const redirectToApp = (isAuthenticated) => {
        if (isAuthenticated && membership) {
            setToApp(true)
        } else {
            setToApp(false)
        }
    }


    if (isProduction) {
        if (isRestoring) {
            return Spinner
        } else {
            if (!isAuthenticated) {
                window.location.href = "https://www.joinsavo.com"
                return Spinner
            } else {
                history.push('/mission/dashboard/tasks')
            }
        }
    }

    return(
        <div style={{ height: '100vh', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button onClick={() => redirectToApp(isAuthenticated)}>{ isAuthenticated ? "authenticated: go to calendar" : "Not Authenticated" }</button>
            { toApp ? <Redirect from="/" to={`/${membership.mission_uuid}`} /> : null }
            <h1>Home Page: JoinSavos</h1>
            <p>This page will be repaced by the marketing page</p>
            <button style={{margin: 20, height: 50}} onClick={loginUser}>Sign in</button>
            <button style={{margin: 20, height: 50}} onClick={handleLogout}>Logout</button>
            <button style={{margin: 20, height: 50}} onClick={() => history.push("/wizard")}>Go to wizard and start as new user</button>
        </div>
    )
}

export default Home;