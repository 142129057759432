export const getLocalItem = (KEY) => {
  const str = localStorage.getItem(KEY)
  return str ? JSON.parse(str) : null
}

export const setLocalItem = (KEY, value) => {
  const str = JSON.stringify(value)
  localStorage.setItem(KEY, str)
}

export const deleteLocalItem = (KEY) => {
  localStorage.removeItem(KEY)
}

export const MEMBERSHIP = "membership";
export const USER = "user";
export const ROLES = "roles";
export const AUTH_SESSION = 'auth_session';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const SIGNUP_NEW_USER = "sign_up_new_user";
export const SIGNIN_EXIST_USER = "sign_in_exist_user";
export const CHECK_MEMBERSHIP = "check_membership";