import environment from '../../.env';
import hash from 'object-hash';

export const INVITE_URL_ROOT_PATH = `${environment.HOST_URL}/signup`;


// takes an object with all the inputs for an organizer invite url, checks environment and returns a url that can be handled as a route
export const generateOrganizerInviteUrl = (input) => {

    const campaign = input.role === 'org' ? 'invite_organizer' : 'invite_recipient';
    const medium = input.medium || 'email'
    const source = input.source || 'product';

    const base = `${INVITE_URL_ROOT_PATH}?role=${input.role}&mission=${input.mission}`;
    const tracking = `&utm_campaign=${campaign}&utm_source=${source}&utm_medium=${medium}`

    return base + tracking;
}



// takes a string representing the query params of a url and parses out the name portion, decodes and returns
// import in component that needs to display name, pass it url from component's props
export const getPersonPassed = (search) => {
    const encodedName = getValueFromSearchParams(search,'id');
    return encodedName ? decodeString(encodedName) : null;
}

export const getRole = (search) => {
    return getValueFromSearchParams(search, 'role')
}

export const getMissionUuid = (search) => {
    return getValueFromSearchParams(search, 'mission')
}

export const getInviteUuid = (search) => {
    return getValueFromSearchParams(search, 'invite')
}

const getValueFromSearchParams = (params, key) => {
    const matches = params.match(key + '=([^&]*)');
    if (matches && matches.length > 1) {
        return matches[1];
    } else {
        return null;
    }
}

// helper functions
// takes the portion of the path that contains the encoded string
const decodeString = (str) => {
    // do some logic to pull value from encoded string and return
    return decodeURIComponent(escape(atob(str)));
}

// encodes any arbitrary string using encoding logic
const encodeString = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
}

export const createSharableContributorLink = ({mission, personPassed, medium, source}) => {
    const encodedPersonPassed = encodeString(personPassed)
    const mediumArg = medium || 'email'
    const sourceArg = source || 'product';
    const base = `${INVITE_URL_ROOT_PATH}?mission=${mission}`
    const tracking = `&utm_campaign=invite_contributor&utm_source=${sourceArg}&utm_medium=${mediumArg}`;
    return base + tracking;
}

export const hashUserUuid = (userUuid) => {
    const hashed = hash(userUuid)
    return hashed
}