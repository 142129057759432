import * as Yup from 'yup';

/*Non-empty password required
* Contains the following 4 types of characters:
 * lower case letters (a-z)
 * upper case letters (A-Z)
 * numbers (i.e. 0-9)
 * special characters (e.g. !@#$%^& *)
 * */

function lengthTest(str) {
    return str == null ? false : (str.length >= 8);
}

function numTest(str) {
    return /^\d+$/.test(str);
}

function capTest(str) {
    if (!numTest(str) && !specialCharTest(str)) {
        return str != null && str != "" && str == str.toUpperCase();
    }
}

function lowerTest(str) {
    if(!numTest(str) && !specialCharTest(str)){
        return str != null && str != "" && str == str.toLowerCase();
    }
}

function specialCharTest(str) {
    const chars = ["!", "@", "#", "$", "%", "^", "&", "*"];
    return chars.indexOf(str) > -1;
}

const requiredChars = (value) => {

    if (value == null) {
        return true;
    }

    const tests = [numTest, capTest, lowerTest, specialCharTest];

    const accum_conjunct = (bool1, bool2) => {
        return bool1 && bool2;
    }

    return tests.map(test => {
        return wrapTest(test)(value)
    }).reduce(accum_conjunct, true);
}

export const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Name is required'),
    email: Yup.string()
      .email('Email invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password is too short')
      .required('Password is required')
}); 

export const passwordRequirements = [
    {
        name:'At least 8 characters',
        test: lengthTest
    },
    {
        name: 'One or more numbers (0-9)',
        test: wrapTest(numTest)
    },
    {
        name: 'One or more lowercase letters (a-z)',
        test: wrapTest(lowerTest)
    },
    {
        name: 'One or more uppercase letters (A-Z)',
        test: wrapTest(capTest)
    },
    {
        name: 'One or more special characters (!@#$%^&*)',
        test: wrapTest(specialCharTest)
    }
]

// applies test to each char in a string and returns true if any satisfy the test, else false
function wrapTest(test) {
    const accum_disjunct = (bool1, bool2) => {
        return bool1 || bool2;
    }
    return (str) => {
        return str.split("").map(char => {
            return test(char);
        }).reduce(accum_disjunct, false);

    }
}
