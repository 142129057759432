import React, { useState, useEffect } from 'react'
import WhoseLoss from './Step1'
import SorryLoss from './Step2'
import AboutFamily from './Step3'
import HowToHelp from './Step4'
import Schedule from './Step5'
import EditTask from './Step6'
import Stepper from '../Stepper'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import VillageLogo from '../../shared-lib/assets/village-logo'
import Footer from '../../shared-lib/components/general/Footer'
import InviteFriends from './Step7'

const parseStep = (page) => {
  switch (page) {
    case '/wizard/whose-loss':
      return -2
    case '/wizard/sorry-loss':
      return -1
    case '/wizard/about-family':
      return 1
    case '/wizard/how-to-help':
      return 2
    case '/wizard/schedule':
      return 3
    case '/wizard/village-ready':
      return 4
    default:
      return -2
  }
}

function Wizard (props) {
  const [activeStep, setActiveStep] = useState(0)
  const [showStepper, setShowStepper] = useState(false)
  const { pathname } = useLocation()

  const goto = (page) => {
    props.history.push(page)
  }



  useEffect(() => {
    const step = parseStep(pathname)
    setActiveStep(step)
    if (step >= 0) {
      setShowStepper(true)
    } else {
      setShowStepper(false)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname])

  return (
    <div className="wiz-root">
      <div className="wiz-header">
        <img src={VillageLogo} alt="beta logo" style={{ height: 20 }}/>
      </div>
      {showStepper
        ? <Stepper activeStep={activeStep} />
        : <div className="wiz-stepper-empty" />
      }
      <Switch>
        <Route exact path="/wizard" render={() =>  <Redirect to="/wizard/whose-loss" /> } />
        <Route path="/wizard/whose-loss" render={(props) => <WhoseLoss {...props} goto={goto}/>} />
        <Route path="/wizard/sorry-loss" render={(props) => <SorryLoss {...props} goto={goto}/>} />
        <Route path="/wizard/about-family" render={(props) => <AboutFamily {...props} goto={goto}/>} />
        <Route path="/wizard/how-to-help" render={(props) => <HowToHelp {...props} goto={goto}/>} />
        <Route path="/wizard/schedule" render={(props) => <Schedule {...props} goto={goto} />} />
        <Route path="/wizard/edit-task" render={(props) => <EditTask {...props} goto={goto} />} />
        <Route path="/wizard/invite-friends" render={(props) => <InviteFriends {...props} goto={goto} />} />
      </Switch>
      <Footer/>
    </div>
  )
}

export default Wizard
