import React from 'react';
import styled from 'styled-components';

const BorderedBox = styled.div`
    min-width: 100%;
    border: 1px solid ${({isError}) => isError ? '#c76b44' : '#91b77a'};
    background-color: ${({isError}) => isError ? 'rgba(199,107,68,.1)' : 'rgba(145, 183, 122, 0.1)'};
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: ${({isError}) => isError ? '#a95632' : '#5b7750'};
    text-decoration: none solid rgb(169, 86, 50);
    text-align: center;
    align-self:center;
`

function ServerBanner({message, isError=true}) {

    if (message) {
        return ( 
            <BorderedBox isError={isError}>
                <p>{message}</p>
            </BorderedBox>
        );
    } else {
        return null;
    }
}

export default ServerBanner;