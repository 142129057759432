import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    'IconButtonPlus-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '47px',
        height: '47px',
        borderRadius: '23.5px',
    },
    primary: {
        backgroundColor: '#69469d',
        border: 'none',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#060368'
        },
        '&:active': {
            border: '2px solid #ffffff'
        },
        '&:focus' : {
            outline:'none'
        },
        '&:disabled': {
            border: '1px solid #cccccc',
            backgroundColor:' #ffffff'
        },
        '&:disabled > svg': {
            fill: '#cccccc'
        },
        '& > svg': {
            fill: 'white'
        },
    },
    secondary: {
        backgroundColor: 'rgba(6, 3, 104, 0.3)',
        border: '2px solid #060368',
        '&:hover': {
            cursor: 'pointer'
        },
        '& > svg': {
            fill: '#060368'
        },
        '&:focus' : {
            outline:'none'
        },
    },
})


function IconButtonPlus({ variant = "primary", disabled = false, onClick = () =>{}, style = {}, width, height}) {
    const classes = useStyles()
    return (
        <button 
            disabled={disabled} 
            className={clsx(classes['IconButtonPlus-root'],classes[variant])}
            style={style}
            onClick={onClick}>
            <svg style={{
                width: width,
                height: height,
                backgroundSize: `${width} ${height}`
            }}
            overflow="visible" 
            preserveAspectRatio="none" 
            width="30" 
            height="30" 
            viewBox="0 0 24 24">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
            </svg>
        </button>
    )
}

export default IconButtonPlus;
