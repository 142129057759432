import React, { useMemo } from 'react'
import styled from 'styled-components';
import {passwordRequirements} from '../../utils/validation';

const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
`

const Input = styled.input`
    width: ${props => props.width};
    height: 45px;
    padding: 5px 10px;
    border: ${props => props.showError ? "2px solid #c76b44" : "1px solid #555555"};
    border-radius: 5px;
    background-color: #ffffff;
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);

    :focus {
        outline: none;
        box-shadow: var(--color-focus-inset) 0px 0px 2px 2px inset, var(--color-focus-purple-outline) 0px 0px 2px 2px;
    }
`
const InputHeader = styled.label`
    text-align: left;
    margin-bottom: 5px;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    font-style: italic;
    font-weight: bold;
`
const ErrorText = styled.p`
    min-width: 100%;
    text-align: left;
    display: ${props => props.showError ? "flex": "none"};
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 18px;
    color: #ad5d3b;
    text-decoration: none solid rgb(173, 93, 59);
    padding-top: 2px;
    margin-top: 2px;
    margin-bottom: 0px;
`
const HuiTextArea = styled.textarea`
    padding: 5px 10px;
    height: ${props => props.height};
    width: 100%;
	border:${props => props.showError ? "2px solid #c76b44" : "1px solid #555555"};
	border-radius: 5px;
	background-color: #ffffff;
	background-size: cover;
	font-family: ${props => props.theme.mrEaves};
	font-size: 21px;
	color: #111111;
	box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);

	overflow: verticle;

	:focus {
        outline: none;
		box-shadow: var(--color-focus-inset) 0px 0px 2px 2px inset, var(--color-focus-purple-outline) 0px 0px 2px 2px;
	}
`

export const getSignupConfig = (id, input) => {
    const config = {
        id:id,
        onChange: input.handleChange,
        onBlur: input.handleBlur,
    };

    switch (id) {
        case 'name':
            config.title = "Your " + id;
            config.touched = input.touched.name;
            config.error = input.errors.name;
            config.value = input.values.name;
            config.type = "text";
            break;
        case 'email':
            config.title = "Your " + id;
            config.touched = input.touched.email;
            config.error = input.errors.email;
            config.value = input.values.email;
            config.type = "email";
            break;
        case 'password':
            config.title = "Password";
            config.touched = input.touched.password;
            config.error = input.errors.password;
            config.value = input.values.password; 
            config.requirements = passwordRequirements;
            config.type = "password";
            break;
        default:
            break;
    }
    return config;
}

export const getEmailConfig = (id, input) => {
    const config = {
        id:id,
        onChange: input.handleChange,
        onBlur: input.handleBlur,
    };

    switch (id) {
        case 'subject':
            config.title = "Subject";
            config.touched = input.touched.subject;
            config.error = input.errors.subject;
            config.value = input.values.subject;
            config.type = "text";
            break;
        case 'body':
            config.title = "Body";
            config.touched = input.touched.body;
            config.error = input.errors.body;
            config.value = input.values.body;
            config.inputElement = 'text_area';
            config.type = "text";
            config.height=  input.height || '500px'
            break;
        default:
            break;
    }
    return config;
}



export const getFamilyNameConfig = (input) => {
    const titleDecor = input.decorator ? input.decorator + " " : ""
    return {
        id:'name',
        title: titleDecor + 'Name of family',
        onChange: input.handleChange,
        onBlur: input.handleBlur,
        touched: input.touched.name,
        error: input.errors.name,
        value:input.values.name
    };
}

export function FormField({config, showErrorDisabled, width='100%' }) {
    const showError = config && config.touched && config.error;

    const inputElement = useMemo(() => {
        if (!config){return null};
        if (config.inputElement === 'text_area') {
            return <HuiTextArea
                type={config.type} 
                id={config.id} 
                name={config.name} 
                onChange={config.onChange} 
                onBlur={config.onBlur} 
                value={config.value} 
                showError={showError}
                width={width}
                height={config.height}
                placeholder={config.placeholder}
                />
        } else {
            return <Input 
            type={config.type} 
            id={config.id} 
            name={config.name} 
            onChange={config.onChange} 
            onBlur={config.onBlur} 
            value={config.value} 
            showError={showError}
            width={width}
            placeholder={config.placeholder}/>
        }

    }, [config, width, showError])

    if (!config){return null};
    
    return(
        <InputWrapper showError={showError}>
            <InputHeader htmlFor={config.id}>{config.title}</InputHeader>
            {inputElement}
            <ErrorText showError={showError && !showErrorDisabled}>
                {config.error}
            </ErrorText>
        </InputWrapper>
    )
}

export default FormField;