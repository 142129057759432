import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../auth/UserContext';

const T1 = styled.div`
    font-family: var(--font-mrsEaves);
    font-size: 26px;
    color: #555555;
    font-weight: 400;
    text-decoration: none solid rgb(85, 85, 85);
    line-height: 32px;
    text-align: center;
    font-style: italic;
    margin: 10px 24px;
`

const T2 = styled.div`
    font-family: var(--font-mrEaves);
    font-size: 36px;
    color: #69469d;
    font-weight: 400;
    text-decoration: none solid rgb(105, 70, 157);
    text-align: center;
    margin: 0px 20px;
`

const T3 = styled.div`
    font-family:  var(--font-mrsEaves);
    font-size: 18px;
    color: #111111;
    font-weight: 400;
    text-decoration: none solid rgb(17, 17, 17);
    line-height: 26px;
    text-align: center;
    margin: 10px 24px;
`

function Intro () {

    const { membership } = useUser()
    const person_passed = membership && membership.person_passed;

    return (
        <div className="task-page-intro">
            <T1>As you may know, the {person_passed} family is experiencing a difficult time. During this time, we are working together to make the family's lives a little easier.</T1>
            <T2>How would you like to help?</T2>
            <T3>Please sign up for items you can help with. No matter how big or small, it will make a difference.</T3>
        </div>
    );
}


export default Intro;