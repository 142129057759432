import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import VolunteersPanel from '../../Invitations/VolunteersPanel';
import Button from '../../Share/Button';
import SkipThisStepButton from '../../Share/SkipThisStepButton'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 125px;

  @media(max-width: 675px) {
    padding: 20px;
}

`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InviteFriends = () => {
  const history = useHistory();

  return (
  <Wrapper>
    <VolunteersPanel isWizard={true} />
    <ButtonWrapper>
      <Button style={{ marginTop: 30 }} onClick={() => history.push('/mission/calendar')}>Finish Setup </Button>
      <SkipThisStepButton onClick={() => history.push('/mission/calendar')}>Skip this step</SkipThisStepButton>
    </ButtonWrapper>
  </Wrapper>)
}

export default InviteFriends;