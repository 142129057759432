import React, {useEffect, useRef, Fragment}  from 'react';
import styled from 'styled-components';
import IconButton from '../IconButton';
import ServerBanner from './ServerBanner'

const ModalContainer = styled.div`
    display: ${({isOpen}) => isOpen ? 'flex' : 'none'}; 
    position: fixed; 
    z-index: 1000; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    padding: 1.5rem 1.5rem;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    
    @media(max-width: 500px) {
        padding: 1.5rem 0.5rem; 
    }

    opacity: ${({isOpen}) => isOpen ? '1' : '0'};
    visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
    transform: ${({isOpen}) => isOpen ? 'scale(1.0)' : 'scale(1.0)'};
    transition: ${({isOpen}) => isOpen ?  
        'visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;'
        :
        'transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;'
    }

`

const ModalDialog = styled.div`
    position: relative;
    margin: auto;
    max-width: 100%;
`

const Content = styled.div`
    width: ${({fullscreen}) => fullscreen ? '100%' : 'auto'};
    max-width: ${({fullscreen}) => fullscreen ? '100%' : '1080px'}; 
    height: auto;
    min-height: ${({fullscreen}) => fullscreen ? '100%' : 'auto'}; 
    z-index:1010;
    left: 0;
    top: 0;
    position: ${({fullscreen}) => fullscreen ? 'fixed' : 'relative'}; 
    border:  ${({fullscreen}) => fullscreen ? 'none' : '2px solid #a694c7'};
    border-radius: ${({fullscreen}) => fullscreen ? '0' : '8px'};
    background-color: #ffffff;
    background-size: cover;
    box-shadow: ${({fullscreen}) => fullscreen ? 'none' : '0px 1px 2px rgba(0,0,0,0.2)'};
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 414px) {
        position: ${({fullscreen}) => fullscreen ? 'fixed' : 'relative'};
    }
`    

const ButtonSpacer = styled.div `
    display: block; 
    margin-right: 0;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

`

const ContainerOne = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    @media (max-width: 414px) {
        width:90%;
    }
`
const BannerWrapper = styled.div`
    width: 100%
    margin-top: 30px;
    margin-bottom: 30px;
    display: ${({show}) => show ? "default" : "none" }; 
`

function Modal({open, handleClose, children, error, success ,title, background, closable=true, fullscreen=false}){

    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open')
        } else {
            document.body.classList.remove('modal-open')
        }
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [open])

    const boxRef = useRef();

    useEffect( () => {
        if(error || success) {
            boxRef.current.scrollTo({
                top:0,
                behavior:'smooth'
            });
        }
    }, [error, boxRef, success])

    const buttonStyle= closable ? {} : {display:'none'};

    return (
        <Fragment>
        {background}
        <ModalContainer isOpen={open} ref={boxRef}>
            <ModalDialog>
            <Content fullscreen={fullscreen}>
                <ButtonSpacer closable={closable}><IconButton variant="secondary" style={buttonStyle} onClick={handleClose}/></ButtonSpacer>
                <ContainerOne>
                   {title}
                </ContainerOne>
                <BannerWrapper show={(success || error)}>
                    <ServerBanner isError={error} message={success || error}/>
                </BannerWrapper>
                    {children}
            </Content>
            </ModalDialog>
        </ModalContainer>
        </Fragment>
        )
}

export default Modal;