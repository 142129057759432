import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import {
  Router, Route, Switch
} from 'react-router-dom'
import Sentry from './logging/sentry'; // this looks unused but is important to init Sentry; don't remove
import { Provider } from 'react-redux'
import env from './.env'
import App from './App'
import Signup from './components/Signup'
import Wizard from './components/Wizard/Wizard'
import LoginPage from './components/LoginPage'
import * as serviceWorker from './serviceWorker'
import Callback from './auth/Callback'
import store from './data/store'
import history from './auth/history'
import { ThemeProvider } from 'styled-components'
import Home from './components/Home'
import { Auth0Provider } from './shared-lib/auth-provider'
import { UserProvider } from './auth/UserContext'
import { AnalyticsProvider } from './analytics/AnalyticsContext'

import './styles/application.css'

const theme = {
  mrEaves: 'mr-eaves-sans, sans-serif',
  mrsEaves: 'mrs-eaves-xl-serif, serif'
}

ReactDOM.render(
  <AnalyticsProvider>
    <Auth0Provider connection={env.connection} config={env.AUTH0_CONFIG}>
      <UserProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Switch>
                <Route exact path="/" render={(props) => <Home {...props} />} />
                <Route path="/login" render={(props) => <LoginPage {...props} />} />
                <Route path="/signup" render={(props) => <Signup {...props}/>} />
                <Route path="/wizard" render={(props) => <Wizard {...props} />} />
                <Route path="/callback" render={(props) => <Callback {...props} />} />
                <Route path="/:mission_uuid" render={(props) => <App {...props} />} />
              </Switch>
            </Router>
          </ThemeProvider>
        </Provider>
      </UserProvider>
    </Auth0Provider>
  </AnalyticsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
