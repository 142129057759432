import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    'IconButtonPlus-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '23.5px',
    },
    primary: {
        backgroundColor: '#ffffff',
        border: '2px solid #69469d',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#060368',
            border: '2px solid #060368',
            
        },
        '&:active': {
            border: '2px solid #ffffff'
        },
        '&:focus' : {
            outline:'none'
        },
        '&:disabled': {
            border: '1px solid #cccccc',
            backgroundColor: '#ffffff'
        },
        '&:disabled > svg': {
            fill: '#cccccc'
        },
        '& > svg': {
            fill: '#69469d'
        },
        '&:hover svg': {
            fill: 'white'
        }
    },
})

const Path = (icon) => {
    if (icon.icon === 'plus') {
        return (
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
        )
    } else if (icon.icon === 'close'){
        return (
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        ) 
    }
}                 

//iconHeight = '18px', iconWidth = '18px', height = '30px', width = '30px'
function IconButtonSmall({ variant = "primary", disabled = false, onClick = () =>{}, style = {}, dimens={}, icon = "plus" }) {
    const classes = useStyles()

    let iconHeight = '18px';
    let iconWidth = '18px';
    let height = '31px';
    let width = '30px';

    if (icon === 'close') {
        iconHeight = '19px';
        iconWidth = '19px';
        height = '31px';
        width = '31px';
    }

    iconHeight = dimens.iconHeight || iconHeight;
    iconWidth = dimens.iconWidth || iconWidth;
    height = dimens.height || height;
    width = dimens.width || width;
   
    return (
        <button 
            type="button"
            disabled={disabled} 
            className={clsx(classes['IconButtonPlus-root'],classes[variant] , "delete-button")}
            style={style}
            style={{
                width: width,
                height: height
            }}
            onClick={onClick}>
            <svg style={{
                width: iconWidth,
                height: iconHeight
            }}
            overflow="visible" 
            preserveAspectRatio="none" 
            viewBox="0 0 24 23">
                <Path icon={icon}/>
            </svg>
        </button>
    )
}

export default IconButtonSmall;
