import React from 'react';
import styled from 'styled-components';

const Tip = styled.div`
    visibility: hidden;
    position: absolute;
    z-index: 10;
    transform: translate(0px, 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    padding: 3px;
    border: 1px solid #ebe9f4;
    border-radius: 3px;
    background-color: #ebe9f4;
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 18px;
    color: #060368;
    text-decoration: none solid rgb(6, 3, 104);
    text-align: center;
    filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.2));

    & i::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-200%) rotate(45deg);
        background-color: #ebe9f4;
    }
`

const Tooltip = ({children, onClick}) => {

    return (
        <Tip className="tooltip" onClick={onClick}>
            {children}
            <i></i>
        </Tip>
    )
}

export default Tooltip;