import React, { useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import EmailChip from './EmailChip';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    min-width: 10px;
    max-width:  ${props => props.maxWidth}

`
const Header = styled.label`
    text-align: left;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #060368;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 5px;
`

const CapturedEmailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border: 1px solid #5c506f;
    border-radius: 5px;
    max-width: 100%;
    height:  ${props => props.hasCaptured ? 'auto': '45px'};
    padding-left: 8px;
    padding-right: 8px;
    padding-top: ${props => props.hasCaptured ? '2.5px': '0px'};
    box-shadow: ${props => props.focused ? ' var(--color-focus-inset) 0px 0px 2px 2px inset, var(--color-focus-purple-outline) 0px 0px 2px 2px;' : 'none'};
   
    :hover {
        cursor: ${props => props.focused ? 'default' : 'pointer'};
    }

`

const TextBox = styled.input`
    font-family: var(--font-mrEaves);
    font-size: 21px;
    width: 100%;
    color:  '#111111';
    align-self: center;
    display: ${props => props.hidden ? 'none' : 'default'};
    margin-right: 5px;
    padding: 5px 5px;
    transparent: true;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: transparent;
    outline: none;

    :focus {
        display: 'default';
    }
`

const EmailDetail = styled.label`
    font-family: ${props => props.theme.mrEaves};
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
`

const EmailAddressInput = ({emailModel, setEmailModel, maxWidth}) => {

    const handleTextChange = (e) => {
        const val = e.target.value;
        setEmailModel(emailModel.processInput(val));
    }

    const handleSubmit = () => {
        setEmailModel(emailModel.handleSubmit());
    }

    const handleBlur = () => {
        setFocused(false);
        handleSubmit();
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
           handleSubmit();
        }
    }

    const handleContainerClick = (e) => {
            setFocused(true);
            inputRef.current.focus();
    }

    const handleMouseDown = (e) => {
        if (focused) {
            e.preventDefault(); // prevent input from blurring
        }
    }

    const inputRef = useRef();

    const emails = emailModel.getEmails();
    const pendingEmail = emailModel.getPending();


    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (focused) {
            inputRef.current.focus();
        }
    }, [focused])


    return(
        <Container maxWidth={maxWidth}>
            <Header>Email addresses</Header>
            <CapturedEmailsContainer hasCaptured={emails.length > 0} focused={focused} onMouseDown={handleMouseDown} onClick={handleContainerClick}>{
                emails.map((email, index) => {
                    return <EmailChip 
                    email={email} 
                    key={index} 
                    handleDelete={() => {setEmailModel(emailModel.handleDelete(index))}}
                    handleClick={() => {setEmailModel(emailModel.startEditing(index))}}
                    onChange={handleTextChange} 
                    handleSubmit={handleSubmit}
                    />
                })
                }
                <TextBox value={pendingEmail} onChange={handleTextChange} onKeyUp={handleKeyUp} onBlur={handleBlur} onFocus={() => {setFocused(true)}} hidden={!focused && emails.length > 0} ref={inputRef}></TextBox>
            </CapturedEmailsContainer>
            <EmailDetail>Separate email addresses with commas. Recipients will not see each other's email addresses.</EmailDetail>
            
        </Container>
    )
}

export default EmailAddressInput