import React from 'react';
import styled from 'styled-components';
import { VCALENDAR, VEVENT } from 'ics-js';
import { useAnalytics } from '../../../analytics/AnalyticsContext';

const Button = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    height: 47px;
    border-radius: 5px;
    background-color: #69469d;
    background-size: cover;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    :hover {
        cursor: ${props => props.enabled ? 'pointer' : 'default'};
        background-color: ${props => props.enabled ? '#060368' : '#69469d'};
    }
`

const AddToCalendarButton= ({event, calendarSelected}) => {

    const { track } = useAnalytics()
    const booking = event && event.booking
    const title = booking && booking.title

    const addToCalendar = () => {
        if(calendarSelected === "Google") {
            track("Add to Calendar", {
                "Task type": title,
                "Calendar type": "Google",
            })
            addToGoogleCalendar()
        } else if (calendarSelected === "Apple") {
            track("Add to Calendar",{
                "Task type": title,
                "Calendar type": "Apple",
            })
            addToAppleCalendar()
        } 
    }
    const addToGoogleCalendar = () => {
        const startDatetime = event.startDatetime;
        const endDatetime = event.endDatetime;
        const timezone = '' // TODO: add timezone
        const location = event.location
        const title = event.title
        const description = event.description
        const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDatetime}/${endDatetime}` +
        `${timezone && `&ctz=${timezone}`}&location=${location}&text=${title}&details=${description}`
        window.open(url, '_blank');
    }

    const addToAppleCalendar = () => {
        const cal = new VCALENDAR()
        cal.addProp('PRODID', 'Calendar')
        cal.addProp('VERSION', 2)
        
        const event = new VEVENT()
        event.addProp('UID');
        event.addProp('CLASS', 'PUBLIC');
        event.addProp('DESCRIPTION', event.description)
        event.addProp('DTSTAMP', event.startDatetime, { VALUE: 'DATE-TIME' });
        event.addProp('DTSTART', event.startDatetime, { VALUE: 'DATE-TIME' });
        event.addProp('DTEND', event.endDatetime, { VALUE: 'DATE-TIME' });
        event.addProp('SUMMARY', event.title)
        event.addProp('LOCATION', event.location)
        cal.addComponent(event)
        const blob = cal.toBlob()

        var FileSaver = require('file-saver');
        FileSaver.saveAs(blob, 'SavoEvent.ics')
    }

    return (
      <Button onClick={addToCalendar} enabled={calendarSelected}>
         Add to calendar
       </Button>
    );
  }

  export default AddToCalendarButton;