
import React from 'react';
import styled from 'styled-components';


const FooterContainer = styled.div`
    min-height: 57px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: center;
    padding: 20px 20px 20px 20px;

    @media(max-width: 675px) {
        flex-direction: column;
        margin-top: 30px;
        padding-left:8px;
        padding-right:8px;
        border-width: 1px 0px 0px 0px;
        border-style: solid;
        border-color: #EBE9F4;
    }
`
const P1 = styled.a`
    margin: 20px;
    height: 16px;
    font-size: 14px;
    color: #69489d;
    font-weight: 400;

`


const FooterLinkAARP = styled.a`
    height: 16px;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    color: black;
    flex-grow: 1;
    margin: 20px;
`

function Footer() {
    return (
        <FooterContainer>
            <P1 href="https://joinsavo.com/index.php/about/" target="_blank">About</P1>
            <P1 href="https://joinsavo.com/faqs/" target="_blank">FAQs</P1>
            <P1 href="https://www.aarp.org/about-aarp/terms-of-service/" target="_blank">Terms of use</P1>
            <P1 href="https://www.aarp.org/about-aarp/privacy-policy/" target="_blank">Privacy policy</P1>
            <P1 href="https://joinsavo.com/index.php/contact/" target="_blank">Contact us</P1>
            <FooterLinkAARP>© 2020 AARP</FooterLinkAARP>
        </FooterContainer>
    );
}

export default Footer
