import React, { useState, Fragment, useEffect }  from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { createBooking } from '../../data/actions'
import moment from 'moment';
import { useUser } from '../../auth/UserContext';
import DropDown from './DropDown';
import AddToCalendarButton from '../../shared-lib/components/general/AddToCalendarButton'
import Modal from '../../shared-lib/components/general/Modal';
import { useAnalytics } from '../../analytics/AnalyticsContext';
import FlexForm from '../../shared-lib/components/FlexForm';
import useMediaQuery from '../../shared-lib/utils/useMediaQuery';
import { connect } from 'react-redux';

const Button = styled.div`
    height: 47px;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
    background-color: #69469d;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    display: flex;
    font-weight: bold;
    margin: 20px auto 10px auto;
    align-items: center;
    :hover {
        cursor: pointer;
        background-color: #060368;
    }
`
const Title = styled.div`
    font-family: var(--font-mrEaves);
    font-size: 42px;
    color: #060368;
    font-weight: bold;
    text-align: center;
`
const SubTitle = styled.div`
    font-family:  var(--font-mrsEaves);
    font-size: 26px;
    color: #555555;
    font-weight: 400;
    text-align: center;
    font-style: italic;
`

const ContainerTwo = styled.div`
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    align-items: flex-start;
    padding: 10px 20px;
    background-color: #ebe9f4;
    border-radius: 5px;
` 

const ThankyouTitle = styled.div`
    width: 100%;
    font-family: var(--font-mrEaves);
    font-size: 28px;
    color: #69489d;
    display: inline-block;
    margin: 30px 10px;
    text-align: center;
    margin-bottom: 0px;
`

const SubNote = styled.div`
    font-family:  var(--font-mrsEaves);
    font-size: 16px;
    text-align: left;
    color: #111111;
    font-weight: 400;
    line-height: 20px;
`
const DropDownWrapper = styled.div`
    display:flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    padding-right: 20%;
    padding-left: 20%;
    margin-bottom:20px;
`

const NoteTextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    label {
        margin: 10px 0 5px 0;
        color: #060368
        font-size: 18px;
        font-style: italic;
        font-family: var(--font-mrsEaves);
        width: 100%;
    }

    textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
    }
`

const formatNote = (note) => {
    return `•  "${note}"`
}

function VolunteerModal(props) {

    const { 
        fetchRecentNotesState,
        booking,
    } = props

    const { seriesId, date } = booking

    const recentNotes = fetchRecentNotesState.response || []
    const [notes, setNotes] = useState("")

    const { user, membership } = useUser();
    const [showCalendarButton, setShowCalendarButton] = useState(false);
    const [calendarSelected, setCalendarSelected] = useState("");
    const dispatch = useDispatch()
    const isSmall = useMediaQuery('(max-width: 600px)');
    const isChildcare = props.booking.title === 'Childcare';
    const { mpTrackBooking } = useAnalytics()

    useEffect(() => {
        setShowCalendarButton(false)
    },[props.isVolunteerModalOpen])

    const handleChangeToSelectedCalendar = (value) => {
        setCalendarSelected(value)
    }
    
    const createBookingHandler = () => {
        if (!user) {
            alert("user's name is not set during registration, booking can't be created")
            return
        }

        let noteVal = notes ? notes : 'No additional notes';

        if (isChildcare) {
            noteVal = '';
        }

        //date, user_id, assignee, series_id, note
        const payloadBooking = {
            date: date,
            user_uuid: user.user_uuid,
            series_id: seriesId,
            note: noteVal,
            assignee: user.name,
            mission_uuid: membership.mission_uuid,
            isFromTaskPage: true,
            title: props.booking.title,
        }
        mpTrackBooking(payloadBooking, "create")
        dispatch(createBooking(payloadBooking))
        setShowCalendarButton(true)

    }
    
    const startDate = props.booking.date;
    const startDatetime = moment(startDate);
    startDatetime.set({h: 12, m: 0})
    const endDatetime = startDatetime.clone().add(5, 'hours');

    const event = {
        description: 'The Savo App is in pilot!',
        location: 'Helping through SAVO',
        startDatetime: startDatetime.format('YYYYMMDDTHHmmss'),
        endDatetime: endDatetime.format('YYYYMMDDTHHmmss'),
        title: `Volunteered for ${props.booking.title} today`,
        booking: props.booking,
      }

    const contentWidth = isSmall ? '90%' : (isChildcare ? 'auto' : '594px');

    return (
        <div>
            <Modal open={props.isVolunteerModalOpen} handleClose={props.handleOpenCloseVolunteerModal}>
                <Title> Volunteer for {props.booking.title}</Title>
                <SubTitle> on {moment(props.booking.date).format('MMMM D').toString()}</SubTitle>
                <FlexForm style={{minHeight: isChildcare ? 'auto' : '200px', width: contentWidth, padding:'0px'}}>
                    {
                    showCalendarButton ? 
                        <Fragment>
                            <ThankyouTitle>Thank you for volunteering</ThankyouTitle>
                            <p style={{marginTop:'16px', marginBottom: '35px', fontSize:'18px', lineHeight: '26px'}}>
                                You will receive an email reminder 24 hours beforehand. At any time you can view your dashboard to see upcoming tasks.
                            </p>
                            <DropDownWrapper>
                            <DropDown
                            calendarSelected={calendarSelected}
                            handleChangeToSelectedCalendar={handleChangeToSelectedCalendar}
                            />
                            </DropDownWrapper>
                            <AddToCalendarButton calendarSelected={calendarSelected} event={event}/>
                        </Fragment>   :
                    <div style={{width: '100%'}}>
                    {isChildcare ? null : 
                    <div>
                    <NoteTextAreaWrapper>
                        <label>Notes</label>
                        <textarea 
                            value={notes} 
                            onChange={(e) => setNotes(e.target.value)}
                            placeholder='Add additional details such as planned activities'
                            />
                    </NoteTextAreaWrapper>
                    <ContainerTwo hidden={recentNotes.length < 1}>
                    <SubNote style={{marginBottom: '15px'}}>Others who are volunteering for this task have left some notes that may be helpful as you plan:</SubNote>
                        {
                            recentNotes.map((i, index) => <SubNote key={index}>{formatNote(i.note)}</SubNote>)
                        }
                    </ContainerTwo>
                    </div>}
                    <Button onClick={createBookingHandler}> Volunteer </Button>
                    </div>
                    }
                </FlexForm>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {fetchRecentNotesState} = state.VillageReducer
    return {fetchRecentNotesState}
}
export default connect(mapStateToProps)(VolunteerModal);
