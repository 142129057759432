import React, { useEffect, useState } from 'react';
import './TaskPage.css';
import Intro from './Intro';
import TaskPanel from './TaskPanel';
import Tabbar from './Tabbar';
import { connect } from 'react-redux';
import { getTaskPageTaskSeries } from '../../data/actions';
import VolunteerModal from './VolunteerModal'
import { useUser } from '../../auth/UserContext';
import { useAnalytics } from '../../analytics/AnalyticsContext';
import { fetchRecentNotes } from '../../data/actions';
import moment from 'moment';

const dummyTaskSeries = [
    {
        title: "Petcare", 
        series_id: 1, 
        timeslots:[
            {date: '2032-01-01', isEnabled: true, assignee: null},
            {date: '2032-01-02', isEnabled: true, assignee: null},
            {date: '2032-01-03', isEnabled: true, assignee: null}
        ]
    },
    {
        title: "Eldercare", 
        series_id: 1, 
        timeslots:[
            {date: '2032-01-01', isEnabled: true, assignee: null},
            {date: '2032-01-02', isEnabled: true, assignee: null},
            {date: '2032-01-03', isEnabled: true, assignee: null}
        ]
    },
    {
        title: "Childcare", 
        series_id: 1, 
        timeslots:[
            {date: '2032-01-01', isEnabled: true, assignee: null},
            {date: '2032-01-02', isEnabled: true, assignee: null},
            {date: '2032-01-03', isEnabled: true, assignee: null}
        ]
    }
]

const filterCategory = (taskSeries, category) => {
    return taskSeries.filter(item => item.category === category)
}

function TaskPage(props) {

    const [activeTab, setActiveTab] = useState(0)
    const [categoryTasks, setCategoryTasks] = useState([])
    const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false)
    const [booking, setBooking] = useState({date: null, title: null, seriesId: null})
    const { membership } = useUser()
    const { getTaskPageTaskSeries, taskSeries, useDummy = false } = props
    const { track, pageview } = useAnalytics()
    const {fetchRecentNotes} = props;

    useEffect(() => {
        if (membership && membership.mission_uuid)
        getTaskPageTaskSeries(membership.mission_uuid)
    },[membership, getTaskPageTaskSeries])

    useEffect(() => {      
        if (taskSeries.length !== 0 && activeTab === 0) {
            setCategoryTasks(filterCategory(taskSeries, 'care'))
        } else {
            handleTabChange(activeTab)
        }
    }, [taskSeries])

    useEffect(() => {
        track("Page View", {"Page Name": "Village"})
        pageview("/tasks", [], "Village")
        document.getElementById('root').setAttribute('style', 'max-width: 1024px');
    }, [])
    
    const handleTabChange = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                const careTaskSeries = filterCategory(taskSeries, "care")
                setCategoryTasks(careTaskSeries)
                break;
            case 1:
                const cleaningTaskSeries = filterCategory(taskSeries, "cleaning")
                setCategoryTasks(cleaningTaskSeries)
                break;
            case 2:
                const foodTaskSeries = filterCategory(taskSeries, 'food')
                setCategoryTasks(foodTaskSeries)
                break;
            case 3:
                const emotionTaskSeries = filterCategory(taskSeries, 'emotion')
                setCategoryTasks(emotionTaskSeries)
                break;
            case 4:
                const otherTaskSeries = filterCategory(taskSeries, 'other')
                setCategoryTasks(otherTaskSeries)
                break;
            default: 
            setCategoryTasks([])
        }
        setActiveTab(tabIndex)
    }



    const handleOpenCloseVolunteerModal = (booking) => {
        setBooking(booking)
        if (booking && booking.date && booking.seriesId) {
            const dateString = moment(booking.date).utc().format('YYYY-M-D').toString()
            fetchRecentNotes(dateString, booking.seriesId);
        }
        setIsVolunteerModalOpen(!isVolunteerModalOpen)
    }

    return (
        <div className="task-page-root">
        <Intro></Intro>
        <div className="task-page-body">
            <Tabbar activeTab={activeTab} handleTabChange={handleTabChange}></Tabbar>
            <div className="task-panel-root">
                {
                    !useDummy ?
                    categoryTasks.map((item, index) => {
                        return <TaskPanel 
                            handleOpenCloseVolunteerModal={handleOpenCloseVolunteerModal} 
                            key={index} 
                            taskSeries={item}
                        />
                    })
                    :
                    dummyTaskSeries.map((item, index) => {
                        return <TaskPanel 
                            handleOpenCloseVolunteerModal={handleOpenCloseVolunteerModal} 
                            key={index} 
                            taskSeries={item}
                        />
                    })
                }
            </div>
            {isVolunteerModalOpen &&
                (<VolunteerModal 
                    handleOpenCloseVolunteerModal={handleOpenCloseVolunteerModal} 
                    isVolunteerModalOpen={isVolunteerModalOpen}
                    booking={booking}
                />)
            }
        </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    taskSeries: state.TaskReducer.taskSeries,
})

export default connect(mapStateToProps, { getTaskPageTaskSeries, fetchRecentNotes})(TaskPage);