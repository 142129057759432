import React, { useEffect } from 'react';
import CalendarHeader from './CalendarHeader';
import CalendarWeek from './CalendarWeek';
import { connect } from 'react-redux';
import { fetchTaskSeries, setWeek, getMission, modifyTasksSeriesCategoriesSuccess } from '../../data/actions';
import styled from 'styled-components';
import { useUser } from '../../auth/UserContext';
import PropTypes from 'prop-types';
import { useAnalytics } from '../../analytics/AnalyticsContext';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 1080px;
    flex-grow: 1;
`

const CalendarTitle = styled.div`
    & h1 {
        color: var(--color-dark-purple);
    }

    @media (max-width: 600px){
        & h1 {
            font-size: 25px;
        }
    }
`

function Calendar(props) {

    const { fetchTaskSeries, taskSeries, weekStart, weekEnd, getMission } = props
    const { membership } = useUser()
    const mission_uuid = membership && membership.mission_uuid
    const person_passed = membership && membership.person_passed
    const { track, pageview } = useAnalytics()

    useEffect(() => {
        track("Page View", {"Page Name": "Task Calendar"})
        pageview("/calendar", [], "Task Calendar")
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (mission_uuid) {
            fetchTaskSeries(mission_uuid, weekStart, weekEnd)
            getMission(mission_uuid)
        }
        // eslint-disable-next-line
    }, [fetchTaskSeries, getMission, mission_uuid])

    return (
        <Root>
            <CalendarTitle>
                <h1>{person_passed} Family Village </h1>
            </CalendarTitle>
            <CalendarHeader/>
            <CalendarWeek 
                taskSeries={taskSeries}
            />
        </Root>)
}

Calendar.propTypes = {
    taskSeries: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = (state) => {
    const villageState = state.VillageReducer;
    return {
        taskSeries: villageState.taskSeries,
        weekStart: villageState.weekStart,
        weekEnd: villageState.weekEnd,
        modifyTaskSeriesCategoriesError: villageState.modifyTaskSeriesCategoriesError, 
    }
}

const mapDispatchToProps = { 
    fetchTaskSeries, 
    setWeek, 
    getMission,
    modifyTasksSeriesCategoriesSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
