const development = {
    //API_URL: "http://localhost:3005",
    API_URL: "https://api-dev.joinsavo.com/v1", 
    HOST_URL: process.env.NODE_ENV === "development" ? 'http://kubernetes.docker.internal:3000' : "https://dev.joinsavo.com",
    AUTH0_CONFIG: {
        domain: 'auth.joinsavo.com',
        clientID: 'coaNqHCKdpjZcRj7MvC5zAGbVI9DxyzM',
        redirectUri: process.env.NODE_ENV === "development" ? 'http://kubernetes.docker.internal:3000/callback' : 'https://dev.joinsavo.com/callback',
        responseType: 'token',
        scope: 'openid profile email',
        audience: 'https://aws.amazon.com/development/api'
    },
    connection: "development",
    MIXPANEL_TOKEN: 'cabb830784ae683fa0f7d09a10e3682f',
    GOOGLE_ANALYTICS: 'UA-131743999-6',
    SENTRY_DSN: "https://0776279e5f964020b8dc9f6d79a3a951@sentry.io/1862711",
}

const local = {
    API_URL: "http://localhost:3005",
    HOST_URL: "http://kubernetes.docker.internal:3000",
    AUTH0_CONFIG: {
        domain: 'auth.joinsavo.com',
        clientID: 'YANJd8nDlUJhc1c9IqZmvFpADfjgKnHJ',
        redirectUri: 'http://kubernetes.docker.internal:3000/callback',
        responseType: 'token',
        scope: 'openid profile email',
        audience: 'https://aws.amazon.com/development/api'
    },
    connection: "local",
    MIXPANEL_TOKEN: 'cabb830784ae683fa0f7d09a10e3682f',
    GOOGLE_ANALYTICS: 'UA-131743999-6',
    SENTRY_DSN: "https://0776279e5f964020b8dc9f6d79a3a951@sentry.io/1862711",
}

const testing = {
    API_URL: "https://api-test.joinsavo.com/v1",
    HOST_URL: "https://test.joinsavo.com",
    AUTH0_CONFIG: {
        domain: 'auth.joinsavo.com',
        clientID: 'bnD5ajGvRCQRsmsVBeYG1MTlq5uhyIV3',
        redirectUri: 'https://test.joinsavo.com/callback',
        responseType: 'token',
        scope: 'openid profile email',
        audience: 'https://aws.amazon.com/development/api'
    },
    connection: "testing",
    MIXPANEL_TOKEN: 'cabb830784ae683fa0f7d09a10e3682f',
    GOOGLE_ANALYTICS: 'UA-131743999-6',
    SENTRY_DSN: "https://0776279e5f964020b8dc9f6d79a3a951@sentry.io/1862711",
}

const staging = {
    API_URL: "https://api-stage.joinsavo.com/v1",
    HOST_URL: "https://stage.joinsavo.com",
    AUTH0_CONFIG: {
        domain: 'auth.joinsavo.com',
        clientID: 'ebQgKWYqHuIH7L2lCxhi7V73Zz503z4X',
        redirectUri: 'https://stage.joinsavo.com/callback',
        responseType: 'token',
        scope: 'openid profile email',
        audience: 'https://aws.amazon.com/development/api'
    },
    connection: "staging",
    MIXPANEL_TOKEN: 'cabb830784ae683fa0f7d09a10e3682f',
    GOOGLE_ANALYTICS: 'UA-131743999-6',
    SENTRY_DSN: "https://0776279e5f964020b8dc9f6d79a3a951@sentry.io/1862711",
}

// TODO: setup the production url when domain is obtained
const production = {
    API_URL: "https://api.joinsavo.com/v1",
    HOST_URL: `https://app.joinsavo.com`,
    AUTH0_CONFIG: {
        domain: 'auth.joinsavo.com',
        clientID: 'Phftzm2XQ4WsoQ1NL2K1Uf21R8SbmBUM',
        redirectUri: 'https://app.joinsavo.com/callback',
        responseType: 'token',
        scope: 'openid profile email',
        audience: 'https://aws.amazon.com/production/api'
    },
    connection: "production",
    MIXPANEL_TOKEN: 'fd8da9abf34b4e8b2ba153a77e2c592e',
    GOOGLE_ANALYTICS: 'UA-131743999-6',
    SENTRY_DSN: "https://ef6395afe358482ab21367a57b548379@sentry.io/1862714",
}

var environment = null;

switch(process.env.REACT_APP_ENV) {
    case "local":
        environment = local
        break;
    case "development":
        environment = development
        break;
    case "testing":
        environment = testing
        break;
    case "staging":
        environment = staging
        break;
    case "production":
        environment = production
        break;
    default:
        environment = development
}

export default environment;
